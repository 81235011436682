<template>
  <section class="progressao">
    <table :class="{ faded: loading }">
      <thead>
        <tr>
          <th colspan="3"></th>
          <th colspan="3" class="headTag alert">
            Percentuais
          </th>
          <th colspan="2"></th>
          <th colspan="2" class="headTag success">
            Progressão
          </th>
          <th colspan="2"></th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Descrição</th>
          <th>Sigla</th>
          <th class="alert text-center">Padrão</th>
          <th class="alert text-center">Limite</th>
          <th class="alert text-center">Reajuste</th>
          <th class="text-center">Somativo</th>
          <th class="text-center">Plano</th>
          <th class="success text-center">Período</th>
          <th class="success text-center">Ciclo</th>
          <th class="text-center">Permite Redução</th>
          <th class="text-center">Prioridade Redução</th>
          <th class="text-center">Status</th>
          <th class="text-right">
            <label class="check-control">
              Todos
              <input type="checkbox" class="check" v-model="all" @change="selectItem('all')"/>
            </label>
          </th>
        </tr>
      </thead>
      <tbody v-if="descontos">
        <template v-for="(item, i) in descontos" :key="item">
          <tr v-show="item.visible">
            <td>{{ item.idprogressaotipodesconto }}</td>
            <td>{{ item.descricao }}</td>
            <td>{{ item.sigla }}</td>
            <td class="alert text-center percentInput">
              <input-control cssClass="small text-center" type="number" placeholder="0" v-model="item.percentualPadrao" @change="selectItem(i)" />
            </td>
            <td class="alert text-center percentInput">
              <input-control cssClass="small text-center" type="number" placeholder="0" v-model="item.percentualLimite" @change="selectItem(i)"/>
            </td>
            <td class="alert text-center percentInput">
              <input-control cssClass="small text-center" type="number" placeholder="0" v-model="item.percentualReajuste" @change="selectItem(i)"/>
            </td>
            <td>
              <div class="switchWrapMini text-center">
                <switch-control v-model:checked="item.descontoSomativo" labelOn="Sim" labelOff="Não" invert @change="selectItem(i)"/>
              </div>
            </td>
            <td>
              <div class="switchWrapMini text-center">
                <switch-control v-model:checked="item.descontoPlano" labelOn="Sim" labelOff="Não" invert @change="selectItem(i)"/>
              </div>
            </td>
            <td class="success text-center">
              <div class="switchWrapMini">
                <switch-control v-model:checked="item.progressaoPeriodo" labelOn="Sim" labelOff="Não" invert @change="selectItem(i)"/>
              </div>
            </td>
            <td class="success text-center">
              <div class="switchWrapMini">
                <switch-control v-model:checked="item.progressaoCiclo" labelOn="Sim" labelOff="Não" invert @change="selectItem(i)"/>
              </div>
            </td>
            <td>
              <div class="switchWrapMini text-center">
                <switch-control v-model:checked="item.permiteReducao" labelOn="Sim" labelOff="Não" invert @change="selectItem(i)"/>
              </div>
            </td>
            <td class="percentInput">
              <input-control v-model="item.prioridadeReducao" type="number" placeholder="0" @change="checkPrioridade(i); selectItem(i)" cssClass="small text-center" />
            </td>
            <td>
              <div class="switchWrapMini text-center">
                <switch-control v-model:checked="item.ativo" labelOn="Ativo" labelOff="Inativo" invert @change="selectItem(i)"/>
              </div>
            </td>
            <td class="text-right">
              <label class="check-control">
                <input type="checkbox" class="check" v-model="item.selected"/>
              </label>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <footer :class="{ hom: hom }">
      <a href="#" class="btn btn-primary" @click.prevent="save">Salvar</a>
    </footer>
  </section>
</template>

<script>
import FinanceiroService from '@/services/financeiro.service.js'

export default {
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.setup()
      }
    }
  },
  data () {
    return {
      hom: false,
      descontos: null,
      all: false,
      loading: false,
      query: null,
      searchWait: null
    }
  },
  mounted () {
    this.hom = process.env.VUE_APP_ENV != 'PROD'
    if ( this.scope ) {
      this.setup()
    }
  },
  methods: {
    setup () {
      const data = this.scope.map( k => {
        return {
          selected: false,
          visible: true,
          ...k
        }
      })

      this.descontos = data
    },
    selectItem ( i ) {
      if ( i == 'all' ) {
        this.descontos.map( k => k.selected = this.all)
      } else {
        this.descontos[i].selected = true
      }
    },
    search ( query ) {
      if ( query && query.length > 0 ) {

        this.descontos.map( k => {
          k.visible = false

          query = query.toLowerCase()
          const id = k.idprogressaotipodesconto ? k.idprogressaotipodesconto.toString() : ""
          const descricao = k.descricao ? k.descricao.toLowerCase() : ""
          const sigla = k.sigla ? k.sigla.toLowerCase() : ""
          
          if ( descricao.includes(query) || sigla.includes(query) || id.includes(query) ) {
            k.visible = true
          }

        })
      } else if ( !query || query.length == 0 ) {
        this.clearSearch()
      }
    },
    clearSearch () {
      this.descontos.map( k => {
        k.visible = true
      })
    },
    toggleActive ( active ) {
      console.log("toggleActive:", active)
      
      this.descontos.map( k => {
        if ( active ) {
          k.visible = false
          if ( k.ativo ) {
            k.visible = true
          }
        } else {
          k.visible = true
        }
        
      })
    },
    checkPrioridade ( i ) {
      const prioridade = this.descontos[i].prioridadeReducao * 1
      const unic = this.descontos.filter( a => a.prioridadeReducao == prioridade ).length == 1
      
      if ( !unic ) { 
        this.descontos[i].prioridadeReducao = null
        const maxPrioridade = Math.max(...this.descontos.map(d => d.prioridadeReducao))
        const next = parseFloat(maxPrioridade) + 1

        window.toaster.emit('open', {style: "error", message: `A posição ${ prioridade } já está ocupada. A próxima posição disponível é: ${ next }`, floater: true})
      }
    },
    save () {
      const drop = this.descontos.filter( a => a.selected )
      this.loading = true
      this.$emit('onSave')
      FinanceiroService.saveProgressaoDesconto( drop ).then(
        response => {
          console.log("response", response)
          this.$emit('onEnd', true)
          this.loading = false
        },
        () => {
          this.$emit('onEnd')
          this.loading = false
        }
      )
    },
    
  }
}
</script>

<style lang="scss" scoped>
  .progressao {
    padding: 0 $hmg_mid $hmg_mid;
  }

  table {
    padding: 0; border-collapse: collapse;
    width: 100%; margin-top: $hmg_mid;

    thead {
      tr {
        th {
          padding: $mg_mini;
          
          &.headTag {
            border-top-left-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
            color: $color-secondary;
            text-align: center;
            
            &.alert {
              background-color: $color-alert;
            }
            &.success {
              background-color: $color-success-2;
            }
          }
        }

        &:last-child {
          background-color: $color-secondary;
          border: 0;

          th {
            border-bottom: $border-component;
            
            &.alert {
              background-color: $color-neutro;
              border-bottom: 1px solid $color-alert !important
            }
            &.success {
              background-color: $color-credito;
              border-bottom: 1px solid $color-success-2 !important
            }
          }
        }
      }
    }

    tbody {
      td {
        padding: $hmg_mini $mg_mini;
        border-bottom: $border-component;


        &.alert {
          background-color: $color-neutro;
          border-bottom: 1px solid $color-alert !important
        }
        &.success {
          background-color: $color-credito;
          border-bottom: 1px solid $color-success-2 !important
        }
      }
    }
  }

  .percentInput {
    width: 120px; max-width: 120px;
  }

  footer {
    position: fixed; bottom: 0;
    left: 60px;
    width: calc(100% - 70px); border-top: $border-component;
    padding: $hmg_mid;
    background-color: $color-bg;
    z-index: 1000;

    &.hom {
      bottom: 20px;
      border-left: 1px solid $color-warning;
      border-bottom: 1px solid $color-warning;
      border-bottom-left-radius: 6px;
    }
  }

  .progressao {
    padding-bottom: $mg_large;
  }
</style>