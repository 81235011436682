<template>
  <section>
    
  </section>
</template>

<script>
export default {
  props: {
    
  },
  data () {
    return {
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    const route = `https://api2.rededecisao.com/api/declaracaoporemail?token=${process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN}&email=${this.currentUser.email}&ano=2024` 

    // console.log("route:", route)
    window.location.href = route
  }
}
</script>

<style>

</style>