import axios from 'axios'
import authHeader from './auth-header'

class PvService {
  syncDescontosPorAluno ( data ) {
    let { idUnidade, periodoLetivo, idCurso, idSerie, idTurma } = data
    
    idCurso = idCurso ?? 0
    idSerie = idSerie ?? 0
    idTurma = idTurma ?? 0

    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/sync/proressao/descontos/aluno/${idUnidade}/${periodoLetivo}/${idCurso}/${idSerie}/${idTurma}`, { headers: authHeader() })
  }

  getDescontosPorAluno ( data ) {
    let { idUnidade, periodoLetivo, idCurso, idSerie, idTurma } = data

    idCurso = idCurso ?? 0
    idSerie = idSerie ?? 0
    idTurma = idTurma ?? 0

    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/descontos/aluno/${idUnidade}/${periodoLetivo}/${idCurso}/${idSerie}/${idTurma}`, { headers: authHeader() })
  }

  getDescontosPorAlunoDetails ( idmodelagemProposta ) {
    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/descontos/modelagem/proposta/${idmodelagemProposta}`, { headers: authHeader() })
  }

  saveDescontosPorAluno ( data ) {
    return axios.put(process.env.VUE_APP_API_URL + `proximociclo/save/descontos/proposta`, data, { headers: authHeader() })
  }

  getSidebarBruto ( data ) {
    let { unidade, periodoLetivo } = data
  
    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/sidebar/modelagem/${unidade}/${periodoLetivo}`, { headers: authHeader() })
  }
  
  getSidebarLiquido ( data ) {
    let { unidade, periodoLetivo, idSerie } = data
    
    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/sidebar/descontos/${unidade}/${periodoLetivo}/${idSerie}`, { headers: authHeader() })
  }
}

export default new PvService()