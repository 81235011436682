<script setup>
  import AcademicoService from '@/services/academico.service.js'
  import UserService from '@/services/user.service.js'
  import { createSelectOptions } from '@/services/utils.service.js'
  import FormSpinner from '@/components/utilities/FormSpinner.vue'
</script>

<template>
  <header>
    <form-spinner v-if="loading || !ready"/>
    <div>
      <div class="row nop nom">
        <div class="col-md-2 form-group">
          <select-control 
            label="Período Letivo" 
            placeholder="Selecione" 
            :options="options.periodosLetivos" 
            :disabled="!ready || loading"
            v-model="filter.periodoLetivo"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <select-control 
            label="Unidade" 
            placeholder="Selecione" 
            :options="options.unidades"
            :disabled="!ready || loading" 
            v-model="filter.unidade"
          />
        </div>
        <div class="col-md-2 form-group">
          <a href="#" class="btn btn-primary btFiltrar" @click.prevent="runFilter">Filtrar</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  component: {
    FormSpinner
  },
  props: {
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      options: {
        periodosLetivos: null
      },
      ready: false,
      wait: {
        unidades: false,
        periodosLetivos: false
      },
      filter: {
        unidade: null,
        periodoLetivo: null
      }
    }
  },
  mounted () {
    this.getTodosPeriodosLetivos()
    this.getUnidades()
  },
  methods: {
    getTodosPeriodosLetivos () {
      AcademicoService.getTodosPeriodosLetivos().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log("getTodosPeriodosLetivos:", response.data)
            this.options.periodosLetivos = createSelectOptions(response.data, 'descricao', 'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.wait.periodosLetivos = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! Não foi possível recuperar os períodos letivos necessários", floater: true})
        }
      )
    },
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log("getUnidades:", response.data)
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')

          this.wait.unidades = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    finishLoad () {
      const done = !Object.values(this.wait).some( a => !a)
      if ( done ) {
        this.ready = true
      }
    },
    runFilter () {
      this.$emit('onFilter', this.filter)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    >div:not(.spinnerWrap) {
      padding: $hmg_mid
    }
  }

  .row {
    align-items: flex-end;
  }

  .btFiltrar {
    margin-bottom: 7px;
  }
</style>