<script setup>
  import FormSpinner from '@/components/utilities/FormSpinner.vue'
  import FinanceiroService from '@/services/financeiro.service.js'
  import FilterController from '@/components/financeiro/parametros/utils/FilterProgressao.vue'
  import ProgressaoController from '@/components/financeiro/parametros/template/ProgressaoDescontos.vue'
</script>

<template>
  <section>
    <form-spinner v-if="loading" />
    <filter-controller @onFilter="runFilter" @onToggleActive="toggleActive" @onSearch="search" :allowSearch="descontos && descontos.length > 1" on/>
    <progressao-controller v-show="descontos" :scope="descontos" @onSave="loading = true" @onEnd="finished" ref="progressao" key="progressao"/>
  </section>
</template>

<script>
export default {
  components: {
    FilterController, ProgressaoController, FormSpinner
  },
  data () {
    return {
      filter: null,
      descontos: null,
      loading: false
    }
  },
  mounted () {
    
  },
  methods: {
    runFilter ( data ) {
      this.filter = data

      this.getProgressaoDesconto()
    },
    getProgressaoDesconto () {
      const { idUnidade, periodoLetivo } = this.filter
      this.loading = true

      FinanceiroService.getProgressaoDesconto( idUnidade, periodoLetivo ).then(
        response => {
          this.loading = false
          this.descontos = response.data
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! Não foi possível ", floater: true})
          this.loading = false
        }
      )
    },
    finished ( refresh ) {
      this.loading = false

      if ( refresh ) {
        this.getProgressaoDesconto()
      }
    },
    toggleActive ( active ) {
      this.$refs.progressao.toggleActive( active )
    },
    search ( query ) {
      // console.log("search...", query)
      this.$refs.progressao.search( query )
    }
  }
}
</script>

<style>

</style>