import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home'
import Login from '@/views/auth/Login'

import AuthService from '@/services/auth.service'
import LinkExterno from '@/components/template/LinkExterno'
import Painel from '@/components/template/Painel'
import ErroLogin from '@/views/auth/Error'

// APPS
import Boletos from '@/views/boletos/Boletos'
import ExtratoFinanceiro from '@/views/financeiro/extrato/ExtratoController.vue'

// CENTRAL DO ALUNO
import CentralAluno from '@/views/centralaluno/CentralDoAluno'
// import PerfilAluno from '@/views/centralaluno/Perfil'
import DocumentosMatricula from '@/views/centralaluno/Documentos'

//ADMINSTRATIVO 
import Administrativo from  '@/views/administrativo/Administrativo'
import Colaboradores from  '@/views/administrativo/colaboradores/Colaboradores'
import Modulos from '@/views/administrativo/modulos/Modulos'
import Permissoes from '@/views/administrativo/permissoes/Permissoes'
import Funcoes from '@/views/administrativo/funcoes/Funcoes'
import Docs from '@/views/administrativo/docs/Docs'

// LOJA
import Loja from '@/views/loja/Home'
import BuscaLoja from '@/views/loja/Busca'
import Produto from '@/views/loja/Produto'
import Checkout from '@/views/loja/checkout/Checkout'
import Categoria from '@/views/loja/Categoria'
import MinhaConta from '@/views/loja/minhaconta/MinhaConta'

// MARKETPLACE (GESTÃO)
import Marketplace from '@/views/marketplace/Marketplace'
import Descontos from '@/views/marketplace/Descontos'
import Recompra from '@/views/marketplace/Recompra'
import Produtos from '@/views/marketplace/Produtos'
import Categorias from '@/views/marketplace/Categorias'
import Vendas from '@/views/marketplace/Vendas'
import Pedidos from '@/views/marketplace/Pedidos'
import ListaEntrega from '@/views/marketplace/ListaEntrega'
import CancelamentoEstorno from '@/views/marketplace/cancelamento/CancelamentoEstorno'
import EtiquetaCancelamentoMP from '@/views/marketplace/cancelamento/EtiquetaCancelamento'

// PARAMETRIZAÇÃO SHOP
import ParametrizacaoShop from '@/views/marketplace/parametrizacao/Parametrizacao'

// FORMULÁRIOS 2.0
import NpsCampanhas from '@/views/formularios/nps/Campanhas'
import NpsResposta from '@/views/formularios/nps/Resposta'

//MATRICULAS
import Matriculas from '@/views/matriculas/Matriculas'
import PesquisaMatriculas from '@/views/matriculas/matriculas/Matriculas'
import CriarMatricula from '@/views/matriculas/matriculas/Matricula'
import AutoMatricula from '@/views/matriculas/AutoMatricula'
import AppRematricula from '@/views/matriculas/AppRema'
import PropostasRematricula from '@/views/matriculas/propostasrema/PropostasRematricula'
import ListaMatriculas from '@/views/matriculas/matriculas/PrintList.vue'
import SimuladorPrecos from '@/views/matriculas/simulador/SimuladorPrecos.vue'
// import Remanejamento from '@/views/matriculas/remanejamento/RemanejamentoController.vue'

//FUNIL DE CAPTAÇÃO
import Captacao from '@/views/captacao/Captacao.vue'
import CaptacaoLeads from '@/views/captacao/leads/Leads.vue'
import GatilhosPipedrive from '@/views/captacao/parametrizacao/GatilhosPipedrive'

// FERRAMENTAS
import Ferramentas from '@/views/ferramentas/Ferramentas'
import FerramentasGatilhos from '@/views/ferramentas/gatilhos/Gatilhos'
import FerramentasIndicadores from '@/views/ferramentas/indicadores/Indicadores'
import RestauracaoBase from '@/views/ferramentas/base/Restauracao'
import Formularios from '@/views/ferramentas/formularios/Formularios.vue'
import GatilhosAtividades from '@/views/ferramentas/gatilhosAtividades/GatilhosAtividades.vue'
import OnlineUsers from '@/views/ferramentas/onlineUsers/OnlineUsers.vue'
import RedeChat from '@/components/ferramentas/redechat/views/RedeChatView.vue'
import Assistentes from '@/views/ferramentas/assistentes/Assistentes.vue'
import Alfred from '@/views/ferramentas/alfred/Alfred.vue'

// ALUNOS
import Alunos from '@/views/alunos/Alunos'
import SenhaEmail from '@/views/alunos/senhaEmail/SenhaEmail'
import Carteirinha from '@/views/alunos/carteirinha/Carteirinha.vue'

// FINANCEIRO
import Financeiro from '@/views/financeiro/Financeiro'
import PlanilhasFinanceiro from '@/views/financeiro/planilhas/PlanilhasFinanceiro'
import Parametros from '@/views/financeiro/parametros/Parametros'
import AlcadaDescontos from '@/views/financeiro/alcada/AlcadaDescontos'
import PlanosPagamento from '@/views/financeiro/planospagamento/PlanosPagamento'
import Bloqueados from '@/views/financeiro/bloqueados/Bloqueados'
import ModelagemPrecos from '@/views/financeiro/modelagemPrecos/ModelagemPrecos.vue'
import ModelagemReajustes from '@/views/financeiro/modelagemReajustes/ModelagemReajustes.vue'
import DeclaracaoIR from '@/views/financeiro/declaracaoIR/DeclaracaoIR.vue'

// SOLUÇÕES ESCOLARES
import SolucoesEscolares from '@/views/solucoesescolares/SolucoesEscolares'
import PlanilhasSolucoes from '@/views/solucoesescolares/planilhas/PlanilhasSolucoes'
import Estudai from '@/views/solucoesescolares/estudai/Estudai'
import PromptsEstudai from '@/views/solucoesescolares/estudai/Prompts'

// ESTRUTURA ACADEMICA
import EstruturaAcademica from '@/views/estruturaacademica/EstruturaAcademica'
import Turmas from '@/views/estruturaacademica/turmas/Turmas'
import ProgressaoPeriodo from '@/views/estruturaacademica/progressao/ProgressaoPeriodo'
import ProximaTurma from '@/views/estruturaacademica/progressao/ProximaTurma'
import Series from '@/views/estruturaacademica/series/Series'
import DocumentosRequeridos from '@/views/estruturaacademica/documentos/Documentos'
import Locais from '@/views/estruturaacademica/locais/Locais.vue'

// ATIVIDADES
import CategoriasAtendimentos from '@/views/atividades/CategoriasAtendimentos.vue'
import CategoriasAtividades from '@/views/atividades/CategoriasAtividades.vue'
import Atividades from '@/views/atividades/Atividades.vue'
import PainelAtividades from '@/views/atividades/Painel.vue'
import PlanilhasAtividades from '@/views/atividades/Planilhas.vue'
import PesquisaAtendimentos  from '@/views/atividades/Pesquisa.vue'

//ATENDIMENTO - CHATBOT
import Chatbot from '@/views/chatbot/Chatbot.vue'
import FaleComAne from '@/views/chatbot/falecomane/FaleComAne.vue'
import Conversas from '@/views/chatbot/falecomane/Conversas.vue'

//AGENDA EDU
import AgendaEdu from '@/views/agendaedu/AgendaEdu.vue'
import AgendaEduCanais from '@/views/agendaedu/canais/Canais.vue'

//PLANEJAMENTO DE VENDAS
import PlanejamentoVendas from '@/views/planejamentovendas/PlanejamentoVendas.vue'
import PlanilhasPv from '@/views/planejamentovendas/PlanilhasPv/PlanilhasPv.vue'
import DescontosAlunos from '@/views/planejamentovendas/DescontosAlunos/DescontosAlunos.vue'

//RENEGOCIAÇÃO
import Renegociacao from '@/views/financeiro/renegociacao/Renegociacao.vue'

// OUTROS
import sorteio from '@/views/sorteio/sorteio.vue'
import Forbidden from '@/views/forbidden/Forbidden.vue'
import Manutencao from '@/views/manutencao/Manutencao.vue'

//TECNOLOGIA
import DashboardProjetos from '@/views/tecnologia/DashboardProjetos.vue'
import Poc from '@/views/poc/Poc.vue'
import Precificacao from '@/views/poc/precificacao/Precificacao.vue'

import { useGtag, trackRouter } from "vue-gtag-next"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Rede Decisão - Apollo',
      requiresAuth: true
    },
    permissions: false
  },
  {
    path: '/redechat',
    name: 'Rede Chat',
    component: RedeChat,
    meta: {
      title: 'Rede Decisão - Rede Chat',
      requiresAuth: true
    },
    permissions: false
  },
  {
    path: '/linkexterno/:id/:isMetabase?/:isUrl?/',
    name: 'LinkExterno',
    component: LinkExterno,
    props: true,
    meta: {
      title: 'Rede Decisão - Link Externo',
      requiresAuth: true
    },
    permissions: [

    ]
  },
  {
    path: '/painel/:id/:hash?',
    name: 'Painel',
    component: Painel,
    props: true,
    meta: {
      title: 'Rede Decisão - Painel',
      requiresAuth: true
    }
  },
  {
    path: '/renegociacao/',
    name: 'Renegociação',
    component: Renegociacao,
    props: true,
    meta: {
      title: 'Rede Decisão - Negocie Já',
      requiresAuth: true,
      nonColab: true
    }
  },
  {
    path: '/login/:user?/:section?',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      title: 'Rede Decisão - Entrar',
      requiresAuth: false
    },
  },
  {
    path: '/centraldoaluno/:idAluno/:idPessoa/:action?',
    name: 'Central do Aluno',
    component: CentralAluno,
    props: true,
    meta: {
      title: 'Rede Decisão - Aluno',
      requiresAuth: true
    },
    permissions: false
  },
  {
    path: '/centraldoaluno/documentos/:id',
    name: 'DocumentosMatricula',
    component: DocumentosMatricula,
    props: true,
    meta: {
      title: 'Rede Decisão - Documentos Matrícula',
      requiresAuth: true
    },
    permissions: false
  },
  {
    path: '/docs/',
    name: 'Docs',
    component: Docs,
    meta: {
      title: 'Rede Decisão - Documentos',
      requiresAuth: true
    },
    permissions: [

    ]
  },
  {
    path: '/loja/',
    name: 'Loja',
    component: Loja,
    meta: {
      requiresAuth: true,
      title: 'Rede Decisão - Shop Decisão',
      nonColab: true
    },
    permissions: false
  },
  {
    path: '/loja/busca/:level/:filter/:query',
    name: 'Busca Loja',
    component: BuscaLoja,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Rede Decisão - Shop Decisão - Busca',
      nonColab: true
    },
    permissions: false
  },
  {
    path: '/loja/produto/:item/:tipo/:extra?',
    name: 'Produto',
    component: Produto,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Rede Decisão - Shop Decisão - Produto',
      nonColab: true
    },
    permissions: false
  },
  {
    path: '/loja/checkout/:stepLabel?',
    name: 'Checkout',
    component: Checkout,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Rede Decisão - Shop Decisão - Checkout',
      nonColab: true
    },
    permissions: false
  },
  {
    path: '/minhaconta/:section?/:item?/:detail?',
    name: 'Minha Conta',
    component: MinhaConta,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Rede Decisão - Shop Decisão - Minha Conta',
      nonColab: true
    },
    permissions: false
  },
  {
    path: '/loja/categoria/:idCategoria/:nome',
    name: 'Categoria',
    component: Categoria,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Rede Decisão - Shop Decisão - Categoria',
      nonColab: true
    },
    permissions: false
  },
  {
    path: '/marketplace',
    component: Marketplace,
    name: 'Marketplace',
    children: [
      {
        path: 'descontos/:target?/:descontoId?',
        name: 'Descontos',
        component: Descontos,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Descontos',
          requiresAuth: true
        },
        permissions: [
    
        ]
      },
      {
        path: 'recompra/:checkoutId?',
        name: 'Recompra',
        component: Recompra,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Recompra',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'produtos/:paramId?',
        name: 'Produtos',
        component: Produtos,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Produtos',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'categorias/',
        name: 'Categorias',
        component: Categorias,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Categorias',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'vendas/:idVendaMaterial?',
        name: 'Vendas e Transações',
        component: Vendas,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Vendas e Transações',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: '/marketplace/pedidos/:idPedido?',
        name: 'Pedidos',
        component: Pedidos,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Pedidos',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'lista-entrega',
        name: 'Lista Entrega',
        component: ListaEntrega,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Lista de Entrega de Pedidos',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'parametrizacao/:section?/:action?/:mid?/:target?',
        name: 'Parametrização Shop',
        component: ParametrizacaoShop,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Parametrização',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'cancelamento/:section?',
        name: 'Cancelamento e Estorno',
        component: CancelamentoEstorno,
        props: true,
        meta: {
          title: 'Rede Decisão - Marketplace - Cancelamento e Estorno',
          requiresAuth: true
        },
        permissions: [
          
        ]
      }
    ]
  },
  {
    path: '/nps',
    name: 'NPS',
    component: NpsCampanhas,
    props: true,
    meta: {
      title: 'Rede Decisão - NPS - Campanhas',
      requiresAuth: true
    },
    permissions: [
      
    ]
  },
  {
    path: '/nps/resposta',
    name: 'NPS',
    component: NpsResposta,
    props: true,
    meta: {
      title: 'Rede Decisão - NPS',
      nonColab: true
    },
    permissions: [
      
    ]
  },
  {
    path: '/boletos',
    name: 'Boletos',
    component: Boletos,
    props: true,
    meta: {
      title: 'Rede Decisão - Boletos',
      nonColab: true,
      requiresAuth: true
    },
    permissions: [
      
    ]
  },
  {
    path: '/matriculas',
    component: Matriculas,
    name: 'Matriculas',
    children: [
      {
        path: 'pesquisa/:action?',
        name: 'Pesquisa Matriculas',
        component: PesquisaMatriculas,
        props: true,
        meta: {
          title: 'Rede Decisão - Matrículas',
          requiresAuth: true
        }
      },
      {
        path: 'propostas-rematricula/:action?',
        component: PropostasRematricula,
        props: true,
        meta: {
          title: 'Propostas Rematrícula',
          requiresAuth: true
        }
      },
      {
        path: 'lista-matriculas',
        name: 'Lista Matrículas',
        component: ListaMatriculas,
        props: true,
        meta: {
          title: 'Rede Decisão - Lista de Matrículas',
          requiresAuth: true
        },
        permissions: [
          
        ]
      },
      {
        path: 'simulador/:idSerie?/:idFuncao?/:tipoMatricula?',
        name: 'Simulador de Preços',
        component: SimuladorPrecos,
        props: true,
        meta: {
          title: 'Rede Decisão - Simulador de Preços',
          requiresAuth: true
        },
      },
      // {
      //   path: 'remanejamento',
      //   name: 'Remanejamento',
      //   component: Remanejamento,
      //   meta: {
      //     title: 'Rede Decisão - Remanejamento',
      //     requiresAuth: true
      //   }
      // }
    ]
  },
  {
    path: '/matricula/:action?/:step?/:idMatricula?',
    name: 'Nova Matrícula',
    component: CriarMatricula,
    props: true,
    meta: {
      title: 'Rede Decisão - Matrículas - Nova Matrícula',
      requiresAuth: true
    }
  },
  {
    path: '/automatricula/:action?/:step?/:idMatricula?',
    name: 'Nova Matrícula Autônoma',
    component: AutoMatricula,
    props: true,
    meta: {
      title: 'Rede Decisão - Matrículas Autônoma - Nova Matrícula',
      nonColab: true,
      requiresAuth: true
    },
  },
  {
    path: '/rematricula/',
    name: 'App Rematrícula',
    component: AppRematricula,
    meta: {
      title: 'Rede Decisão - Matrículas Autônoma - Nova Matrícula',
      nonColab: true,
      requiresAuth: true
    }
  },
  {
    path: '/tecnologia/projetos',
    name: 'Projetos Tecnologia',
    component: DashboardProjetos,
    meta: {
      title: 'Rede Decisão - Projetos Tecnologia',
    }
  },
  {
    path: '/captacao',
    name: 'Captação',
    component: Captacao,
    children: [
      {
        path: 'leads/:action?/:idProspect?',
        component: CaptacaoLeads,
        name: 'Leads',
        props: true,
        meta: {
          title: 'Rede Decisão - Funil de Captação - Formulário de Leads',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/captacao/parametrizacao/gatilhos-pipedrive',
    name: 'Gatilhos Pipedrive',
    component: GatilhosPipedrive,
    meta: {
      title: 'Rede Decisão - Funil de Captação - Parametrização - Gatilhos Pipedrive',
      requiresAuth: true
    }
  },
  {
    path: '/ferramentas',
    component: Ferramentas,
    name: 'Ferramentas',
    children: [
      {
        path: 'indicadores',
        component: FerramentasIndicadores,
        props: true,
        meta: {
          title: 'Parametrização Indicadores',
          requiresAuth: true
        }
      },
      {
        path: 'gatilhos/:action?',
        component: FerramentasGatilhos,
        props: true,
        meta: {
          title: 'Parametrização Gatilhos',
          requiresAuth: true
        }
      },
      {
        path: 'restauracao-base',
        component: RestauracaoBase,
        meta: {
          title: 'Restauração de Base',
          requiresAuth: true
        }
      },
      {
        path: 'formularios/:action?/:id?',
        component: Formularios,
        name: "Formulários",
        props: true,
        meta: {
          title: 'Formulários',
          requiresAuth: true
        }
      },
      {
        path: 'gatilhos-atividades/:idCategoria?/:idFormulario?/:perfilPessoa?',
        component: GatilhosAtividades,
        name: "Gatilhos Atividades",
        props: true,
        meta: {
          title: 'Gatilhos Atividades',
          requiresAuth: true
        }
      },
      {
        path: 'online-users',
        component: OnlineUsers,
        name: "Usuários Online",
        props: true,
        meta: {
          title: 'Usuários Online',
          requiresAuth: true
        }
      },
      {
        path: 'assistentes/:id?',
        component: Assistentes,
        name: "Assistentes",
        props: true,
        meta: {
          title: 'Assistentes',
          requiresAuth: true
        }
      },
      {
        path: 'alfred/:id?',
        component: Alfred,
        name: "Alfred",
        props: true,
        meta: {
          title: 'Alfred',
          requiresAuth: true
        }
      },
    ],
    meta: {
      title: 'Rede Decisão - Ferramentas',
      requiresAuth: true
    }
  },
  {
    path: '/estrutura-academica',
    component: EstruturaAcademica,
    name: 'Estrutura Acadêmica',
    children: [
      {
        path: 'turmas/:action?',
        component: Turmas,
        props: true,
        name: 'Estrutura Acadêmica - Turmas',
        meta: {
          title: 'Turmas',
          requiresAuth: true
        }
      },
      {
        path: 'progressao-blocked',
        component: ProgressaoPeriodo,
        props: true,
        name: 'Estrutura Acadêmica - Progressão Período',
        meta: {
          title: 'Progressão Período',
          requiresAuth: true
        }
      },
      {
        path: 'proxima-turma',
        component: ProximaTurma,
        props: true,
        name: 'Estrutura Acadêmica - Próxima Turma',
        meta: {
          title: 'Próxima Turma',
          requiresAuth: true
        }
      },
      {
        path: 'series/:action?/:target?',
        component: Series,
        props: true,
        name: 'Estrutura Acadêmica - Séries',
        meta: {
          title: 'Séries',
          requiresAuth: true
        }
      },
      {
        path: 'documentos/:action?',
        component: DocumentosRequeridos,
        props: true,
        name: 'Estrutura Acadêmica - Documentos',
        meta: {
          title: 'Documentos',
          requiresAuth: true
        }
      },
      {
        path: 'locais/:idLocal?',
        component: Locais,
        props: true,
        name: 'Locais',
        meta: {
          title: 'Estrutura Acadêmica - Locais',
          requiresAuth: true
        }
      },
    ],
    meta: {
      title: 'Rede Decisão - Estrutura Acadêmica',
      requiresAuth: true
    }
  },
  {
    path: '/alunos',
    component: Alunos,
    name: 'Alunos',
    children: [
      {
        path: 'restaura-senha-email-aluno',
        name: 'Restaura Senha Aluno',
        component: SenhaEmail,
        props: true,
        meta: {
          title: 'Restaurar senha email',
          requiresAuth: true,
          nonColab: true
        }
      },
      {
        path: 'carteirinha/:idPessoa',
        name: 'Carteirinha Aluno',
        component: Carteirinha,
        props: true,
        meta: {
          title: 'Carteirinha Aluno',
          requiresAuth: true,
          nonColab: false
        }
      }
    ],
    meta: {
      title: 'Rede Decisão - Alunos',
      requiresAuth: true
    }
  },
  {
    path: '/pv',
    component: PlanejamentoVendas,
    name: 'Planejamento de Vendas',
    children: [
      {
        path: 'planilhas',
        name: 'Planilhas PV',
        component: PlanilhasPv,
        props: true,
        meta: {
          title: 'Planejamento de Vendas - Planilhas',
          requiresAuth: true,
        }
      },
      {
        path: 'descontos-alunos',
        name: 'Descontos Aluno',
        component: DescontosAlunos,
        props: true,
        meta: {
          title: 'Planejamento de Vendas - Descontos por Aluno',
          requiresAuth: true,
        }
      },
    ]
  },
  {
    path: '/financeiro',
    component: Financeiro,
    name: 'Financeiro',
    children: [
      {
        path: 'planilhas/:action?',
        component: PlanilhasFinanceiro,
        props: true,
        meta: {
          title: 'Planilhas Financeiro',
          requiresAuth: true
        }
      },
      {
        path: 'parametros/:target?',
        component: Parametros,
        name: 'Parâmetros Financeiros',
        props: true,
        meta: {
          title: 'Parâmetros Financeiros',
          requiresAuth: true
        }
      },
      {
        path: 'alcada/:action?',
        component: AlcadaDescontos,
        name: 'Alçada de Descontos',
        props: true,
        meta: {
          title: 'Alçada de Descontos',
          requiresAuth: true
        }
      },
      {
        path: 'planospagamento/:section?',
        component: PlanosPagamento,
        name: 'Planos de Pagamento',
        props: true,
        meta: {
          title: 'Planos de Pagamento',
          requiresAuth: true
        }
      },
      {
        path: 'bloqueados',
        component: Bloqueados,
        name: 'Bloqueados',
        props: true,
        meta: {
          title: 'Bloqueados',
          requiresAuth: true
        }
      },
      {
        path: 'extrato/:idPessoa?/:declaracao?',
        component: ExtratoFinanceiro,
        name: 'Extrato Financeiro',
        props: true,
        meta: {
          title: 'Ficha Financeiro',
          requiresAuth: true,
          nonColab: true
        }
      },
      {
        path: 'modelagem-precos',
        component: ModelagemPrecos,
        name: 'Modelagem de Preços',
        props: true,
        meta: {
          title: 'Modelagem de Preços',
          requiresAuth: true
        }
      },
      {
        path: 'modelagem-reajustes',
        component: ModelagemReajustes,
        name: 'Modelagem de Reajustes',
        props: true,
        meta: {
          title: 'Modelagem de Reajustes',
          requiresAuth: true
        }
      },
      {
        path: 'ir',
        component: DeclaracaoIR,
        name: 'Declaracao IR',
        props: true,
        meta: {
          title: 'Imposto de Renda',
          requiresAuth: true,
          nonColab: true
        }
      }
    ],
    meta: {
      title: 'Rede Decisão - Financeiro',
      requiresAuth: true
    }
  },
  {
    path: '/solucoesescolares',
    component: SolucoesEscolares,
    name: 'Soluções Escolares',
    children: [
      {
        path: 'planilhas/:action?',
        component: PlanilhasSolucoes,
        props: true,
        meta: {
          title: 'Soluções Escolares - Planilhas',
          requiresAuth: true
        }
      },
      {
        path: 'conteudosprogramaticos/:conteudo?/:topico?',
        component: Estudai,
        props: true,
        name: 'Conteúdos Programáticos',
        meta: {
          title: 'Soluções Escolares - Conteúdos Programáticos',
          requiresAuth: true
        }
      },
      {
        path: 'prompts/:action?/:prompt?',
        component: PromptsEstudai,
        props: true,
        name: 'Prompts EstudAI',
        meta: {
          title: 'EstudAI - Prompts Conteúdos',
          requiresAuth: true
        }
      }
    ],
    meta: {
      title: 'Rede Decisão - Soluções Escolares',
      requiresAuth: true
    }
  },
  {
    path: '/atividades',
    component: Atividades,
    name: 'Atividades',
    children: [
      {
        path: 'painel/:section?/:action?/:id?',
        name: 'Painel Atividades',
        component: PainelAtividades,
        props: true,
        meta: {
          title: 'Atividades',
          requiresAuth: true
        }
      },
      {
        path: 'planilhas',
        component: PlanilhasAtividades,
        props: true,
        meta: {
          title: 'Planilhas Atividades',
          requiresAuth: true
        }
      },
      {
        path: 'categorias',
        component: CategoriasAtividades,
        props: true,
        meta: {
          title: 'Categorias Atividades',
          requiresAuth: true
        }
      },
      {
        path: 'atendimentos/categorias',
        component: CategoriasAtendimentos,
        props: true,
        meta: {
          title: 'Categorias Atendimentos',
          requiresAuth: true
        }
      },
      {
        path: 'pesquisa',
        component: PesquisaAtendimentos,
        props: true,
        meta: {
          title: 'Pesquisar Atendimentos',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/administrativo',
    component: Administrativo,
    name: 'Administrativo',
    children: [
      {
        path: 'colaboradores/:action?/:id?',
        component: Colaboradores,
        props: true,
        meta: {
          title: 'Colaboradores',
          requiresAuth: true
        }
      },
      {
        path: 'modulos',
        name: 'Módulos',
        component: Modulos,
        meta: {
          title: 'Rede Decisão - Módulos',
          requiresAuth: true
        }
      },
      {
        path: 'permissoes',
        name: 'Permissões',
        component: Permissoes,
        meta: {
          title: 'Rede Decisão - Permissões',
          requiresAuth: true
        }
      },
      {
        path: 'funcoes',
        name: 'Funções',
        component: Funcoes,
        meta: {
          title: 'Rede Decisão - Funções',
          requiresAuth: true
        }
      },
    ],
    meta: {
      title: 'Rede Decisão - Financeiro',
      requiresAuth: true
    }
  },
  {
    path: '/sorteiocumbuca/',
    name: 'sorteio',
    component: sorteio,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/etiqueta-cancelamento/:solicitacao',
    name: 'Etiqueta Cancelamento',
    component: EtiquetaCancelamentoMP,
    props: true,
    meta: {
      title: "Imprimir Etiqueta de Cancelamento",
      requiresAuth: false,
      nonColab: true,
    }
  },
  {
    path: '/agendaedu',
    component: AgendaEdu,
    name: 'AgendaEdu',
    children: [
      {
        path: 'canais/:action?/:id?',
        component: AgendaEduCanais,
        name: 'AgendaEdu Canais',
        props: true,
        meta: {
          title: 'AgendaEdu - Canais',
          requiresAuth: true
        }
      },
    ]
  },
  {
    path: '/atendimento',
    component: Chatbot,
    name: 'atendimento',
    children: [
      {
        path: 'falecomaane',
        component: FaleComAne,
        name: 'Fale com a Ane',
        props: true,
        meta: {
          title: 'Fale com a Ane',
          requiresAuth: false,
          nonColab: true
        }
      },
      {
        path: 'conversas/:id?',
        component: Conversas,
        name: 'Fale com a Ane - Conversas',
        props: true,
        meta: {
          title: 'Fale com a Ane',
          requiresAuth: true,
          nonColab: false
        }
      },
    ]
  },
  {
    path: '/erro-login/',
    name: 'Erro Login',
    component: ErroLogin,
    props: true,
    meta: {
      requiresAuth: false,
      nonColab: true,
    }
  },
  {
    path: '/forbidden/',
    name: 'Sem Permissão',
    component: Forbidden,
    props: false,
    meta: {
      requiresAuth: false,
      nonColab: true,
    }
  },
  {
    path: '/manutencao/',
    name: 'Manutenção',
    component: Manutencao,
    props: false,
    meta: {
      requiresAuth: false,
      nonColab: false,
    }
  },
  {
    path: '/poc/',
    name: 'POC',
    component: Poc,
    props: false,
    meta: {
      requiresAuth: false,
      nonColab: false
    },
    children: [
      {
        path: 'precificacao',
        component: Precificacao,
        props: true,
        name: 'POC - Precificação',
        meta: {
          title: 'Turmas',
          requiresAuth: true
        }
      }
    ]
  }
]

const routelist = []

routes.map( k => {
  const item = {
    path: k.path,
    name: k.name,
    meta: k.meta
  }
  routelist.push(item)
})

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {

  const localToken = localStorage.getItem('token')
  const token = to.query.token
  const email = to.query.email
  const aeToken = to.query.aetoken

  var user = localStorage.getItem('profile')
      user = user ? JSON.parse(atob(user)) : null

  const isColab = user?.colaborador

  setTimeout(() => {
    if ( to.meta.title ) {
      document.title = to.meta.title
    }
  },0)

  const getManutencao = await AuthService.getConfig()
  const emManutencao = getManutencao.data.emManutencao

  if ( to.meta.requiresAuth ) {
    if ( aeToken ) {
      // console.log("aeToken:", aeToken)
      try {
        const loginEdu = await AuthService.loginAgendaEdu( aeToken )
        const userEmail = loginEdu.data.data.attributes.email

        next({name: to.name, replace: true, query:{ token: process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN, email: userEmail }})
      } catch ( error ) {
        next({ name: 'Login' })
      }
    } else if ( token && email ) {
      try {
        const login = { 
            apiToken: token,
            userEmail: email,
            portal: 'portalGestao'
        }
        await AuthService.loginApi(login)
        
        if ( to.meta.nonColab ) {
          next({ name: to.name, replace: true, query: {} })
        } else {
          if ( isColab ) {
            next({ name: to.name, replace: true, query: {} })
          } else {
            next({ name: 'Loja', replace: true, query: {} })
          }
        }
        
      } catch (error) {
        next({ name: 'Login' })
      }
    } else if ( localToken ) {
      try {
        await AuthService.verifyLocalToken(localToken)

        if ( to.meta.nonColab ) {
          next()
        } else {
          if ( isColab ) {
            next()
          } else {
            if (to.name !== 'Loja') {
              next({ name: 'Loja', replace: true, query: {} })
            } else {
              next() // Allow navigation if already on the right route
            }
          }
        }
      } catch (error) {
        next({ name: 'Login' }); // Redirect to login on error
      }
    } else {
      if ( emManutencao ) {
        next({ name: 'Manutenção', force: true })
        return
      } else {
        next({ name: 'Login' })
      }
    }
  } else {
    //paginas sem autenticação (pública)
    next() 
  }
})

router.afterEach((to) => {
  const { pageview, event } = useGtag()

  pageview({
    page_path: to.path,
  })

  event(to.path, {
    'event_category': 'page_view',
    'event_label': to.path
  })
})

trackRouter(router)

export default router