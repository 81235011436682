<template>
  <section class="detalhesProduto" >
    <div v-if="produto" class="frame">
      <div>
        <div v-if="thumb" class="productImage" :style="{backgroundImage: 'url(' + thumb + ')'}"></div>
      </div>
      <div>
        <h6>{{ produto.servicoExtra == 1 ? produto.categoriadescricao : produto.nomeCategoria }}</h6>
        <h1>{{ produto.descricaovenda ? produto.descricaovenda : produto.descricaoVenda }}</h1>
        
        <div class="preco" v-if="canBuy(produto).can">
          <!-- <h4>{{ produto.servicoExtra == 1 || produto.precoitem == 0 ? ( produto.precoVenda ? formatMoney(produto.precoVenda) : formatMoney(produto.PrecoVenda) ) : formatMoney(produto.valorVenda) }}</h4>
          <h2>3x {{ produto.servicoExtra == 1 || produto.precoitem == 0 ? formatMoney(divide( ( produto.precoVenda ? produto.precoVenda : produto.PrecoVenda ), 3)) : formatMoney(divide(produto.valorVenda, 3)) }}</h2> -->
          <template v-if="produto.percentual && parseFloat(produto.percentual) > 0 || produto.allowDesconto && produto.UnidadeDesconto">
            <span>
              <h5><del>{{
                produto.servicoextra === 1 || produto.precoitem == 0 || !produto.valorVenda ? formatMoney(produto.PrecoVenda) : formatMoney(produto.valorVenda)
              }}</del></h5>
              <h4 v-if="produto.UnidadeDesconto && produto.percentual">{{
                produto.servicoextra === 1 || produto.precoitem == 0 || !produto.valorVenda ? formatMoney(desconto(desconto(produto.PrecoVenda, produto.percentual), produto.UnidadeDesconto)) : formatMoney(desconto(desconto(produto.valorVenda, produto.percentual),produto.UnidadeDesconto)) 
              }}</h4>
              <h4 v-else-if="produto.UnidadeDesconto && !produto.percentual">{{
                produto.servicoextra === 1 || produto.precoitem == 0 || !produto.valorVenda ? formatMoney(desconto(produto.PrecoVenda, produto.UnidadeDesconto)) : formatMoney(desconto(produto.valorVenda, produto.UnidadeDesconto)) 
              }}</h4>
              <h4 v-else>{{
                produto.servicoextra === 1 || produto.precoitem == 0 || !produto.valorVenda ? formatMoney(desconto(produto.PrecoVenda, produto.percentual)) : formatMoney(desconto(produto.valorVenda, produto.percentual)) 
              }}</h4>
            </span>
            <h3>{{ getParcelamento(produto, true) }}</h3>
            <h6 class="color-text">{{ getParcelamento(produto, true, true) }}</h6>
          </template>
          <template v-else>
            <h5>{{ 
              produto.servicoextra === 1 || produto.precoitem == 0 ? formatMoney(produto.PrecoVenda) : formatMoney(produto.valorVenda) 
            }}</h5>
            <h3>{{ getParcelamento(produto, false) }}</h3>
            <h6 class="color-text">{{ getParcelamento(produto, false, true) }}</h6>
          </template>
        </div>
        <div>
          <add-to-cart 
            mode="page"
            :product="produto"
            @getConfig="getConfig"
            ref="addToCart"
            v-if="canBuy(produto).can"
          ></add-to-cart>
          <template v-else>
            <div class="noBuyAlert" :title="canBuy(produto).message" >
              <fa :icon="['fas', 'user-lock']" :class="['icon', { on: canBuy(produto).motive != 'comprado' }]"></fa>
              <fa :icon="['fas', 'box-check']" :class="['icon', { on: canBuy(produto).motive == 'comprado' }]" ></fa>
            </div>
            <a href="/loja" class="btn btn-primary goBackButton">
              Voltar para a página inicial
            </a>
          </template>
          
          <div :class="['detailModal', { on: config || amount }]">
            <div>
              <transition name="flipin">
                <product-config
                  v-if="config == 'grade'"
                  :produto="produto"
                  class="configSelector"
                  type="grade"
                  @select="setSize"
                  :selected="produto.selectedSize"
                  @goback="goBack('grade')"
                ></product-config>
              </transition>
              <transition name="flipin">
                <product-config
                  v-if="config == 'aluno'"
                  :produto="produto"
                  :selected="produto.selectedAluno"
                  type="aluno"
                  class="configSelector"
                  @select="setAluno"
                  @goback="closeConfig()"
                ></product-config>
              </transition>
              <transition name="flipin">
                <product-config
                  v-if="config == 'turma'"
                  :produto="produto"
                  class="configSelector"
                  type="turma"
                  @select="setTurma"
                  :selected="produto.selectedTurma"
                  @goback="goBack('turma')"
                ></product-config>
              </transition>
              <transition name="flipin">
                <amount-selector
                  v-if="amount"
                  :produto="produto"
                  class="configSelector"
                  @updateamount="updateAmount($event,i)"
                  @addtocart="addToCart"
                  @goback="goBack('quantidade')"
                ></amount-selector>
              </transition>
            </div>
          </div>
          <!-- <a href="#" @click.prevent="" class="btn btn-primary btn-super">Comprar com um click</a> -->
        </div>
        <template v-if="produto.detalhes">
          <h5>Detalhes</h5>
          <p v-html="produto.detalhes"></p>
        </template>
        <!-- <div v-else>
          <div class="noBuyAlert">
            <fa :icon="['fas', 'user-lock']" class="icon"></fa>
            <p v-if="!hasLogin">Para comprar este produto,<br>
               você precisa estar logado.</p>
            <p v-else>Você não pode comprar este produto, pois ele é restrito a uma turma específica.</p>
          </div>
          <a href="#" @click.prevent="" class="btn btn-primary btn-super btn-arrow arrow-up">Fazer login</a>
        </div> -->
        <confirm ref="confirm"></confirm>
      </div>
    </div>
  </section>
</template>

<script>
import { formatMoney, divide } from '@/services/utils.service'
import AddToCart from '@/components/loja/utilities/AddToCart'
import ProductConfig from '@/components/loja/utilities/cart/ProductConfig'
import AmountSelector from '@/components/loja/utilities/cart/AmountSelector'
import LojaService from '@/services/loja.service'
import Confirm from '@/components/utilities/Confirm.vue'

export default {
  name: 'Product',
  components: {
    AddToCart, ProductConfig, AmountSelector, Confirm
  },
  props: {
    detalhes: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    detalhes ( value ) {
      console.log("value:", value)

      if ( value ) {
        const eligibilty = LojaService.processProductsAccess([value], this.currentUser, value.idcategorialoja)
        this.podeComprar = eligibilty.podeComprar
        // console.log("eligibilty:", eligibilty)
        this.produto = eligibilty[0]
        this.setImageRoute()
        this.alert()
        console.log("this.produto - detalhes:", this.produto)
      }
      // console.log("detalhes:", value)
    }
  },
  data () {
    return {
      produto: null,
      logged: false,
      needLogin: false,
      config: null,
      amount: false,
      grade: null,
      aluno: false,
      alunos: [],
      podeComprar: false,
      hasLogin: false,
      hasAluno: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      thumb: null
    }
  },
  mounted () {
    console.log("this.produto", this.detalhes)
    
    // this.alert()
  },
  methods: {
    alert () {
      if ( this.detalhes.idtipomaterial == 3607) {
        setTimeout(() => {
          this.$refs.confirm.run({
            message: "<b class='color-primary'>Atenção!</b>",
            obs: "A Copinha Decisão é um evento <b>exclusivo para os alunos do Futsal que estejam no 1º ao 5º ano.</b><br>Se o estudante não atende a esses critérios, não é possível participar.",
            confirmButton: "Entendi",
            denyButton: false,
            messageAlign: 'center',
            size: 'small',
            callback: this.closeModal,
          })
        },1000)
      }
    },
    closeModal () {
      console.log("continue..")
    },  
    formatMoney ( value ) {
      if ( value ) {
        return formatMoney(value)
      }
    },
    divide ( value, times ) {
      return divide(value,times)
    },
    setImageRoute () {
      // console.log("setImageRoute:", route)
      const route = this.produto.imgproduto

      if ( route ) {
        LojaService.setImageRoute(route).then(
          response => {
            this.thumb = response.data
          }
        )
      } else return '/img/thumb_nopic.jpg'

    },
    setSize ( size ) {
      console.log("setSize:", size)
      this.produto.selectedSize = size

      // console.log("produto:", this.produto)

      if ( this.produto.comprasMultiplas == 1 ) {
        this.config = null
        this.amount = true
      } else {
        this.addToCart()
      }
    },
    setAluno ( aluno ) {
      // console.log("setAluno:", aluno)
      // console.log('selectedAluno:', this.produto.selectedAluno)

      const exist = this.produto.selectedAluno.filter( k => {
        return aluno.idAluno == k.idAluno
      }).length > 0

      if ( !exist ) {
        this.produto.selectedAluno.push(aluno)
      } else {
        console.log("aluno a selecionado...")
      }
      
      if ( this.produto.servicoextra == 1  && this.produto.comprasMultiplas == 1) {
        if ( this.produto.categoriaLivre == 1 ) {
          this.config = null
          this.amount = true
        } else if ( this.produto.comprasMultiplas == 0 ) {
          this.addToCart()
        } else {
          this.getConfig('turma')
        }
        
      } else if ( this.produto.gradeProduto == 1 ) {
        this.getConfig('grade')
      } else if ( this.produto.numeroturmas == 0 ) {
        this.addToCart()
      }
      
      // console.log("produto:", this.produto)
    },
    setTurma ( turma ) {
      // console.log("setTurma:", turma)
      this.produto.selectedTurma.push(turma)
      
      if ( this.produto.comprasMultiplas == 1 && this.produto.servicoextra != 1 ) {
        this.config = null
        this.amount = true
      } else {
        this.addToCart()
      }
    },
    updateAmount (amount) {
      this.produto.quantidade = amount
      this.amountValue = amount
    },
    addToCart () {
      // console.log("addToCart - item:", this.produto)
      this.$refs.addToCart.addToCart(this.produto)

      setTimeout(() => {
        this.closeConfig()
      },500)
      
    },
    getConfig ( config ) {
      this.config = config
    },
    closeConfig () {
      this.config = null
      this.amount = false

      this.$refs.addToCart.resetCartButton()

      setTimeout(() => {
        this.produto.selectedAluno = []
        this.produto.selectedTurma = []
      },1000)
    },
    goBack ( source ) {
      if ( source === 'grade' || source === 'turma') {
        this.produto.selectedAluno = []
        this.config = 'aluno'
      }
      if ( source === 'quantidade' ) {
        this.amount = false
        this.config = 'grade'
      }
    },
    canBuy ( produto ) {
      // console.log("canBuy - produto:", produto)
      // console.log("currentUser:", this.currentUser)
      
      const hasLogin = this.currentUser != null
      this.hasLogin = hasLogin

      const hasAluno = this.currentUser?.alunosProdutos != null
      this.hasAluno = hasAluno

      const allowed = produto.podeComprar
      const bloqueioCompra = produto.bloqueioCompra

      // console.log("hasLogin:",hasLogin)
      // console.log("bloqueioCompra:",bloqueioCompra)
      // console.log("allowed:",allowed)

      var message = "Para comprar este produto você precisa estar logado"
      let can = false
      let motive = ""

      if ( produto.categoriaLivre == 1 && !bloqueioCompra ) {
        can = true
        message = "Compra Liberada"
        motive = "livre"
      } else {
        if ( bloqueioCompra ) {
          can = false
          message = "Ops. Infelizmente você não pode comprar este produto. Quer saber mais? Procure nosso time de atendimento."
          motive = "bloqueio-financeiro"
        } else if ( hasLogin && hasAluno && allowed && produto.comprado != 1 ) { 
          can = true
          motive = 'allowed'
        } else if ( produto.comprado == 1 ){
          can = false
          message = "Você já comprou este produto"
          motive = "comprado"
        } else if ( !allowed ) {
          can = false
          message = "Você não pode comprar este produto pois ele é restrito a uma turma específica."
          motive = "seguimento"
        }
      }

      const drop = {
        can, message, motive
      }
      
      console.log("drop:", drop)
      return drop
    },
    desconto ( value, discount ) {
      return LojaService.calcDesconto (value, discount)
    },
    getParcelamento ( produto, desconto, label ) {
      // console.log("getParcelamento - produto:", produto)
      // console.log("getParcelamento - desconto:", desconto)
      
      const fp = produto.formasPagamento

      // console.log("fp:", fp)
      var parcelas, sfp

      if ( fp ) {
        const ordered = produto.formasPagamento.sort((a,b) => b.parcelas - a.parcelas)
        console.log("ordered:", ordered)
        // parcelas = Math.max.apply( Math, produto.formasPagamento.map( o => { return o.parcelas } ) )
        parcelas = ordered[0].parcelas

        console.log("parcelas:", parcelas)

        sfp = ordered[0].cartao == 1 ? 'no cartão' : 'no boleto'

        console.log("sfp:", sfp)
      }
      
      if ( desconto ) {
        var value
        if ( produto.servicoextra === 1 || produto.precoitem === 0 ) {
          if ( produto.UnidadeDesconto && produto.percentual ) {
            value = formatMoney(divide(this.desconto(this.desconto(produto.PrecoVenda, produto.percentual), produto.UnidadeDesconto), parcelas))
          } else if ( produto.UnidadeDesconto && !produto.percentual ) {
            value = formatMoney(divide(this.desconto(produto.PrecoVenda, produto.UnidadeDesconto), parcelas))
          } else {
            value = formatMoney(divide(this.desconto(produto.PrecoVenda, produto.percentual), parcelas))
          }
        } else {
          if ( produto.UnidadeDesconto && produto.percentual ) {
            value = formatMoney(divide(this.desconto(this.desconto(produto.valorVenda, produto.percentual), produto.UnidadeDesconto), parcelas))
          } else if ( produto.UnidadeDesconto && !produto.percentual ) {
            value = formatMoney(divide(this.desconto(produto.valorVenda, produto.UnidadeDesconto)), parcelas)
          } else {
            value = formatMoney(divide(this.desconto(produto.valorVenda, produto.percentual), parcelas))
          }
          
        }
        // const 
        return label ? sfp : ( 'até ' + parcelas + 'x ' + value )
      } else {
        const value = produto.servicoextra === 1 || produto.precoitem === 0 ? formatMoney(divide(produto.PrecoVenda, parcelas)) : formatMoney(divide(produto.valorVenda, parcelas))
        return label ? sfp : ( 'até ' + parcelas + 'x ' + value )
      }
      
    }
  }
}
</script>

<style lang="scss">
  .detalhesProduto {
    width: 100%; padding: $mg $hmg;
    background-color: $color-secondary;

    > div {
      display: flex; align-content: center; align-items: flex-start;
      width: 100%;

      > div {
        flex: 1; width: 50%; position: relative;

        &:first-child {
          display: flex; align-items: center; align-content: flex-end; justify-content: flex-end;

          .productImage {
            display: block;
            width: 640px; padding-top: 100%;
            background-color: $color-light;
            background-size: cover; background-repeat: no-repeat;
            background-position: center;
            border-radius: $border-radius;
          }
        }
        &:last-child {
          padding: $hmg 0 $mg $mg;

          h4 {
            line-height: 1;
            margin-bottom: $hmg_small;
            color: $color-text !important;
          }

          

          h1 {
            color: $color-text !important;
            line-height: 1; width: 60%;
            font-size: 44px;
            margin-bottom: $hmg_small;
          }

          p {
            // font-size: 13px; line-height: 1.4;

            @media screen and (min-width: 1220px) {
              max-width: 440px;
            }
          }

          .preco {
            margin-top: $mg;

            > * {
              line-height: 1;
              color: $color-text !important
            }

            >span {
              display: flex;
              align-items: center; align-content: flex-end;

              >* {
                margin-right: $hmg_mini;
              }

              h5 {
                color: $color-text !important;
              }
            }
          }
        }
      }

      @media screen and ( max-width: 1024px ) {
        flex-direction: column; align-content: center;

        > div {
          width: 100%;

          &:last-child { 
            padding: $hmg $hmg_mid $hmg_mid $hmg_mid;

            h1 {
              font-size: 30px; width: 100%;
            }
          }

           
        }
        .productImage {
          width: 100%; max-width: 640px;
        }
      }

    }

    .noBuyAlert {
      display: flex; align-content: center; align-items: center;
      color: $color-primary;
      margin: $hmg 0 $hmg;


      .icon {
        font-size: 30px;
        margin: 0; padding: 0;
        opacity: 0; pointer-events: none;
        position: absolute;

        &.on {
          opacity: 1; pointer-events: all;
          position: relative;
        }
      }

      p {
        font-size: 12px; font-weight: bold;
        margin: 0 0 0 $hmg_mini; padding: 0; line-height: 1 !important;
      }
    }

    .detailModal {
      display: flex; align-content: flex-start; align-items: flex-start;
      justify-content: center;
      position: absolute; top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: #FFF;
      opacity: 0; pointer-events: none;
      @extend %transition_3o;

      >div {
        max-width: 310px; width: 100%; height: auto;
        margin-top: 250px;
        position: relative;
      }

      &.on {
        opacity: 1; pointer-events: all;
      }
    }
  }

  .goBackButton {
    margin-bottom: $mg
  }
</style>