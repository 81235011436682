import axios from 'axios'
import authHeader from './auth-header'
class UserService {
  getMenuPortal() {
    const profile = localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    let idFuncao = profile.colaborador?.idFuncao
    return axios.get(process.env.VUE_APP_API_URL + 'buildmenubyfuncao/' + idFuncao, { headers: authHeader() })
  }

  getUnidades () {
    return axios.get(process.env.VUE_APP_API_URL + 'public/unidades/0/0/0', { headers: authHeader() })
  }
  
  getMinhasUnidades () {
    return axios.get(process.env.VUE_APP_API_URL + 'auth/unidades', { headers: authHeader() })
  }

  getPessoasByNome ( query ) {
    return axios.get(process.env.VUE_APP_API_URL + 'getpessoasbynome/' + query, { headers: authHeader() })
  }

  getPessoasByIdPessoa ( idPessoa ) {
    return axios.get(process.env.VUE_APP_API_URL + 'pessoa/' + idPessoa, { headers: authHeader() })
  }

  filterPermissions ( permission ) {
    const currentUser = localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    // console.log("currentUser:", currentUser)
    const userPermissions = currentUser.permissions

    // console.log("userPermissions:", userPermissions)
    // console.log("permission:", permission)
    
    return userPermissions.filter( k => {
      return k == permission
    }).length > 0

  }

  //boletos
  getBoletos ( idMatricula ) {
    if ( idMatricula ) {
      return axios.get(process.env.VUE_APP_API_URL + 'boletos/' + idMatricula, { headers: authHeader() })
    } else {
      return axios.get(process.env.VUE_APP_API_URL + 'boletos', { headers: authHeader() })
    }
  }

  getUrlBoletoPerseus ( menCodigo ) {
    return axios.post(process.env.VUE_APP_API_URL + 'boleto/perseus/' + menCodigo, {}, { headers: authHeader() })
  }

  getUrlBoleto ( menCodigo, gestao ) {
    const apollo = gestao ? 1 : 0
    return axios.get(process.env.VUE_APP_API_URL + 'boleto/' + menCodigo + '/' + apollo, { headers: authHeader(), responseType: 'blob' })
  }

  getAtualizacaoDatas ( boleto ) {
    const menCodigo = (boleto.origem === 'perseus') ? boleto.transacao : 0;
    const idTransacaoVenda = (boleto.origem === 'pagarMe') ? boleto.transacao : 0;
    // idTransacao
    return axios.get(process.env.VUE_APP_API_URL + 'boleto/atualizacao/datas/' + menCodigo + '/' + idTransacaoVenda, { headers: authHeader() })
  }

  atualizarBoleto ( options ) {
    const data = options.data
    const menCodigo = options.tipo == 'perseus' ? options.transacao : 0
    const idTransacaoVenda = options.tipo == 'pagarMe' ? options.transacao : 0

    return axios.put(process.env.VUE_APP_API_URL + 'boleto/atualizacao/' + data + '/' + menCodigo + '/' + idTransacaoVenda, {}, { headers: authHeader() })
    // /boleto/atualizacao/${dataPagto}/${menCodigo}/${idTransacaoVenda}
  }

  getAtualizacaoDatasExtrato ( boleto ) {
    const menCodigo = (boleto.plataforma == 'Perseus') ? boleto.mencodigo : 0;
    const idTransacaoVenda = (boleto.plataforma == 'Apollo') ? boleto.numero_titulo : 0;
    // idTransacao
    return axios.get(process.env.VUE_APP_API_URL + 'boleto/atualizacao/datas/' + menCodigo + '/' + idTransacaoVenda, { headers: authHeader() })
  }

  atualizarBoletoExtrato ( options ) {
    const data = options.data
    const menCodigo = options.boleto.plataforma == 'Perseus' ? options.mencodigo : 0
    const idTransacaoVenda = options.boleto.plataforma == 'Apollo' ? options.numero_titulo : 0

    return axios.put(process.env.VUE_APP_API_URL + 'boleto/atualizacao/' + data + '/' + menCodigo + '/' + idTransacaoVenda, {}, { headers: authHeader() })
    // /boleto/atualizacao/${dataPagto}/${menCodigo}/${idTransacaoVenda}
  }

  pagarComCartao ( options ) {
    return axios.post(process.env.VUE_APP_API_URL + 'cobranca/pagamento/new', options, { headers: authHeader() })
  }

  pagarComPix ( options ) {
    return axios.post(process.env.VUE_APP_API_URL + 'pagar/pix', options, { headers: authHeader() })
  }

  //parametros
  nacionalidades () {
    return axios.get(process.env.VUE_APP_API_URL + 'parametro/nacionalidades', { headers: authHeader() })
  }
  orgaosEmissores () {
    return axios.get(process.env.VUE_APP_API_URL + 'parametro/orgaoEmissores', { headers: authHeader() })
  }
  racaCor () {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/get/raca/cor', { headers: authHeader() })
  }

  ufs () {
    return axios.get(process.env.VUE_APP_API_URL + 'ufs', { headers: authHeader() })
  }
  cidades () {
    return axios.get(process.env.VUE_APP_API_URL + 'cidades/0', { headers: authHeader() })
  }
  // nacionalidades () {
  //   return axios.get(process.env.VUE_APP_API_URL + 'parametro/nacionalidades', { headers: authHeader() })
  // }

  getMenuFavoritos (idPessoa) {
    return axios.get(process.env.VUE_APP_API_URL + `get/favoritos/menu/${idPessoa}`, { headers: authHeader() })
  }

  saveRotaFavorita ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + `save/favoritos/menu`, data, { headers: authHeader() })
  }

  getPaineisFavoritos ( idPessoa ) {
    return axios.get(process.env.VUE_APP_API_URL + `get/favoritos/menu/paineis/${idPessoa}`, { headers: authHeader() })
  }

  getFavoritos ( idPessoa ) {
    return axios.get(process.env.VUE_APP_API_URL + `get/favoritos/apollo/pessoa/${idPessoa}`, { headers: authHeader() })
  }

  saveFavorito ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + `save/favoritos/pessoa`, data, { headers: authHeader() })
  }

  removeFavorito ( id ) {
    return axios.delete(process.env.VUE_APP_API_URL + `delete/favoritos/apollo/pessoa/${id}`, { headers: authHeader() })
  }
  
  uploadPicture ( pic ) {
    const data = {
      urlFoto: pic
    }
    return axios.put(process.env.VUE_APP_API_URL + 'auth/perfil', data, { headers: authHeader() })
  }

  getPictureFromStorage ( idUser ) {
    const pp = localStorage.getItem('pp')
    const data = pp ? JSON.parse(pp) : null

    if ( data ) {
      const pic = data.p
      const user = data.u

      if ( user == idUser ) {
        return pic
      } else {
        this.removePicture()
        return null
      }
    } else {
      return data
    }
  }

  storePicture ( user ) {
    const pp = {
      p: user.picture,
      u: user.email
    }

    localStorage.setItem('pp', JSON.stringify(pp))
  }

  removePicture () {
    localStorage.removeItem('pp')
  }
}

export default new UserService()
