<template>
  <controller>
    <page-header title="Planilhas Planejamento de Vendas" />
    <div class="page-inner">
      <planilhas-controller tipo="planejamentoVendas"></planilhas-controller>
    </div>
  </controller>
</template>

<script>
import PlanilhasController from '@/components/planilhas/template/PlanilhasController'
export default {
  components: {
    PlanilhasController
  },
  data () {
    return {

    }
  }
}
</script>

<style>

</style>