<script setup>
  import ModelagemPrecos from '@/components/financeiro/modelagemPrecos/template/ModelagemPrecos.vue'
  import FilterController from '@/components/financeiro/modelagemPrecos/utilities/Filter.vue'
  import FinanceiroService from '@/services/financeiro.service.js'
  import SidebarController from '@/components/planejamentovendas/projecao/template/SidebarFaturamento.vue'
</script>

<template>
  <controller>
    <page-header title="Modelagem de Preços" />
    <section class="pageComponent">
      <div>
        <filter-controller @onFilter="runFilter" :loading="loading"/>
        <modelagem-precos :scope="precos" v-if="precos" />
      </div>
      <div :class="['sidebar', { 'active': sidebar }]">
        <sidebar-controller :options="sidebar" v-if="sidebar"/>
      </div>
    </section>
  </controller>
</template>

<script>

export default {
  components: {
    ModelagemPrecos, FilterController, SidebarController
  },
  data () {
    return {
      scope: null,
      precos: null,
      loading: false,
      sidebar: null
    }
  },
  mounted () {
    // this.parseData()
  },
  methods: {
    runFilter ( data ) {
      const { periodoLetivo, unidade } = data
      this.loading = true
      this.sidebar = {
        ...data,
        type: 'bruto',
      }

      FinanceiroService.getPrecosFace( periodoLetivo, unidade ).then(
        response => {
          console.log("getPrecosFace:", response.data)
          if ( response.data.length > 0 ) {
            this.precos = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos nenhum preço para o período/unidade informado. ", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar os preços para o período/unidade informado.", floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .pageComponent {
    display: flex; align-content: flex-start;
    min-height: calc(100% - 72px);
    
    >div:not(.sidebar) {
      flex: 1
    }
    .sidebar {
      position: sticky; top: 0;
      border-left: $border-component;
      width: 0;
      @extend %transition_4o;

      &.active {
        width: 20%; min-width: 260px; max-width: 320px;
      }
    }
  }
</style>