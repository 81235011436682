import axios from 'axios'
import authHeader from './auth-header'

class AcademicoService {
  getPeriodosLetivos ( idUnidade ) {
    return axios.get(process.env.VUE_APP_API_URL + 'periodosletivos/' + idUnidade, { headers: authHeader() })
  }

  getSeries ( idPeriodoLetivo, idTipoCurso ) {
    idTipoCurso = idTipoCurso ?? 0
    return axios.get(process.env.VUE_APP_API_URL + 'series/0/0/' + idPeriodoLetivo + '/' + idTipoCurso,  { headers: authHeader() })
  }

  getSerie ( idSerie ) {
    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/serie/${ idSerie }`,  { headers: authHeader() })
  }
  
  getTurmas ( idSerie ) {
    return axios.get(process.env.VUE_APP_API_URL + 'turmas' + '/' + idSerie,  { headers: authHeader() })
  }

  filterTurmas ( params ) {
    return axios.get(process.env.VUE_APP_API_URL + 'turmas' + '/' + params.idUnidade + '/' + params.idPeriodoLetivo + '/' + params.idSeries ,  { headers: authHeader() })
  }
  
  saveTurmas ( data, integraGestorClass ) {
    integraGestorClass = integraGestorClass ? integraGestorClass : 0
    return axios.put(process.env.VUE_APP_API_URL + 'proximociclo/edit/turmas/' + integraGestorClass, data, { headers: authHeader() })
  }

  saveTurma ( data, integraGestorClass ) {
    integraGestorClass = integraGestorClass ? integraGestorClass : 0
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/turmas/novas/' + integraGestorClass, [data], { headers: authHeader() })
  }

  getTurnos () {
    return axios.get(process.env.VUE_APP_API_URL + 'turnos',  { headers: authHeader() })
  }

  getTipoCurso ( ) {
    return axios.get(process.env.VUE_APP_API_URL + 'tiposCursos',  { headers: authHeader() })
  }

  getCursos ( ) {
    return axios.get(process.env.VUE_APP_API_URL + 'cursos/0',  { headers: authHeader() })
  }

  getOptativas ( ) {
    return axios.get(process.env.VUE_APP_API_URL + 'turmasoptativas/0',  { headers: authHeader() })
  }

  filtrarMatriculas ( options ) {
    const idUnidade = options.idUnidade ?? 0
    const idTipoCurso = options.idTipoCurso ?? 0
    const idPeriodoLetivo = options.idPeriodoLetivo ?? 0
    const idSerie = options.idSerie ?? 0
    const idTurma = options.idTurma ?? 0
    const validado = options.validado ?? 0
    const tipoNome = options.tipoNome ?? 0
    const nome = options.nome ? options.nome : 0
    const idCurso = options.idCurso ?? 0
    const idTipoSituacao = options.idTipoSituacao ?? 1
    const idAluno = options.idAluno ?? 0

    const s = '/'
    const route = idUnidade + s + idSerie + s + idCurso + s + idTipoCurso + s + idTurma + s + idTipoSituacao + s + idPeriodoLetivo + s + idAluno + s + validado + s + tipoNome + s + nome
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/matriculas/' + route,  { headers: authHeader() })

    // matriculas/{idUnidade?}/{idSerie?}/{idCurso?}/{idTipoCurso?}/{idTurma?}/{idTipoSituacao?}/{idPeriodoLetivo?}/{idAluno?}/{validado?}/{tipoNome?}/{nome?}
  }

  getDocumentosByIdMatricula ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/documento/' + idMatricula,  { headers: authHeader() })
  }

  getDocumentosByIdPessoa ( idPessoa ) {
    console.log("getDocumentosByIdPessoa:", idPessoa)
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/getdocspendentes/' + idPessoa,  { headers: authHeader() })
  }

  uploadDocumento ( file ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/documento', file, { headers: authHeader() })
  }

  updateDocumento ( idMatricula, file ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/documento/' + idMatricula , file, { headers: authHeader() })
  }

  getTurmasProximoCiclo ( options ) {
    const idUnidade = options.idUnidade ? options.idUnidade : 0
    const idTipoCurso = options.idTipoCurso ? options.idTipoCurso : 0
    const idPeriodoLetivo = options.idPeriodoLetivo ? options.idPeriodoLetivo : 0
    const idCurso = options.idCurso ? options.idCurso : 0
    const idSerie = options.idSerie ? options.idSerie : 0

    return axios.get(process.env.VUE_APP_API_URL + 'proximociclo/get/turmas/' + idUnidade + '/' + idTipoCurso + '/' + idPeriodoLetivo + '/' + idCurso + '/' + idSerie,  { headers: authHeader() })
  }

  getLogProgressao ( periodoLetivo ) {
    return axios.get(process.env.VUE_APP_API_URL + 'proximociclo/log/' + periodoLetivo,  { headers: authHeader() })
  }

  progredirPeriodoLetivo ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/periodoletivo', data, { headers: authHeader() })
  }

  progredirSeries ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/series', data, { headers: authHeader() })
  }

  progredirTurmas ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/turmas', data, { headers: authHeader() })
  }

  syncApolloPerseusTurma ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/sync/apollotoperseus/turmas', data, { headers: authHeader() })
  }

  searchTurmas ( idUnidade, idPeriodoLetivo, text ) {
    return axios.get(process.env.VUE_APP_API_URL + 'get/turmas/text/' + idUnidade + '/' + idPeriodoLetivo + '/' + text,  { headers: authHeader() })
  }

  createProximasTurmas ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/progressao/turmas', data,  { headers: authHeader() })
  }

  getGestorSeriesInfo ( perCodigo, sedCodigo ) {
    return axios.post(process.env.VUE_APP_API_URL + `get/combo/series/${ perCodigo }/${ sedCodigo }`, {}, { headers: authHeader() })
  }

  saveSerie ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/series/novas/1', [data], { headers: authHeader() })
  }

  getTodosPeriodosLetivos () {
    return axios.get(process.env.VUE_APP_API_URL + 'get/lista/periodosletivo', { headers: authHeader() })
  }

  getDocumentosRequeridos () {
    return axios.get(process.env.VUE_APP_API_URL + 'proximociclo/get/serie/documentos/requeridos', { headers: authHeader() })
  }

  saveDocumentosRequeridos ( data ) {
    if ( data.iddocumentosrequeridos ) {
      return axios.put(process.env.VUE_APP_API_URL + 'proximociclo/update/documento/requerido', data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/documento/requerido', data, { headers: authHeader() })
    }
  }

  getLocaisPorUnidade ( idUnidade ) {
    return axios.get(process.env.VUE_APP_API_URL + 'turma/get/locais/' + idUnidade, { headers: authHeader() })
  }

  getLocaisSegmentos ( idLocal ) {
    return axios.get(process.env.VUE_APP_API_URL + 'turma/get/local/segmentos/' + idLocal, { headers: authHeader() })
  }

  getLocaisByCurso (idCurso,idUnidade) {
    return axios.get(process.env.VUE_APP_API_URL + `turma/get/local/segmentos/turmas/${idCurso}/${idUnidade}`, { headers: authHeader() })
  }

  saveLocal ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + `turma/save/local/segmentos`, data, { headers: authHeader() })
  }
}

export default new AcademicoService()