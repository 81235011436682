<template>
  <section>
    <section class="matricula-wrap">
      <div class="matricula-sidebar navigation">
        <div>
          <steps :steps="steps" @go="go" :navigate="allowStepNav" :action="action" />
        </div>
      </div>
      <div class="matricula-content" v-if="matricula && activeStep">
        <transition name="fadein">
          <unidade-curso v-if=" activeStep.slug === 'unidade-curso' " :totalSteps="steps.length" :step="activeStep" ref="unidade-curso" :idMatricula="idMatricula" :ready="ready" @ready="isReady('unidade-curso')" :auto="auto" :action="action" />
        </transition>
        <transition name="fadein">
          <aluno v-if=" activeStep.slug === 'aluno' " :totalSteps="steps.length" :step="activeStep" ref="aluno" :idMatricula="idMatricula" :ready="ready" @ready="isReady('aluno')" :auto="auto" :action="action" />
        </transition>
        <transition name="fadein">
          <filiacao v-if=" activeStep.slug === 'filiacao' " :totalSteps="steps.length" :step="activeStep" ref="filiacao" :idMatricula="idMatricula" :ready="ready" @ready="isReady('filiacao')" :auto="auto" :action="action" />
        </transition>
        <transition name="fadein">
          <resp-financeiro v-if=" activeStep.slug === 'responsavel-financeiro' " :totalSteps="steps.length" :step="activeStep" ref="responsavel-financeiro" :idMatricula="idMatricula" :ready="ready" :auto="auto" :action="action" @ready="isReady('responsavel-financeiro')" />
        </transition>
        <transition name="fadein">
          <resp-pedagogico v-if=" activeStep.slug === 'responsavel-pedagogico' " :totalSteps="steps.length" :step="activeStep" ref="responsavel-pedagogico" :idMatricula="idMatricula" :ready="ready" :auto="auto" :action="action" @ready="isReady('responsavel-pedagogico')" />
        </transition>
        <transition name="fadein">
          <ficha-saude v-if=" activeStep.slug === 'ficha-saude' " :totalSteps="steps.length" :step="activeStep" ref="ficha-saude" :idMatricula="idMatricula" :ready="ready" :action="action" @ready="isReady('ficha-saude')" />
        </transition>
        <transition name="fadein">
          <ficha-saida v-if=" activeStep.slug === 'ficha-saida' " :totalSteps="steps.length" :step="activeStep" ref="ficha-saida" :idMatricula="idMatricula" :ready="ready" :action="action" @ready="isReady('ficha-saida')" />
        </transition>
        <transition name="fadein">
          <documentos v-if=" activeStep.slug === 'documentos' " :totalSteps="steps.length" :step="activeStep" ref="documentos" :idMatricula="idMatricula ? idMatricula : matricula.matricula?.idMatricula" :respFin="matricula.respFin.Basicos.idPessoa" :ready="ready" :action="action" @ready="isReady('documentos')" :auto="auto" />
        </transition>
        <transition name="fadein">
          <planos-pagamento v-if=" activeStep.slug === 'planos-pagamento' " :totalSteps="steps.length" :step="activeStep" ref="planos-pagamento" :idMatricula="idMatricula" :ready="ready" :auto="auto" :action="action" @ready="isReady('planos-pagamento')" />
        </transition>
        <transition name="fadein">
          <mensalidades v-if=" activeStep.slug === 'mensalidades' " :totalSteps="steps.length" :step="activeStep" ref="mensalidades" :idMatricula="idMatricula" :ready="ready" :auto="auto" :action="action" @ready="isReady('planos-pagamento')" />
        </transition>
        <transition name="fadein">
          <contrato v-if=" activeStep.slug === 'contrato' " :totalSteps="steps.length" :step="activeStep" ref="contrato" :action="action" :idMatricula="idMatricula" :auto="auto" @block="allowNext = false" @ready="isReady('contrato')" @finished="finalizeAutoMatricula"/>
        </transition>
        <navigation 
          @next="next"
          @prev="prev"
          @finalize="finalizeMatricula()"
          :allowPrev="allowPrev"
          :allowNext="allowNext"
          :activeStep="activeStep"
          :final="final"
          :action="action"
          :auto="auto"
          v-if="ready"
        >
        </navigation>
      </div>
      <div class="matricula-sidebar tips" v-if="this.auto">
        <div>
          <dicas></dicas>
        </div>
      </div>
      <confirm ref="confirm"></confirm>
      <modal ref="modal">
        <div class="wait">
          <input-spinner></input-spinner>
          <h3>Aguarde. Estamos processando sua matrícula</h3>
          <p>Isto pode demorar um pouco. Não feche esta janela até que o processo seja concluído</p>
        </div>
      </modal>
      <upsell-banner v-if="upsell" :options="upsellOptions"/>
    </section>
  </section>
</template>

<script>
import Steps from '@/components/matriculas/navigation/Steps.vue'
import UnidadeCurso from '@/views/matriculas/etapas/UnidadeCurso.vue'
import Aluno from '@/views/matriculas/etapas/Aluno.vue'
import Filiacao from '@/views/matriculas/etapas/Filiacao.vue'
import RespFinanceiro from '@/views/matriculas/etapas/RespFinanceiro.vue'
import RespPedagogico from '@/views/matriculas/etapas/RespPedagogico.vue'
import FichaSaude from '@/views/matriculas/etapas/FichaSaude.vue'
import FichaSaida from '@/views/matriculas/etapas/FichaSaida.vue'
import Documentos from '@/views/matriculas/etapas/Documentos.vue'
import PlanosPagamento from '@/views/matriculas/etapas/PlanosPagamento.vue'
import Mensalidades from '@/views/matriculas/etapas/Mensalidades.vue'
import Contrato from '@/views/matriculas/etapas/Contrato.vue'
import Dicas from '@/components/matriculas/utilities/Dicas.vue'
import Navigation from '@/components/matriculas/navigation/Navigation.vue'
import Confirm from '@/components/utilities/Confirm.vue'
import Modal from '@/components/utilities/Modal.vue'
import InputSpinner from '@/components/utilities/InputSpinner.vue'
import UpsellBanner from '@/components/matriculas/utilities/UpsellBanner.vue'
import MatriculaService from '@/services/matricula.service.js'
import { formatDate, clearMask } from '@/services/utils.service.js'

export default {
  name: 'Matrícula',
  components: {
    Steps, UnidadeCurso, Aluno, Filiacao, RespFinanceiro, RespPedagogico, 
    FichaSaude, FichaSaida, Documentos, PlanosPagamento, Mensalidades, Contrato, 
    Navigation, Confirm, Modal, InputSpinner, Dicas, UpsellBanner
  },
  props: {
    action: {
      type: String
    },
    step: {
      type: String
    },
    idMatricula: {
      type: [String, Number]
    },
    auto: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeStep: null,
      steps: [
        {
          index: 0,
          label: 'Unidade<br>e Curso',
          slug: 'unidade-curso',
          icon: ['fas','school-flag'],
          done: false,
          alert: false,
          active: true,
          locked: false,
          saved: false
        },
        {
          index: 1,
          label: 'Aluno',
          slug: 'aluno',
          icon: ['fas','user-graduate'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 2,
          label: 'Filiação',
          slug: 'filiacao',
          icon: ['fas','family'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 3,
          label: 'Responsável<br>Financeiro',
          slug: 'responsavel-financeiro',
          icon: ['fas','user-tie-hair'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 4,
          label: 'Responsável<br>Pedagógico',
          slug: 'responsavel-pedagogico',
          icon: ['fas','user-vneck-hair'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 5,
          label: 'Ficha de<br>Saúde',
          slug: 'ficha-saude',
          icon: ['fas','book-medical'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 6,
          label: 'Ficha de<br>Saída',
          slug: 'ficha-saida',
          icon: ['fas','person-to-door'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 7,
          label: 'Documentos',
          slug: 'documentos',
          icon: ['fas','folder-open'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 8,
          label: 'Planos de<br>Pagamento',
          slug: 'planos-pagamento',
          icon: ['fas','hand-holding-dollar'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 9,
          label: 'Mensalidades',
          slug: 'mensalidades',
          icon: ['fas','money-check-dollar'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        },
        {
          index: 10,
          label: 'Contrato',
          slug: 'contrato',
          icon: ['fas','file-signature'],
          done: false,
          alert: false,
          active: false,
          locked: false,
          saved: false
        }
      ],
      matricula: null,
      allowPrev: false,
      allowNext: true,
      allowStepNav: true,
      ready: false,
      wait: {
        respFin: false,
        respPed: false
      },
      ini: true,
      final: false,
      nextIndex: 0,
      scapeGet: ['aluno', 'documentos', 'contrato', 'planos-pagamento'],
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      hasUpsell: false,
      upsell: false,
      upsellOptions: {
        idMatriculaUpsell: null,
        idMatricula: null
      }
    }
  },
  mounted () {
    if ( this.step ) {
      const filterSteps = this.steps.filter( k => {
        return k.slug === this.step
      })
      
      var goTo = null

      if ( filterSteps.length === 0 ) {
        goTo = this.steps[0]
      } else {
        goTo = filterSteps[0]
      }

    } else {
      // this.activeStep = this.steps[0]
      goTo = this.steps[0]
    }
    
    // if ( this.action == 'nova' ) {
    //   this.allowStepNav = false
    // } else if ( this.action == 'editar' && this.idMatricula ) {
    //   this.allowStepNav = true
    // }

    if ( goTo.slug == 'contrato' ) {
      this.final = true
    }

    if ( goTo.slug != 'aluno-curso' ) {
      this.steps[0].active = false
    }

    this.activeStep = goTo

    this.start(goTo)
    
    window.pagescroll.emit("unlock")

    const currentRoute = this.$router.currentRoute._value
    const query = currentRoute.query

    if ( query ) {
      // console.log("query:", query)
      this.hasUpsell = true
      this.upsellOptions.idMatriculaUpsell = query.cpm
      this.upsellOptions.idMatricula = this.idMatricula
    }
  },
  methods: {
    start ( to ) {
      this.$store.dispatch('matricula/get').then(response => {
        // console.log("idMatricula:", this.idMatricula)
        this.matricula = response

        this.$nextTick(() => {
          const hasData = this.hasData(response)

          // console.log("hasData:", hasData)
          if ( this.action == 'nova' && hasData ) { 
            this.toggleRecover()
          } else {
            this.$store.dispatch('matricula/clear')
            // console.log('caiu aqui...', this.ready)
            this.ready = true
          }
          
          if ( !this.scapeGet.includes(to.slug) ) {
            // console.log('deu get aqui...')
            this.$refs[to.slug].get()
          }

          if ( this.action == 'editar' ) {
            //
          }

          this.go(to)
        })
        
      })

    },
    go ( step, nav, nopost ) {
      // console.log("go:", step)
      this.ready = false
      const activeIndex = this.activeStep?.index ? this.activeStep.index : 0
      const currentStep = this.steps[activeIndex]
      
      if ( currentStep?.slug == 'unidade-curso' && nav && !nopost ) {
        // console.log("save curso..............................................")
        this.saveCurso(step)
      } else if ( currentStep?.slug == 'aluno' && nav && !nopost ) {
        // console.log("save aluno..............................................")
        this.saveAluno(step)
      } else if ( currentStep?.slug == 'filiacao' && !nopost && nav ) {
        this.saveFiliacao(step)
      } else if ( currentStep?.slug == 'responsavel-pedagogico' && !nopost && nav ) {
        this.nextIndex = this.steps[this.findStepBySlug('ficha-saude').index].index
        this.saveRespPed(step)
      } else if ( currentStep?.slug == 'responsavel-financeiro' && !nopost && nav ) {
        const respPedDone = this.steps[this.findStepBySlug('responsavel-pedagogico').index].done
        this.nextIndex = respPedDone ? this.steps[this.findStepBySlug('ficha-saude').index].index : this.steps[this.findStepBySlug('responsavel-pedagogico').index].index
        this.saveRespFin(step)
      } else if ( currentStep?.slug == 'ficha-saude' && !nopost && nav ) {
        this.nextIndex = this.steps[this.findStepBySlug('ficha-saida').index].index
        this.saveFichaSaude(step)
      } else if ( currentStep?.slug == 'ficha-saida' && !nopost && nav ) {
        this.nextIndex = this.steps[this.findStepBySlug('documentos').index].index
        this.saveFichaSaida(step)
      } else if ( currentStep?.slug == 'planos-pagamento' && !nopost && nav ) {
        this.nextIndex = this.steps[this.findStepBySlug('mensalidades').index].index
        this.savePlanosPagamento(step)
      } else if ( currentStep?.slug == 'mensalidades' && !nopost && nav ) {
        this.nextIndex = this.steps[this.findStepBySlug('contrato').index].index
        this.steps[this.activeStep.index].done = true
        this.steps[this.activeStep.index].alert = false
        this.steps[this.activeStep.index].active = false
        this.nextAfterValidation(step)
      } else {
        if ( this.activeStep && nav && !nopost ) {
          this.steps[this.activeStep.index].done = true
          this.steps[this.activeStep.index].alert = false
          this.steps[this.activeStep.index].active = false
        }
        
        currentStep.active = false
        this.activeStep = step
        if ( !nopost ) {
          this.steps[step.index].active = true
        }

        if ( !this.ini ) {
          this.ini = false

          this.$store.dispatch('matricula/save', this.steps).then(() => {
            // console.log('save matricula:', response)
            if ( !this.scapeGet.includes(step.slug) ) {
              this.$refs[step.slug].get()
            }
            this.addRoute(step)
            this.togglePrevNext()
          })
        } else {
          // console.log("step on else:", step)
          this.addRoute(step)
          this.togglePrevNext()
          this.$nextTick(() => {
            if ( !this.scapeGet.includes(step.slug) ) {
              this.$refs[step.slug].get()
              // console.log(this.$refs[step.slug])
            }
          })
        }
      }
    },
    hasData ( src ) {
      const noData = !src.matricula.idMatricula && !this.idMatricula
      const hasData = !noData

      return hasData
    },
    addRoute ( step ) {
      const current = this.$router.currentRoute._value
      const query = current.query

      if ( this.idMatricula || this.matricula.matricula.idMatricula ) {
        const idMatricula = this.idMatricula ? this.idMatricula : this.matricula.matricula.idMatricula

        if ( query ) {
          this.$router.push({name: current.name, params: { action: this.action, step: step.slug, idMatricula: idMatricula }, query: query})
        } else {
          this.$router.push({name: current.name, params: { action: this.action, step: step.slug, idMatricula: idMatricula }})  
        }
      } else {
        if ( query ) {
          this.$router.push({name: current.name, params: { action: this.action, step: step.slug }, query: query})
        } else {
          this.$router.push({name: current.name, params: { action: this.action, step: step.slug }})
        }
      }
    },
    next ( nopost ) {
      const active = this.activeStep.slug
      const valid = this.$refs[active].validate()
      // console.log("next - valid:", valid)
      
      if ( valid.response ) {
        // this.activeStep.done = true
        // this.activeStep.active = false
        // this.steps[this.activeStep.index].done = true
        const nextIndex = this.activeStep.index + 1
        
        this.$store.dispatch('matricula/save', this.steps).then(() => {
          // console.log('save matricula:', response)

          setTimeout(() => {
            this.go(this.steps[nextIndex], true, nopost)
          }, 500)
        })
      } else {
        this.activeStep.alert = true
      }
    },
    prev () {
      const prevIndex = this.activeStep.index - 1
      this.activeStep = this.steps[prevIndex]

      this.addRoute(this.activeStep)
      this.togglePrevNext()

        this.$nextTick(() => {
          if ( !this.scapeGet.includes(this.activeStep.slug) ) {
            this.$refs[this.activeStep.slug].get()
            // console.log(this.$refs[this.activeStep.slug])
          }
        })
    },
    togglePrevNext ( lock ) {
      if ( lock ) {
        this.allowPrev = false
        this.allowNext = false
      } else {
        this.allowPrev = this.activeStep.index > 0
        this.allowNext = this.activeStep.index < this.steps.length  
      }

    },
    toggleRecover () {
      var idMatricula = this.idMatricula
          idMatricula = !idMatricula ? this.matricula.matricula.idMatricula : idMatricula

      this.$store.dispatch('matricula/recover', idMatricula).then(recover => {
        console.log('recover', recover)
        if ( recover && recover != 'invalid' ) {
          var message = "Notamos que você iniciou o processo de matrícula de"
          if ( recover.matricula.Aluno.Basicos.nome ) {
            message += ":<br><span class='color-text'>" + recover.matricula.Aluno.Basicos.nome + "</span>"
          } else {
            message += " um novo aluno."
          }
          message += "<br>Deseja continuá-lo?"

          // console.log("refs:", this.$refs)

          if ( this.$refs.confirm ) {
            const denyMessage = this.idMatricula ? "Não, reiniciar matrícula " : "Não. Iniciar novo processo."

            this.$refs.confirm.run({
              message: message,
              confirmButton: "Sim",
              denyButton: denyMessage,
              size: 'mid',
              callback: this.recover,
              data: recover
            })
          }
          
          
        } else {
          if ( this.idMatricula ) {
            // console.log("tem id matricula e não tem dados para recover")
            this.$store.dispatch('matricula/clear')
            this.$nextTick(() => {
              this.ready = true
            })
          } else {
            this.$store.dispatch('matricula/clear', this.steps).then(() => {
              // console.log(response)
              const current = this.$router.currentRoute._value
              const query = current.query

              this.$router.push({ name: 'Matriculas', params: { action: 'nova' }, query: query })
            })
          }
          
        }
      })
    },
    recover ( state, recover ) {
      if ( state ) {
        // console.log('recover:', recover)
        this.matricula.Aluno = recover.matricula.Aluno
        this.matricula.Filiacao = recover.matricula.Filiacao
        this.matricula.respFin = recover.matricula.respFin
        this.matricula.respPed = recover.matricula.respPed
        this.matricula.fichaSaude = recover.matricula.fichaSaude
        this.matricula.fichaSaida = recover.matricula.fichaSaida
        this.matricula.planoPag = recover.matricula.planoPag
        this.matricula.matricula = recover.matricula.matricula

        // this.matricula.Aluno.Basicos.dataNascimento = unformatDate(this.matricula.Aluno.Basicos.dataNascimento)
        // this.matricula.Filiacao.mae.Basicos.dataNascimento = unformatDate(this.matricula.Filiacao.mae.Basicos.dataNascimento)
        // this.matricula.Filiacao.pai.Basicos.dataNascimento = unformatDate(this.matricula.Filiacao.pai.Basicos.dataNascimento)
        // this.matricula.respPed.Basicos.dataNascimento = unformatDate(this.matricula.respPed.Basicos.dataNascimento)
        // this.matricula.respFin.Basicos.dataNascimento = unformatDate(this.matricula.respFin.Basicos.dataNascimento)

        this.steps = recover.steps && recover.steps.length > 0 ? recover.steps : this.steps

        // console.log("this.steps:", this.steps)

        const filterSteps = this.steps.filter( k => {
          return k.slug === this.step
        })

        this.$store.dispatch('matricula/save', this.steps).then(() => {
          filterSteps[0].active = true

          setTimeout(() => {
            this.ready = true
          }, 200)
        })
      } else {
        if ( this.idMatricula ) {
          setTimeout(() => {
            this.ready = true
            const name = this.auto ? 'Nova Matrícula Autônoma' : 'Nova Matrícula'
            const current = this.$router.currentRoute._value
            const query = current.query
            this.$router.push({ name: name, params: { action: 'nova', step: 'unidade-curso', idMatricula: this.idMatricula }, query: query })
          },200)
          
        } else {
          this.$store.dispatch('matricula/clear').then(response => {
            if ( response ){
              const current = this.$router.currentRoute._value
              const query = current.query
              this.$router.push({ name: 'Matriculas', params: { action: 'nova' }, query: query })
            }
          })
        }
      }
    },
    saveCurso ( step ) {      
      this.$store.dispatch('matricula/save', this.steps).then(() => {
        this.steps[this.findStepBySlug('unidade-curso').index].saved = true
        this.steps[step.index].saved = true

        window.spinner.emit('close')

        this.nextAfterValidation(step)
      })
    },
    saveAluno ( step ) {
      window.spinner.emit('open')
      // this.matricula.Aluno.Basicos.dataNascimento = unformatDate(this.matricula.Aluno.Basicos.dataNascimento)
      const aluno = {}
      const basicos = {}
      const enderecos = {}
      const documentos = {}
      const matricula = {}

      if ( !this.matricula.Aluno.Documento.rg ) {
        this.matricula.Aluno.Documento.rg = '000000000'
      }
      if ( !this.matricula.Aluno.Documento.rgEmissor ) {
        this.matricula.Aluno.Documento.rgEmissor = '1'
      }

      Object.keys(this.matricula.Aluno.Basicos).map((k,i) => {
        if ( Object.values(this.matricula.Aluno.Basicos)[i] ) {
          basicos[k] = Object.values(this.matricula.Aluno.Basicos)[i]
        }
      })
      Object.keys(this.matricula.Aluno.Documento).map((k,i) => {
        if ( Object.values(this.matricula.Aluno.Documento)[i] ) {
          documentos[k] = Object.values(this.matricula.Aluno.Documento)[i]
        }
      })
      Object.keys(this.matricula.Aluno.Endereco).map((k,i) => {
        if ( Object.values(this.matricula.Aluno.Endereco)[i] ) {
          enderecos[k] = Object.values(this.matricula.Aluno.Endereco)[i]
        }
      })
      Object.keys(this.matricula.Aluno.Aluno).map((k,i) => {
        if ( Object.values(this.matricula.Aluno.Aluno)[i] ) {
          aluno[k] = Object.values(this.matricula.Aluno.Aluno)[i]
        }
      })

      Object.keys(this.matricula.matricula).map((k,i) => {
        if ( Object.values(this.matricula.matricula)[i] ) {  
          matricula[k] = Object.values(this.matricula.matricula)[i]
        }
      })
      
      const data = {
        aluno: {
          Aluno: JSON.parse(JSON.stringify(aluno)),
          Basicos: JSON.parse(JSON.stringify(basicos)),
          Documento: JSON.parse(JSON.stringify(documentos)),
          Endereco: JSON.parse(JSON.stringify(enderecos))
        },
        matricula: JSON.parse(JSON.stringify(matricula)),
        contato: {}
      }
      
      if ( !data.aluno.Basicos.nome ) {
        data.aluno.Basicos.nome = data.aluno.Basicos.nomeDocumento
      }
      // console.log("data raw", data)
      data.aluno.Basicos.telefonecelular = data.aluno.Basicos.telefonecelular ? clearMask('phone', data.aluno.Basicos.telefonecelular) : data.aluno.Basicos.telefonecelular
      data.aluno.Basicos.telefonefixo = data.aluno.Basicos.telefonefixo ? clearMask('phone', data.aluno.Basicos.telefonefixo) : data.aluno.Basicos.telefonefixo
      data.aluno.Basicos.dataNascimento = formatDate(data.aluno.Basicos.dataNascimento)
      data.aluno.Endereco.cep = clearMask('cep', data.aluno.Endereco.cep)

      if ( data.aluno.Documento.cpf ) {
        data.aluno.Documento.cpf = clearMask('cpf', data.aluno.Documento.cpf)
      }
      if ( data.aluno.Documento.rg ) {
        data.aluno.Documento.rg = clearMask('rg', data.aluno.Documento.rg)
      }
      
      delete data.aluno.Endereco.nomeUf
      delete data.aluno.Endereco.uf
      delete data.aluno.Endereco.nomeCidade
      delete data.aluno.Endereco.cidade
      delete data.aluno.Documento.nomeOrgaoEmissor
      delete data.aluno.Documento.orgaoEmissor
      delete data.aluno.Documento.rgDataExpedicao
      delete data.aluno.Basicos.nacionalidade
      delete data.aluno.Basicos.urlFoto
      // console.log("data", data)

      MatriculaService.saveAluno( data, this.idMatricula ).then(
        response => {
          // console.log('saveAluno:', response)

          if ( !this.idMatricula ) {
            const aluno = {}
            const matricula = {}

            Object.keys(this.matricula.Aluno).map((k,i) => {
              if ( Object.values(this.matricula.Aluno)[i] ) {
                aluno[k] = Object.values(this.matricula.Aluno)[i]
              }
            })

            Object.keys(response.data.pessoa).map((k,i) => {
              if ( Object.values(response.data.pessoa)[i] ) {
                aluno[k] = Object.values(response.data.pessoa)[i]
              }
            })

            Object.keys(this.matricula.matricula).map((k,i) => {
              if ( Object.values(this.matricula.matricula)[i] ) {
                matricula[k] = Object.values(this.matricula.matricula)[i]
              }
            })

            Object.keys(response.data.matricula).map((k,i) => {
              if ( Object.values(response.data.matricula)[i] ) {
                matricula[k] = Object.values(response.data.matricula)[i]
              }
            })

            this.matricula.Aluno.Aluno = aluno.Aluno
            this.matricula.matricula = matricula
            this.$emit('dropIdMatricula', matricula.idMatricula)
          }
          
          if ( step.slug != 'aluno' && this.action == 'editar' ) {
            this.integraMatricula(step)
          } else {
            this.nextAfterValidation(step)

            this.$store.dispatch('matricula/save', this.steps).then(() => {
              this.steps[this.findStepBySlug('unidade-curso').index].saved = true
              this.steps[step.index].saved = true

              window.spinner.emit('close')
            })
          }
          
          
        },
        error => {
          // console.error(error)
          window.spinner.emit('close')
          this.catch(error)
          this.ready = true
        }
      )
      
    },
    saveFiliacao ( step ) {
      window.spinner.emit('open')

      var mae = {}
      var pai = {}
      var data = {}
      var basicosMae = {}
      var basicosPai = {}
      var respMae = {}
      var respPai = {}

      Object.keys(this.matricula.Filiacao.mae).map((k,i) => {
        if ( Object.values(this.matricula.Filiacao.mae)[i] ) {
          mae[k] = Object.values(this.matricula.Filiacao.mae)[i]
        }
      })

      Object.keys(mae.Basicos).map((k,i) => {
        if ( Object.values(mae.Basicos)[i] ) {
          basicosMae[k] = Object.values(mae.Basicos)[i]
        }
      })

      Object.keys(mae.Responsavel).map((k,i) => {
        if ( Object.values(mae.Responsavel)[i] ) {
          respMae[k] = Object.values(mae.Responsavel)[i]
        }
      })

      mae.Basicos = basicosMae
      // mae.Responsavel = respMae
      
      if ( !this.matricula.Filiacao.semPai ) {
        Object.keys(this.matricula.Filiacao.pai).map((k,i) => {
          if ( Object.values(this.matricula.Filiacao.pai)[i] ) {
            pai[k] = Object.values(this.matricula.Filiacao.pai)[i]
          }
        })

        Object.keys(pai.Basicos).map((k,i) => {
          if ( Object.values(pai.Basicos)[i] ) {
            basicosPai[k] = Object.values(pai.Basicos)[i]
          }
        })

        Object.keys(pai.Responsavel).map((k,i) => {
          if ( Object.values(pai.Responsavel)[i] ) {
            respPai[k] = Object.values(pai.Responsavel)[i]
          }
        })

        pai.Basicos = basicosPai
        // pai.Responsavel = respPai
      }
      
      data.mae = JSON.parse(JSON.stringify(mae))
      if ( !this.matricula.Filiacao.semPai ) data.pai = JSON.parse(JSON.stringify(pai))
      
      // console.log('data:', data)
      if ( !data.mae.Basicos.nome ) {
        data.mae.Basicos.nome = data.mae.Basicos.nomeDocumento
      }
      data.mae.Basicos.telefonecelular = data.mae.Basicos.telefonecelular ? clearMask('phone', data.mae.Basicos.telefonecelular) : data.mae.Basicos.telefonecelular
      data.mae.Basicos.telefonefixo = data.mae.Basicos.telefonefixo ? clearMask('phone', data.mae.Basicos.telefonefixo) : data.mae.Basicos.telefonefixo
      data.mae.Basicos.dataNascimento = formatDate(data.mae.Basicos.dataNascimento)
      data.mae.Endereco.cep = clearMask('cep', data.mae.Endereco.cep)
      data.mae.Responsavel.financeiro = data.mae.Responsavel.financeiro ? 1 : 0
      data.mae.Responsavel.pedagogico = data.mae.Responsavel.pedagogico ? 1 : 0
      data.mae.Documento.cpf = clearMask('cpf', data.mae.Documento.cpf)
      data.mae.Basicos.email = data.mae.Basicos.email.trim()

      delete data.mae.Endereco.nomeUf
      delete data.mae.Endereco.uf
      delete data.mae.Endereco.nomeCidade
      delete data.mae.Endereco.cidade
      delete data.mae.Documento.nomeOrgaoEmissor
      delete data.mae.Documento.orgaoEmissor
      delete data.mae.Basicos.nacionalidade

      if ( !this.matricula.Filiacao.semPai ) {
        if ( !data.pai.Basicos.nome ) {
          data.pai.Basicos.nome = data.pai.Basicos.nomeDocumento
        }
        data.pai.Basicos.telefonecelular = data.pai.Basicos.telefonecelular ? clearMask('phone', data.pai.Basicos.telefonecelular) : data.pai.Basicos.telefonecelular
        data.pai.Basicos.telefonefixo = data.pai.Basicos.telefonefixo ? clearMask('phone', data.pai.Basicos.telefonefixo) : data.pai.Basicos.telefonefixo
        data.pai.Basicos.dataNascimento = formatDate(data.pai.Basicos.dataNascimento)
        data.pai.Endereco.cep = clearMask('cep', data.pai.Endereco.cep)
        data.pai.Responsavel.financeiro = data.pai.Responsavel.financeiro ? 1 : 0
        data.pai.Responsavel.pedagogico = data.pai.Responsavel.pedagogico ? 1 : 0
        data.pai.Documento.cpf = clearMask('cpf', data.pai.Documento.cpf)
        data.pai.Basicos.email = data.pai.Basicos.email.trim()

        delete data.pai.Endereco.nomeUf
        delete data.pai.Endereco.uf
        delete data.pai.Endereco.nomeCidade
        delete data.pai.Endereco.cidade
        delete data.pai.Documento.nomeOrgaoEmissor
        delete data.pai.Documento.orgaoEmissor
        delete data.pai.Basicos.nacionalidade
      }
      
      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula

      MatriculaService.saveFiliacao( idMatricula, data ).then(
        response => {
          // console.log('saveFiliacao:', response)

          if ( response.data.mae ) {
            this.matricula.Filiacao.mae.Endereco.idPessoa = response.data.mae.Endereco.idPessoa
            this.matricula.Filiacao.mae.Documento.idPessoa = response.data.mae.Documento.idPessoa
            // this.matricula.Filiacao.mae.Endereco.idPessoa = response.data.mae.Endereco.idPessoa
          }
          // const respMae = JSON.parse(JSON.stringify(this.matricula.Filiacao.mae.Responsavel))
          // this.matricula.Filiacao.mae = response.data.mae
          // this.matricula.Filiacao.mae.Basicos.dataNascimento = formatDate(this.matricula.Filiacao.mae.Basicos.dataNascimento)
          this.matricula.Filiacao.mae.Responsavel = respMae

          if ( !this.matricula.Filiacao.semPai && response.data.pai ) {
            // const respPai = JSON.parse(JSON.stringify(this.matricula.Filiacao.pai.Responsavel))
            // this.matricula.Filiacao.pai = response.data.pai
            // this.matricula.Filiacao.pai.Basicos.dataNascimento = formatDate(this.matricula.Filiacao.pai.Basicos.dataNascimento)
            this.matricula.Filiacao.pai.Responsavel = respPai
            this.matricula.Filiacao.pai.Endereco.idPessoa = response.data.pai.Endereco.idPessoa
            this.matricula.Filiacao.pai.Documento.idPessoa = response.data.pai.Documento.idPessoa
          }

          this.steps[this.findStepBySlug('responsavel-financeiro').index].locked = false
          this.steps[this.findStepBySlug('responsavel-pedagogico').index].locked = false
          this.steps[this.findStepBySlug('responsavel-financeiro').index].done = false
          this.steps[this.findStepBySlug('responsavel-pedagogico').index].done = false

          this.nextIndex = this.findStepBySlug('responsavel-financeiro').index

          if ( !this.auto ) {
            if ( mae.Responsavel.financeiro || pai?.Responsavel?.financeiro ) {
              this.nextIndex = this.findStepBySlug('responsavel-pedagogico').index
              this.steps[this.findStepBySlug('responsavel-financeiro').index].locked = true
              this.steps[this.findStepBySlug('responsavel-financeiro').index].done = true

              if ( mae.Responsavel.financeiro ) {
                // console.log("------------------------------------------------ mae is respFin:", response.data.mae)
                this.matricula.respFin = this.matricula.Filiacao.mae ? this.matricula.Filiacao.mae : response.data.mae
              } else if ( pai?.Responsavel?.financeiro ) {
                // console.log("------------------------------------------------ pai is respFin:", this.matricula.Filiacao.pai)
                this.matricula.respFin = this.matricula.Filiacao.pai ? this.matricula.Filiacao.pai : response.data.pai
              }

              this.saveRespFin()
              // noRespSave = false
            }
            if ( mae.Responsavel.pedagogico || pai?.Responsavel?.pedagogico ) {
              if ( mae.Responsavel.financeiro || pai?.Responsavel?.financeiro ) {
                this.nextIndex = this.findStepBySlug('ficha-saude').index
              }
              
              this.steps[this.findStepBySlug('responsavel-pedagogico').index].locked = true
              this.steps[this.findStepBySlug('responsavel-pedagogico').index].done = true

              if ( mae.Responsavel.pedagogico ) {
                this.matricula.respPed = this.matricula.Filiacao.mae ? this.matricula.Filiacao.mae : response.data.mae
              } else if ( pai?.Responsavel?.pedagogico ) {
                this.matricula.respPed = this.matricula.Filiacao.pai ? this.matricula.Filiacao.pai : response.data.pai
              }

              // console.log("saveRespPed.. next step = ", this.steps[nextIndex])
              this.saveRespPed()
              // noRespSave = false
            }
            
            if ( !mae.Responsavel.financeiro && !pai?.Responsavel?.financeiro ) {
              this.wait.respFin = true
            } 
            if ( !mae.Responsavel.pedagogico && !pai?.Responsavel?.pedagogico ) {
              this.wait.respPed = true
            }
          }
          
          
          // if ( !mae.Responsavel.financeiro && !pai?.Responsavel?.financeiro && !mae.Responsavel.pedagogico && !pai?.Responsavel?.pedagogico || this.auto ) {
            
          // }

          step = this.steps[this.nextIndex]
          this.nextAfterValidation(step)

          this.$store.dispatch('matricula/save', this.steps).then(() => {
            this.steps[this.findStepBySlug('filiacao').index].saved = true
            window.spinner.emit('close')
          })
        },
        error => {
          // console.error(error)
          window.spinner.emit('close')
          // const msg = error.response.data.error
          // window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos salvar a filiação com os dados enviados: <b>" + msg + "</b>", floater: true})
          this.catch(error)
          this.ready = true
        }
      )
    },
    saveRespFin () {
      window.spinner.emit('open')

      // console.log("saveRespFin - step:",step)
      var data = {}
      var Basicos = {}
      var Documento = {}
      var Endereco = {}
      var Matricula = {}
      var Responsavel = {}

      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula
      
      Object.keys(this.matricula.respFin.Basicos).map((k,i) => {
        if ( Object.values(this.matricula.respFin.Basicos)[i] ) {
          Basicos[k] = Object.values(this.matricula.respFin.Basicos)[i]
        }
      })

      Object.keys(this.matricula.respFin.Documento).map((k,i) => {
        if ( Object.values(this.matricula.respFin.Documento)[i] ) {
          Documento[k] = Object.values(this.matricula.respFin.Documento)[i]
        }
      })

      Object.keys(this.matricula.respFin.Endereco).map((k,i) => {
        if ( Object.values(this.matricula.respFin.Endereco)[i] ) {
          Endereco[k] = Object.values(this.matricula.respFin.Endereco)[i]
        }
      })

      Object.keys(this.matricula.matricula).map((k,i) => {
        if ( Object.values(this.matricula.matricula)[i] ) {
          Matricula[k] = Object.values(this.matricula.matricula)[i]
        }
      })

      Object.keys(this.matricula.respFin.Responsavel).map((k,i) => {
        if ( Object.values(this.matricula.respFin.Responsavel)[i] ) {
          Responsavel[k] = Object.values(this.matricula.respFin.Responsavel)[i]
        }
      })

      data = {
        Basicos: JSON.parse(JSON.stringify(Basicos)),
        Documento: JSON.parse(JSON.stringify(Documento)),
        Endereco: JSON.parse(JSON.stringify(Endereco)),
        Matricula: JSON.parse(JSON.stringify(Matricula)),
        Responsavel: JSON.parse(JSON.stringify(Responsavel))
      }
      
      console.log("data - saveRespFin:", data)

      if ( !data.Basicos.nome ) {
        data.Basicos.nome = data.Basicos.nomeDocumento
      }

      data.Basicos.telefonecelular = data.Basicos.telefonecelular ? clearMask('phone', data.Basicos.telefonecelular) : data.Basicos.telefonecelular
      data.Basicos.telefonefixo = data.Basicos.telefonefixo ? clearMask('phone', data.Basicos.telefonefixo) : data.Basicos.telefonefixo

      const isDateFormat = data.Basicos.dataNascimento.includes('-')

      data.Basicos.dataNascimento = isDateFormat ? formatDate(data.Basicos.dataNascimento) : data.Basicos.dataNascimento
      data.Endereco.cep = clearMask('cep', data.Endereco.cep)
      data.Responsavel.financeiro = data.Responsavel.financeiro ? 1 : 0
      data.Responsavel.pedagogico = data.Responsavel.pedagogico ? 1 : 0
      data.Documento.cpf = clearMask('cpf', data.Documento.cpf)
      data.Basicos.email = data.Basicos.email.trim()
      data.Basicos.tipo = data.Documento.cpf.length > 11 ? 2 : 1

      delete data.Endereco.nomeUf
      delete data.Endereco.uf
      delete data.Endereco.nomeCidade
      delete data.Endereco.cidade
      delete data.Documento.nomeOrgaoEmissor
      delete data.Documento.orgaoEmissor
      delete data.Documento.rgDataExpedicao
      delete data.Basicos.nacionalidade

      MatriculaService.saveRespFin( idMatricula, data ).then(
        () => {
          // console.log("response:",response)
          this.wait.respFin = true

          if ( this.action == 'editar' ) {
            window.spinner.emit("close")
            // this.integraResponsavel(step,'financeiro')
            this.$store.dispatch('matricula/save', this.steps).then(() => {
              
              this.steps[this.findStepBySlug('responsavel-financeiro').index].saved = true
              this.stopSpinner()

              this.nextAfterValidation()
            })
          } else {
            this.nextAfterValidation()

            this.$store.dispatch('matricula/save', this.steps).then(() => {
              this.steps[this.findStepBySlug('responsavel-financeiro').index].saved = true
              this.stopSpinner()
            })
          }
          
        },
        error => {
          // console.error(error)
          this.catch(error)
          this.ready = true
          window.spinner.emit('close')
        }
      )
    },
    saveRespPed ( step ) {
      window.spinner.emit('open')
      // console.log("saveRespPed - step:",step)
      var data = {}
      var Basicos = {}
      var Documento = {}
      var Endereco = {}
      var Matricula = {}
      var Responsavel = {}

      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula
      
      Object.keys(this.matricula.respPed.Basicos).map((k,i) => {
        if ( Object.values(this.matricula.respPed.Basicos)[i] ) {
          Basicos[k] = Object.values(this.matricula.respPed.Basicos)[i]
        }
      })

      Object.keys(this.matricula.respPed.Documento).map((k,i) => {
        if ( Object.values(this.matricula.respPed.Documento)[i] ) {
          Documento[k] = Object.values(this.matricula.respPed.Documento)[i]
        }
      })

      Object.keys(this.matricula.respPed.Endereco).map((k,i) => {
        if ( Object.values(this.matricula.respPed.Endereco)[i] ) {
          Endereco[k] = Object.values(this.matricula.respPed.Endereco)[i]
        }
      })

      Object.keys(this.matricula.matricula).map((k,i) => {
        if ( Object.values(this.matricula.matricula)[i] ) {
          Matricula[k] = Object.values(this.matricula.matricula)[i]
        }
      })

      Object.keys(this.matricula.respPed.Responsavel).map((k,i) => {
        if ( Object.values(this.matricula.respPed.Responsavel)[i] ) {
          Responsavel[k] = Object.values(this.matricula.respPed.Responsavel)[i]
        }
      })

      data = {
        Basicos: JSON.parse(JSON.stringify(Basicos)),
        Documento: JSON.parse(JSON.stringify(Documento)),
        Endereco: JSON.parse(JSON.stringify(Endereco)),
        Matricula: JSON.parse(JSON.stringify(Matricula)),
        Responsavel: JSON.parse(JSON.stringify(Responsavel))
      }

      console.log("data - saveRespPed:", data)

      if ( !data.Basicos.nome ) {
        data.Basicos.nome = data.Basicos.nomeDocumento
      }
      data.Basicos.telefonecelular = data.Basicos.telefonecelular ? clearMask('phone', data.Basicos.telefonecelular) : data.Basicos.telefonecelular
      data.Basicos.telefonefixo = data.Basicos.telefonefixo ? clearMask('phone', data.Basicos.telefonefixo) : data.Basicos.telefonefixo
      const isDateFormat = data.Basicos.dataNascimento.includes('-')

      data.Basicos.dataNascimento = isDateFormat ? formatDate(data.Basicos.dataNascimento) : data.Basicos.dataNascimento
      // data.Basicos.dataNascimento = unformatDate(data.Basicos.dataNascimento)
      data.Endereco.cep = clearMask('cep', data.Endereco.cep)
      data.Responsavel.financeiro = data.Responsavel.financeiro ? 1 : 0
      data.Responsavel.pedagogico = data.Responsavel.pedagogico ? 1 : 0
      data.Documento.cpf = clearMask('cpf', data.Documento.cpf)
      data.Basicos.email = data.Basicos.email.trim()
      data.Basicos.tipo = 1

      delete data.Endereco.nomeUf
      delete data.Endereco.uf
      delete data.Endereco.nomeCidade
      delete data.Endereco.cidade
      delete data.Documento.nomeOrgaoEmissor
      delete data.Documento.orgaoEmissor
      delete data.Documento.rgDataExpedicao
      delete data.Basicos.nacionalidade
      
      MatriculaService.saveRespPed( idMatricula, data ).then(
        () => {
          this.wait.respPed = true

          if ( this.action == 'editar' ) { 
            this.integraResponsavel(step,'pedagogico')
          } else {
            this.nextAfterValidation()

            this.$store.dispatch('matricula/save', this.steps).then(() => {
              this.steps[this.findStepBySlug('responsavel-pedagogico').index].saved = true
            })
          }
        },
        error => {
          this.catch(error)
          this.ready = true
          window.spinner.emit('close')
        }
      )
    },
    saveFichaSaude ( step ) {
      // console.log("saveRespPed - step:",step)
      var data = {}
      var saude = {}

      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula
      
      Object.keys(this.matricula.fichaSaude).map((k,i) => {
        if ( Object.values(this.matricula.fichaSaude)[i] ) {
          saude[k] = Object.values(this.matricula.fichaSaude)[i]
        }
      })

      data = { fichaSaude: JSON.parse(JSON.stringify(saude)) }

      data.fichaSaude.possuiPlanoSaude = data.fichaSaude.possuiPlanoSaude ? data.fichaSaude.possuiPlanoSaude : 0
      data.fichaSaude.possuiNecessidadeEspecial = data.fichaSaude.possuiNecessidadeEspecial ? data.fichaSaude.possuiNecessidadeEspecial : 0
      data.fichaSaude.nomeNecessidadeEspecial = data.fichaSaude.nomeNecessidadeEspecial ? data.fichaSaude.nomeNecessidadeEspecial : ""

      if ( idMatricula ) {
        data.fichaSaude['idMatricula'] = idMatricula
      }

      MatriculaService.saveFichaSaude( idMatricula, data ).then(
        () => {
          // console.log("saveFichaSaude:",response)
          this.wait.respPed = true
          // this.matricula.fichaSaude = response
          // this.nextAfterValidation(step)
          step = this.steps[this.nextIndex]
          this.nextAfterValidation(step)

          this.$store.dispatch('matricula/save', this.steps).then(() => {
            // console.log('save matricula:', response)
            this.steps[this.findStepBySlug('ficha-saude').index].saved = true
            // this.finishSave(step)
          })
        },
        error => {
          this.catch(error)
          this.ready = true
        }
      )
      // console.log("step:", step)
    },
    saveFichaSaida ( step ) {
      // console.log("saveRespPed - step:",step)
      var autorizacao = {}
      var transporte = {}
      var valeTransporte = {}
      var retirada = []
      
      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula
      
      Object.keys(this.matricula.fichaSaida.autorizacaoSaida).map((k,i) => {
        if ( Object.values(this.matricula.fichaSaida.autorizacaoSaida)[i] ) {
          autorizacao[k] = Object.values(this.matricula.fichaSaida.autorizacaoSaida)[i]
        }
      })

      Object.keys(this.matricula.fichaSaida.transporteEscolar).map((k,i) => {
        if ( Object.values(this.matricula.fichaSaida.transporteEscolar)[i] ) {
          transporte[k] = Object.values(this.matricula.fichaSaida.transporteEscolar)[i]
        }
      })

      Object.keys(this.matricula.fichaSaida.valeTransporte).map((k,i) => {
        if ( Object.values(this.matricula.fichaSaida.valeTransporte)[i] ) {
          valeTransporte[k] = Object.values(this.matricula.fichaSaida.valeTransporte)[i]
        } else {
          valeTransporte[k] = 0
        }
      })

      if ( this.matricula.fichaSaida.autorizacaoRetirarAluno.length > 0 ) {
        const autorizacaoSaida = this.matricula.fichaSaida.autorizacaoSaida.idAutorizacaoSaida

        this.matricula.fichaSaida.autorizacaoRetirarAluno.map( k => {
          var item = {}

          Object.keys(k).map((ka,i) => {
            item['idAutorizacaoSaida'] = autorizacaoSaida
            var drop
            if ( ka == 'cpf' ) {
              drop = Object.values(k)[i] ? clearMask('cpf', Object.values(k)[i]) : Object.values(k)[i]
            } else if ( ka == 'telefoneCelular' || ka == 'telefoneResidencial' ) {
              drop = Object.values(k)[i] ? clearMask('phonecc', Object.values(k)[i]) : Object.values(k)[i]
            } else {
              drop = Object.values(k)[i]
            }
            item[ka] = drop
          })
          retirada.push(item)
        })
      }
      
      
      const data = {
        autorizacaoSaida: JSON.parse(JSON.stringify(autorizacao)),
        autorizacaoRetirarAluno: JSON.parse(JSON.stringify(retirada)),
        transporteEscolar: JSON.parse(JSON.stringify(transporte)),
        valeTransporte: JSON.parse(JSON.stringify(valeTransporte))
      }
      
      data.autorizacaoSaida.idMatricula = idMatricula
      data.valeTransporte.idMatricula = idMatricula
      
      if ( data.transporteEscolar.telefoneCondutor ) {
        data.transporteEscolar.telefoneCondutor = clearMask('phone', data.transporteEscolar.telefoneCondutor)
      }

      data.transporteEscolar.usaTransporteEscolar = data.autorizacaoSaida.idTipoAutorizacaoSaida == 4  ? 1 : 0

      delete data.valeTransporte.idValeTransporte
      delete data.autorizacaoSaida.corAutorizacao
      delete data.autorizacaoSaida.corHexa
      delete data.autorizacaoSaida.descricao

      MatriculaService.saveFichaSaida( idMatricula, data ).then(
        () => {
          
          this.wait.respPed = true
          
          step = this.steps[this.nextIndex]
          this.nextAfterValidation(step)

          this.$store.dispatch('matricula/save', this.steps).then(() => {
            // console.log('save matricula:', response)
            this.steps[this.findStepBySlug('ficha-saida').index].saved = true
            // this.finishSave(step)
          })
        },
        error => {
          this.catch(error)
          this.ready = true

          const data = error.response.data
          var msg = data.error

          if ( !msg ) {
            msg = Object.values(data).join(", ")
          } 
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos salvar os dados enviados: <b>" + msg + "</b>", floater: true})
        }
      )
      // console.log("step:", step)
    },
    savePlanosPagamento ( step ) {
      window.spinner.emit('open')

      var data = {}
      var Plano = {}

      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula
      
      Object.keys(this.matricula.planoPag).map((k,i) => {
        if ( Object.values(this.matricula.planoPag)[i] ) {
          Plano[k] = Object.values(this.matricula.planoPag)[i]
        }
      })

      data = JSON.parse(JSON.stringify(Plano))

      MatriculaService.savePlanosPagamento( idMatricula, data ).then(
        () => {
          
          this.wait.respPed = true
          this.steps[this.findStepBySlug('planos-pagamento').index].done = true
          
          step = this.steps[this.nextIndex]
          this.nextAfterValidation(step)

          this.$store.dispatch('matricula/save', this.steps).then(() => {
            // console.log('save matricula:', response)
            this.steps[this.findStepBySlug('planos-pagamento').index].saved = true
          })
        },
        error => {
          this.catch(error)
          this.ready = true
          window.spinner.emit('close')
        }
      )
      // console.log("step:", step)
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      // console.log("done:", done)
      if ( done ) {
        window.spinner.emit("close")
      }
    },
    findStepBySlug ( slug ) {
      const step = this.steps.filter( k => {
        return k.slug == slug
      })[0]

      return step
    },
    nextAfterValidation ( step ) {
      step = step ? step : this.steps[this.nextIndex]

      if ( step ) {
        this.activeStep.done = true
        this.activeStep.alert = false
        this.activeStep.active = false
        this.steps[this.activeStep.index].done = true

        const activeIndex = this.activeStep?.index ? this.activeStep.index : 0
        const currentStep = this.steps[activeIndex]
        
        currentStep.active = false
        this.activeStep = step
        step.active = true

        this.addRoute(step)
        this.togglePrevNext()

        this.$nextTick(() => {
          if ( !this.scapeGet.includes(step.slug) ) {
            this.$refs[step.slug].get()
          }
        })
      }
    },
    finishSave ( step ) {
      const fichaSaidaSaved = this.steps[this.findStepBySlug('ficha-saida').index].saved
      const fichaSaudeSaved = this.steps[this.findStepBySlug('ficha-saida').index].saved

      if ( fichaSaidaSaved && fichaSaudeSaved ) {
        this.nextAfterValidation(step)
        this.final = true
        window.spinner.emit('close')
      }
    },
    finalizeAutoMatricula ( state ) {
      const message = state ? "Matrícula concluída com sucesso." : "Ops. Algo aconteceu!"
      const obs = state ? "Tudo certo com a assinatura do contrato" : "Houve um problema com o contrato."

      if ( state ) {
        this.$store.dispatch('matricula/save', this.steps).then(response => {
          if ( this.hasUpsell && this.auto ) {
            this.upsell = true
          } else {
            const confirmMessage = this.auto ? "Quero ir para o Shop Decisão" : "Ir para a central do aluno"
            const denyMessage = this.auto ? "Voltar para o App de Rematrículas" : "Não. Iniciar novo processo"

            this.$refs.confirm.run({
              message: message,
              messageAlign: 'center',
              obs: obs,
              confirmButton: confirmMessage,
              denyButton: denyMessage,
              size: 'mid',
              callback: this.endSession,
              data: response
            })
          }
        })
        
      } else {
        if ( this.auto ) {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não houve um problema com o contrato. Procure nosso time de Relacionamento com as famílias.", floater: true})
        } else {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não houve um problema com o contrato.", floater: true})
        }
        
      }
      
    },
    finalizeMatricula () {
      // window.spinner.emit('open')
      
      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula
      const termoAceite = this.$refs.contrato.finalize()
      
      const data = {
        idMatricula: idMatricula,
        portal: "portalGestao"
      }

      if ( termoAceite ) {
        const options = {
          title: 'Gravando Matrícula',
          titleAlign: "center",
          // message: "Se quiser uma mensagem...", //não obrigatório
          size: "small", //small, mid, large, full
          backdrop: true,
          backdropColor: "transparent",  //se o backdrop for false, não precisa
          backdropClose: false, //se o backdrop for false, não precisa
          fullHeight: false
        }
        
        this.$refs.modal.open(options)

        MatriculaService.saveContrato( data ).then(
          () => {
            // window.spinner.emit('close')
            this.$refs.modal.close()
            this.steps[this.findStepBySlug('contrato').index].done = true

            // this.nextAfterValidation(step)
            
            this.$store.dispatch('matricula/save', this.steps).then(response => {
              
              const confirmMessage = this.auto ? "Quero ir para o Shop Decisão" : "Ir para a central do aluno"
              const denyMessage = this.auto ? "Voltar para o App de Rematrículas" : "Não. Iniciar novo processo"

              this.$refs.confirm.run({
                message: "Matrícula concluída com sucesso.",
                obs: "<b class='color-primary'>Atenção!</b> Seu boleto estará disponível em até 48h.",
                confirmButton: confirmMessage,
                denyButton: denyMessage,
                size: 'mid',
                callback: this.endSession,
                data: response
              })
            })
          },
          error => {
            this.catch(error)
            const data = error.response.data
            var msg = data.error

            if ( !msg ) {
              msg = Object.values(data).join(", ")
            }

            this.$refs.modal.close()

            this.$nextTick(() => {
              window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos salvar os dados enviados: <b>" + msg + "</b>", floater: true})
            })
          }
        )
      } else {
        this.$refs.confirm.run({
          message: "Para finalizar esta matrícula você precisa aceitar os termos.",
          confirmButton: "Ok!",
          // denyButton: "Não. Iniciar novo processo.",
          size: 'small',
          callback: this.restartAceite,
          // data: recover
        })
      }
    },
    restartAceite () {
      window.spinner.emit('close')
    },
    integraMatricula ( step ) {
      const idMatricula = this.matricula.matricula.idMatricula

      MatriculaService.integraMatricula( idMatricula ).then(
        () => {
          this.nextAfterValidation(step)
          
          window.toaster.emit('open', {style: "success", message: "Matrícula integrada com sucesso!", floater: true})
          this.$store.dispatch('matricula/save', this.steps).then(() => {
          
            this.steps[this.findStepBySlug('unidade-curso').index].saved = true
            this.steps[step.index].saved = true

            window.spinner.emit('close')
          })
        },
        error => {
          this.catch(error)
          window.spinner.emit('close')
        }
      )
    },
    integraFiliacao ( step ) {
      const idMatricula = this.matricula.matricula.idMatricula

      MatriculaService.integraFiliacao( idMatricula ).then(
        () => {
          // console.log("integraFiliacao:", response.data)
          this.nextAfterValidation(step)
          
          window.toaster.emit('open', {style: "success", message: "Matrícula integrada com sucesso!", floater: true})
          this.$store.dispatch('matricula/save', this.steps).then(() => {
            this.steps[this.findStepBySlug('filiacao').index].saved = true
            this.steps[step.index].saved = true

            window.spinner.emit('close')
          })
        },
        error => {
          this.catch(error)
          window.spinner.emit('close')
        }
      )
    },
    integraResponsavel ( step, tipo ) {
      const idMatricula = this.matricula.matricula.idMatricula ? this.matricula.matricula.idMatricula : this.idMatricula

      MatriculaService.integraResponsavel( idMatricula, tipo ).then(
        () => {
          // console.log("integraResponsavel:", response.data)
          this.nextAfterValidation(step)
          
          window.toaster.emit('open', {style: "success", message: "Matrícula integrada com sucesso!", floater: true})
          this.$store.dispatch('matricula/save', this.steps).then(() => {
            
            const drop = 'responsavel-' + tipo
            this.steps[this.findStepBySlug(drop).index].saved = true
            this.steps[step.index].saved = true

            this.stopSpinner()
          })
        },
        error => {
          this.catch(error)
          window.spinner.emit('close')
        }
      )
    },
    endSession ( state, matricula ) {
      this.$store.dispatch('matricula/clear', this.steps).then(() => {

        if ( state ) {
          if ( this.auto ) {
            window.location.href = "https://lojadecisao.com.br/loja/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjY4ODAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODAwMC9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTU2MzQ3NzY4NywiZXhwIjoxNTYzNDgxMjg3LCJuYmYiOjE1NjM0Nzc2ODcsImp0aSI6ImRtRjZsYUMwWUswVTdzSVIifQ.ynkU2CQ2Kr3yt-cxA3o21y2wkg2HRj-zckdBYDy2MNE&email=" + this.currentUser.email
          } else {
            const idPessoa = matricula.idPessoa
            const idAluno = matricula.idAluno
            
            this.$router.push({ name: 'Central do Aluno', params: { idAluno: idAluno, idPessoa: idPessoa } })
          }
          
        } else {
          if ( this.auto ) {
            this.$router.push({ name: 'App Rematrícula'})
          } else {
            this.$router.push({ name: 'Matriculas', params: { action: 'nova' } })
          }
          
        }
      })
    },
    isReady ( target ) {
      const step = this.findStepBySlug(target)
      
      this.steps[step.index].active = true
      this.ready = true

      this.$store.dispatch('matricula/save').then(() => {
        this.togglePrevNext()
      })
    },
    catch ( error ) {
      const data = error.response.data
      var msg = data.error

      if ( !msg ) {
        msg = Object.values(data).join(", ")
      } 

      window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos salvar os dados enviados: <b>" + msg + "</b>", floater: true})
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-inner {
    @media screen and ( max-width: 640px) {
      padding: $hmg 0 !important;
    }
  }
  .matricula-wrap {
    display: flex; align-items: stretch; align-content: flex-start; width: 100%; height: auto;
    padding: $mg $hmg_mid $mg_extra $hmg_mid;

    @media screen and ( max-width: 640px) {
      flex-direction: column; padding-top: $hmg;
    }

    .matricula-sidebar {
      width: 100%; flex: 0;
      
      @media screen and ( max-width: 640px) {
        position: relative; overflow-x: auto;
      }

      &.navigation {
        max-width: 220px; min-width: 190px;
        
        > div {
          position: -webkit-sticky;
          position: sticky; top: $hmg;
          // min-height: 300px; background-color: #ff9900;
        }

        @media screen and ( max-width: 640px) {
          width: 100%; max-width: 100%;
        }
      }
      &.tips {
        max-width: 270px; min-width: 240px;
        @extend %transition_4e;

        > div {
          position: -webkit-sticky;
          position: sticky; top: $hmg;
          // min-height: 400px; background-color: #ff0099;
        }
      }

      @media screen and ( max-width: 640px) {
        &.tips {
          // display: none;
          position: fixed; top: 0; right: 0; 
          transform: translateX(110%);
          
        }
      }
    }

    .matricula-content {
      flex: 1; 
      padding: 0 $mg;

      @media screen and ( max-width: 640px) {
         padding: $mg $hmg 0;
      }
      // background-color: #efefef; min-height: 2000px;
    }
  }
  
  .wait {
    text-align: center;

    .input-spinner {
      margin: 0 auto $hmg
    }
  }
</style>