<script setup>
  // import ModelagemPrecos from '@/components/financeiro/modelagemPrecos/template/ModelagemPrecos.vue'
  // import FilterController from '@/components/financeiro/modelagemPrecos/utilities/Filter.vue'
  import FinanceiroService from '@/services/financeiro.service.js'
</script>

<template>
  <controller>
    <page-header title="Modelagem de Reajustes" />
    <section class="pageComponent">
      <div>
        <!-- <filter-controller @onFilter="runFilter" :loading="loading"/>
        <modelagem-precos :scope="precos" v-if="precos" /> -->
      </div>
    </section>
  </controller>
</template>

<script>

export default {
  components: {
    // ModelagemPrecos, FilterController
  },
  data () {
    return {
      scope: null,
      precos: null,
      loading: false
    }
  },
  mounted () {
    // this.parseData()
  },
  methods: {
    runFilter ( data ) {
      const { periodoLetivo, unidade } = data
      this.loading = true
      FinanceiroService.getPrecosFace( periodoLetivo, unidade ).then(
        response => {
          console.log("getPrecosFace:", response.data)
          if ( response.data.length > 0 ) {
            this.precos = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos nenhum preço para o período/unidade informado. ", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar os preços para o período/unidade informado.", floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .pageComponent {
    display: flex; align-content: flex-start;
    min-height: calc(100% - 72px);
    
    >div:not(.sidebar) {
      flex: 1
    }
    .sidebar {
      position: sticky; top: 0;
      border-left: $border-component;
      width: 20%; min-width: 300px; max-width: 340;
    }
  }
</style>