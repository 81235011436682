<template>
  <section>
    <div class="row no-padd" ref="pessoaForm" v-if="matricula">
      <div class="col-md-12 form-group no-padd-left checks" v-if="trackResp && action != 'ver' ">
        <label class="check-control">
          <input type="checkbox" class="check" value="1" v-if="child" v-model="matricula[branch][child].Responsavel.pedagogico" >
          <input type="checkbox" class="check" value="1" v-else v-model="matricula[branch].Responsavel.pedagogico" >
          <span>É Responsável Pedagógico</span>
        </label>
        <label class="check-control">
          <input type="checkbox" class="check" value="1" v-if="child" v-model="matricula[branch][child].Responsavel.financeiro">
          <input type="checkbox" class="check" value="1" v-else v-model="matricula[branch].Responsavel.financeiro" >
          <span>É Responsável Financeiro</span>
        </label>
      </div>

      <div class="col-md-5 form-group no-padd-left">
        <label>Nome Completo</label>
        <input-control
          v-if="child" 
          v-model="matricula[branch][child].Basicos.nomeDocumento" 
          placeholder="Nome do documento" 
          name="nome"
          type="text"
          :valid="valid.nome"
          :disabled="loadingInputContent || highrequire || action == 'ver'" 
          @keyup="valid.nome = true"
          @onsearch="search('nome',$event)" 
          @change="changeName()"
          @focus="triggerTip(branch,'nome')"
          :search="!highrequire" 
          required
        />
        <input-control 
          v-else
          v-model="matricula[branch].Basicos.nomeDocumento" 
          placeholder="Nome do documento" 
          name="nome"
          type="text"
          :valid="valid.nome"
          :disabled="loadingInputContent || highrequire || action == 'ver'" 
          @keyup="valid.nome = true"
          @change="changeName()"
          @onsearch="search('nome',$event)" 
          @focus="triggerTip(branch,'nome')"
          :search="!highrequire" 
          required
        />
      </div>
      <div class="col-md-5 form-group no-padd-left">
        <label>Nome Social</label>
        <input-control 
          v-if="child"
          v-model="matricula[branch][child].Basicos.nome" 
          placeholder="Como gostaria de ser chamado" 
          :disabled="loadingInputContent || action == 'ver' || auto"
          @focus="triggerTip(branch,'nomeSocial')"
          name="nomeSocial" 
          type="text"
        />
        <input-control 
          v-else
          v-model="matricula[branch].Basicos.nome" 
          placeholder="Como gostaria de ser chamado" 
          @focus="triggerTip(branch,'nomeSocial')"
          :disabled="loadingInputContent || action == 'ver' || auto" 
          name="nomeSocial" 
          type="text"
        />
      </div>
      <div class="col-md-2 form-group no-padd-left" v-if="branch == 'Aluno'">
        <label>RA</label>
        <input-control 
          v-model="matricula[branch].Basicos.ra" 
          placeholder="RA" 
          :disabled="loadingInputContent || action == 'ver' || auto" 
          name="ra" 
          type="text"
        />
      </div>
      <div class="col-md-3 form-group no-padd-left">
        <label>Nacionalidade</label>
        <select-control
          v-if="child"
          v-model="matricula[branch][child].Basicos.idNacionalidade" 
          :options="options.nacionalidades"
          placeholder="Selecione" 
          name="idNacionalidade" 
          type="text" 
          :valid="valid.idNacionalidade"
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idNacionalidade = true" 
          @focus="triggerTip(branch,'idNacionalidade')"
          required
        />
        <select-control
          v-else
          v-model="matricula[branch].Basicos.idNacionalidade" 
          :options="options.nacionalidades"
          placeholder="Selecione" 
          name="idNacionalidade" 
          type="text" 
          :valid="valid.idNacionalidade"
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idNacionalidade = true" 
          @focus="triggerTip(branch,'idNacionalidade')"
          required
        />
      </div>
      <div class="col-md-3 form-group no-padd-left">
        <label>Data de Nascimento</label>
        <input-control
          v-if="child"
          v-model="matricula[branch][child].Basicos.dataNascimento"
          placeholder="DD/MM/YYYY" 
          name="dataNascimento" 
          type="date" 
          :valid="valid.dataNascimento"
          :disabled="loadingInputContent || action == 'ver'" 
          @keyup="valid.dataNascimento = true" 
          @focus="triggerTip(branch,'dataNascimento')"
          required
        />
        <input-control 
          v-else
          v-model="matricula[branch].Basicos.dataNascimento"
          placeholder="DD/MM/YYYY" 
          name="dataNascimento" 
          type="date" 
          :valid="valid.dataNascimento"
          :disabled="loadingInputContent || action == 'ver'" 
          @keyup="valid.dataNascimento = true" 
          @focus="triggerTip(branch,'dataNascimento')"
          required
        />
      </div>
      <div class="col-md-3 form-group no-padd-left">
        <label>Identidade de Gênero</label>
        <select-control
          v-if="child"
          v-model="matricula[branch][child].Basicos.sexo" 
          :options="options.generos" 
          placeholder="Selecione" 
          name="sexo" 
          type="text" 
          :valid="valid.sexo" 
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.sexo = true" 
          @focus="triggerTip(branch,'sexo')"
          required
        />
        <select-control 
          v-else
          v-model="matricula[branch].Basicos.sexo" 
          :options="options.generos" 
          placeholder="Selecione" 
          name="sexo" 
          type="text" 
          :valid="valid.sexo" 
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.sexo = true" 
          @focus="triggerTip(branch,'sexo')"
          required
        />
      </div>
      <div class="col-md-3 form-group no-padd-left">
        <label>Raça/Cor</label>
        <select-control
          v-if="child"
          v-model="matricula[branch][child].Basicos.idRacaCor" 
          :options="options.raca" 
          placeholder="Selecione" 
          name="idRacacor" 
          type="text" 
          :valid="valid.idRacacor" 
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idRacacor = true" 
          required
        />
        <select-control 
          v-else
          v-model="matricula[branch].Basicos.idRacaCor" 
          :options="options.raca" 
          placeholder="Selecione" 
          name="idRacacor" 
          type="text" 
          :valid="valid.idRacacor" 
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idRacacor = true"
          required
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <template v-if="child">
          <label v-if="trackResp && matricula[branch][child].Responsavel.financeiro">CPF/CNPJ</label>
          <label v-else>CPF</label>
          <input-control 
            v-model="matricula[branch][child].Documento.cpf" 
            placeholder="000.000.000-00" 
            name="cpf" 
            type="text" 
            :valid="valid.cpf" 
            :disabled="loadingInputContent || highrequire || action == 'ver'" 
            @keyup="valid.cpf = true"
            @onsearch="search('cpf',$event)"
            @focus="triggerTip(branch,'cpf'); triggerCpfChange('record')"
            @change="triggerCpfChange('change'); personType()"
            masking="cpf"
            :search="!highrequire"
            :required="requireCPF()"
          />
          <input type="hidden" name="tipo" v-model="matricula[branch][child].Basicos.tipo"  />
        </template>
        <template v-else>
          <label v-if="trackResp && matricula[branch].Responsavel.financeiro">CPF/CNPJ</label>
          <label v-else>CPF</label>
          <input-control 
            v-model="matricula[branch].Documento.cpf" 
            placeholder="000.000.000-00"
            name="cpf" 
            type="text" 
            :valid="valid.cpf" 
            :disabled="loadingInputContent || highrequire || action == 'ver'" 
            @keyup="valid.cpf = true"
            @onsearch="search('cpf',$event)"
            @focus="triggerTip(branch,'cpf'); triggerCpfChange('record')"
            @change="triggerCpfChange('change'); personType()"
            masking="cpf"
            :search="!highrequire"
            :required="requireCPF()"
          />
          <input type="hidden" name="tipo" v-model="matricula[branch].Basicos.tipo"  />
        </template>
        
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>RG</label>
        <input-control 
          v-if="child"
          v-model="matricula[branch][child].Documento.rg" 
          placeholder="" 
          name="rg" 
          type="text" 
          :disabled="loadingInputContent || action == 'ver'"
          :valid="valid.rg" 
          @keyup="valid.rg = true" 
          @focus="triggerTip(branch,'rg')"
          :required="!lowrequire && !isCNPJ"
        />
        <input-control 
          v-else
          v-model="matricula[branch].Documento.rg" 
          placeholder="" 
          name="rg" 
          type="text" 
          :disabled="loadingInputContent || action == 'ver'"
          :valid="valid.rg" 
          @keyup="valid.rg = true" 
          @focus="triggerTip(branch,'rg')"
          :required="!lowrequire && !isCNPJ"
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Órgão Emissor</label>
        <select-control 
          v-if="child"
          v-model="matricula[branch][child].Documento.rgEmissor" 
          :options="options.orgaosEmissores" 
          placeholder="Selecione" 
          name="rgEmissor" 
          type="text" 
          :valid="valid.rgEmissor" 
          :disabled="loadingInputContent || action == 'ver'" 
          @keyup="valid.orgaoEmissor = true" 
          @focus="triggerTip(branch,'rgEmissor')"
          :required="!lowrequire && !isCNPJ"
        />
        <select-control 
          v-else
          v-model="matricula[branch].Documento.rgEmissor" 
          :options="options.orgaosEmissores" 
          placeholder="Selecione" 
          name="rgEmissor" 
          type="text" 
          :valid="valid.rgEmissor" 
          :disabled="loadingInputContent || action == 'ver'" 
          @keyup="valid.orgaoEmissor = true" 
          @focus="triggerTip(branch,'rgEmissor')"
          :required="!lowrequire && !isCNPJ"
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>E-mail</label>
        <input-control 
          v-if="child"
          v-model="matricula[branch][child].Basicos.email" 
          placeholder="rafinha@email.com.br" 
          :disabled="loadingInputContent || ( lowrequire && auto ) || action == 'ver'" 
          :valid="valid.email"
          @keyup="valid.email = true"
          @focus="triggerTip(branch,'email')"
          name="email" 
          type="text" 
          :required="!lowrequire"
        />
        <input-control 
          v-else
          v-model="matricula[branch].Basicos.email" 
          placeholder="rafinha@email.com.br" 
          :disabled="loadingInputContent ||  ( lowrequire && auto ) || action == 'ver'"
          :valid="valid.email" 
          @keyup="valid.email = true" 
          @focus="triggerTip(branch,'email')"
          name="email"
          type="text"
          :required="!lowrequire"
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Telefone Residencial</label>
        <input-control 
          v-if="child"
          v-model="matricula[branch][child].Basicos.telefonefixo" 
          placeholder="(11) 3456 7890" 
          name="telefonefixo" 
          type="text" 
          masking="fixo" 
          :disabled="loadingInputContent || action == 'ver'" 
          @keydown="addCountryCode('telefonefixo', $event)"
          @focus="triggerTip(branch,'telefonefixo')"
        />
        <input-control 
          v-else
          v-model="matricula[branch].Basicos.telefonefixo" 
          placeholder="(11) 3456 7890" 
          name="telefonefixo" 
          type="text" 
          masking="fixo" 
          :disabled="loadingInputContent || action == 'ver'"
          @keydown="addCountryCode('telefonefixo', $event)"
          @focus="triggerTip(branch,'telefonefixo')"
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Telefone Celular</label>
        <input-control 
          v-if="child"
          v-model="matricula[branch][child].Basicos.telefonecelular" 
          placeholder="(11) 9 8765 4321" 
          name="telefonecelular" 
          type="text" 
          masking="celular" 
          :valid="valid.telefonecelular" 
          @keyup="valid.telefonecelular = true" 
          @keydown="addCountryCode('telefonecelular', $event)"
          @focus="triggerTip(branch,'telefonecelular')"
          :disabled="loadingInputContent || action == 'ver'"
          :required="!lowrequire"
        /> 
        <input-control 
          v-else
          v-model="matricula[branch].Basicos.telefonecelular" 
          placeholder="(11) 9 8765 4321" 
          name="telefonecelular" 
          type="text" 
          masking="celular" 
          :valid="valid.telefonecelular" 
          @keyup="valid.telefonecelular = true"
          @keydown="addCountryCode('telefonecelular', $event)" 
          @focus="triggerTip(branch,'telefonecelular')"
          :disabled="loadingInputContent || action == 'ver'"
          :required="!lowrequire"
        /> 
      </div>
      <div class="col-md-4 form-group no-padd-left" v-if="( trackResp && matricula[branch][child].Responsavel.financeiro == 1  && !auto ) || ( branch == 'respFin' && !auto )">
        <label>Dia vencimento mensalidade</label>
        <template v-if="vencimentoResponsavel">
          <input-control 
            v-if="child"
            v-model="matricula[branch][child].Responsavel.diavencimento"
            placeholder="Ex. 5" 
            name="diavencimento" 
            type="number" 
            @keyup="validateDiaVencimento" 
            :disabled="loadingInputContent || action == 'ver'"
          />
          <input-control 
            v-else
            v-model="matricula[branch].Responsavel.diavencimento"
            placeholder="Ex. 5" 
            name="diavencimento" 
            type="number" 
            @keyup="validateDiaVencimento" 
            :disabled="loadingInputContent || action == 'ver'"
          />
        </template>
        <template v-else>
          <select-control 
            v-if="child"
            v-model="matricula[branch][child].Responsavel.diavencimento"
            :options="options.vencimentos" 
            placeholder="Selecione" 
            name="diavencimento"
            type="text" 
            :disabled="loadingInputContent" 
            @focus="triggerTip(branch,'diavencimento')"
          />
          <select-control 
            v-else
            v-model="matricula[branch].Responsavel.diavencimento" 
            :options="options.vencimentos" 
            placeholder="Selecione" 
            name="diavencimento" 
            type="text" 
            :disabled="loadingInputContent" 
            @focus="triggerTip(branch,'diavencimento')"
            :required="!lowrequire"
          />
        </template>
        
      </div>
    </div>
    <confirm ref="confirm"></confirm>
  </section>
</template>

<script>
import UserService from '@/services/user.service'
import FormService from '@/services/form.service'
import MatriculaService from '@/services/matricula.service'
import { createSelectOptions, clearMask, unformatDate } from '@/services/utils.service'
import Confirm from '@/components/utilities/Confirm'

export default {
  components: {
    Confirm
  },
  props: {
    branch: {
      type: String
    },
    child: {
      type: String
    },
    lowrequire: {
      type: Boolean,
      default: false
    },
    highrequire: {
      type: Boolean,
      default: false
    },
    trackResp: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [String, Number]
    },
    action: {
      type: String
    },
  },
  data () {
    return {
      valid: {
        nome: true,
        nomeSocial: true,
        idNacionalidade: true,
        dataNascimento: true,
        sexo: true,
        cpf: true,
        rg: true,
        rgEmissor: true,
        email: true,
        telefonecelular: true,
        idRacacor: true
      },
      options: {
        nacionalidades: [],
        orgaosEmissores: [],
        generos: [
          { label: 'Feminino', value: 'F' },
          { label: 'Masculino', value: 'M' },
          { label: 'Outros', value: 'O' }
        ],
        vencimentos: [
          { label: '5', value: 5 },
          { label: '10', value: 10 },
          { label: '15', value: 15 },
          { label: '20', value: 20 },
          { label: '30', value: 30 }
        ]
      },
      wait: {
        nacionalidades: false,
        orgaosEmissores: false,
        racaCor: false
      },
      matricula: null,
      loadingInputContent: false,
      isRespPed: false,
      isRespFin: false,
      cpfBkp: null,
      updateDiaVencimento: false,
      vencimentoResponsavel: false,
      vencimentoResponsavelTotal: false,
      diaVencimentoBkp: null,
      isCNPJ: false
    }
  },
  mounted () {
    this.updateDiaVencimento = UserService.filterPermissions('updateDiaVencimento')
    this.vencimentoResponsavel = UserService.filterPermissions('vencimentoResponsavel')
    this.vencimentoResponsavelTotal = UserService.filterPermissions('vencimentoResponsavelTotal')

    // console.log("vencimentoResponsavelTotal:", this.vencimentoResponsavelTotal)
    // console.log("is auto:", this.auto)

    this.$store.dispatch('matricula/get').then(response => {
      console.log("matricula on pessoas:", response)
      this.matricula = response
      window.spinner.emit("open")
      this.loadingInputContent = true
      this.getNacionalidades()
      this.getOrgaosEmissores()
      this.getRacaCor()
      this.personType()

      if ( this.child && this.trackResp ) {
        if ( 
            this.matricula[this.branch][this.child].Responsavel.diavencimento &&
            this.matricula[this.branch][this.child].Responsavel.diavencimento != 5 &&
            this.matricula[this.branch][this.child].Responsavel.diavencimento != 10 
          ) {
          const newVencimento = { label: this.matricula[this.branch][this.child].Responsavel.diavencimento.toString(), value: this.matricula[this.branch][this.child].Responsavel.diavencimento }
          this.options.vencimentos.push(newVencimento) 

          this.diaVencimentoBkp = this.matricula[this.branch][this.child].Responsavel.diavencimento
        } else {
          this.matricula[this.branch][this.child].Responsavel.diavencimento = this.matricula[this.branch][this.child].Responsavel.diavencimento ? this.matricula[this.branch][this.child].Responsavel.diavencimento : 5
        }

        this.diaVencimentoBkp = this.matricula[this.branch][this.child].Responsavel.diavencimento
      }
      
      if ( this.branch == 'respFin' ) {
        if ( 
            this.matricula[this.branch].Responsavel.diavencimento &&
            this.matricula[this.branch].Responsavel.diavencimento != 5 && 
            this.matricula[this.branch].Responsavel.diavencimento != 10 
          ) {
            // console.log("dua vencimento existe e não 5 nem 10...")
          const newVencimento = { label: this.matricula[this.branch].Responsavel.diavencimento.toString(), value: this.matricula[this.branch].Responsavel.diavencimento }
          this.options.vencimentos.push(newVencimento) 
        } else {
          this.matricula[this.branch].Responsavel.diavencimento = this.matricula[this.branch].Responsavel.diavencimento ? this.matricula[this.branch].Responsavel.diavencimento : 5
        }
        this.diaVencimentoBkp = this.matricula[this.branch].Responsavel.diavencimento
      }
    })
  },
  methods: {
    getNacionalidades () {
      UserService.nacionalidades().then(
        response => {
          if ( response.data.length > 0 ) {
            this.wait.nacionalidades = true
            this.options.nacionalidades = createSelectOptions(response.data,'idNacionalidade', 'descricao')
          } else {
            //
          }
          this.stopSpinner()
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
        }
      )
    },
    getOrgaosEmissores () {
      UserService.orgaosEmissores().then(
        response => {
          if ( response.data.length > 0 ) {
            this.wait.orgaosEmissores = true
            this.options.orgaosEmissores = createSelectOptions(response.data,'idorgaoemissor', 'nome')
          } else {
            //
          }
          this.stopSpinner()
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
        }
      )
    },
    getRacaCor () {
      UserService.racaCor().then(
        response => {
          console.log("racaCor:", response.data)

          if ( response.data.length > 0 ) {
            this.wait.racaCor = true
            this.options.raca = createSelectOptions(response.data,'idRacaCor', 'descricao')
          } else {
            //
          }
          this.stopSpinner()
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
        }
      )
    },
    search ( by ) {
      if ( by === 'nome' ) {
        const nome = this.child ? this.matricula[this.branch][this.child].Basicos.nomeDocumento : this.matricula[this.branch].Basicos.nomeDocumento
        if ( nome && nome.length > 3 ) {
          window.spinner.emit('open')
          MatriculaService.getPessoaByNome( nome ).then(
            response => {
              console.log("getPessoaByCpfOuNome - nome:", response.data)
              if ( response.data.dados ) {
                this.$refs.confirm.run({
                  message: "Encontramos uma pessoa com este nome:<br><span class='color-text'>" + nome.toUpperCase() + "</span><br>Você deseja auto-preencher os campos do fomulário com os dados desta pessoa?",
                  confirmButton: "Sim",
                  denyButton: "Não",
                  callback: this.populate,
                  data: response.data,
                  size: 'mid'
                })
              }
              window.spinner.emit('close')
            },
            error => {
              console.error(error)
              window.spinner.emit('close')
              window.toaster.emit('open', {style: "error", message: "Não encontramos ninguém com o nome informado.", floater: true})
            }
          )
        }
      }

      if ( by === 'cpf' ) {
        const cpf = this.child ? this.matricula[this.branch][this.child].Documento.cpf : this.matricula[this.branch].Documento.cpf
        if ( cpf &&  ( cpf.length == 14 || cpf.length == 18 ) ) {
          const drop = clearMask('cpf', cpf)
          window.spinner.emit('open')
          MatriculaService.getPessoaByIdPessoaOuCpf( 0, drop ).then(
            response => {
              console.log("getPessoaByCpfOuNome - cpf:", response.data)
              const message = response.data.dados.Basicos.nomeDocumento ? ( response.data.dados.Basicos.nomeDocumento + " - " + cpf ) : cpf
              if ( response.data.dados ) {
                this.$refs.confirm.run({
                  message: "Encontramos uma pessoa com este CPF:<br><span class='color-text'>" + message + "</span><br>Você deseja auto-preencher os campos do fomulário com os dados desta pessoa?",
                  confirmButton: "Sim",
                  denyButton: "Não",
                  callback: this.populate,
                  data: response.data,
                  size: 'mid'
                })
              }
              window.spinner.emit('close')
            },
            error => {
              console.error(error)
              window.spinner.emit('close')
              const doc = cpf.length == 14 ? 'cpf' : 'cnpj'
              window.toaster.emit('open', {style: "error", message: "Não encontramos ninguém com o " + doc + " informado.", floater: true})
            }
          )
        }
      }
    },
    validate () {
      const rg = this.child ? this.matricula[this.branch][this.child]?.Documento?.rg : this.matricula[this.branch]?.Documento?.rg
       
       this.child
      if ( this.isCNPJ && !rg ) {
        if ( this.child ) {
          this.matricula[this.branch][this.child].Documento.rg = '000000000'
        } else {
          this.matricula[this.branch].Documento.rg = '000000000'
        }
      } 
      
      if ( this.child ) {
        this.matricula[this.branch][this.child].Basicos.idRacaCor = parseFloat(this.matricula[this.branch][this.child].Basicos.idRacaCor)
      } else {
        this.matricula[this.branch].Basicos.idRacaCor = parseFloat(this.matricula[this.branch].Basicos.idRacaCor)
      }

      const valid = FormService.validate(this.$refs.pessoaForm, this.valid)
      // console.log("validate:", valid)
      return valid
    },
    populate (status, data) {
      if ( status ) {
        if ( this.child ) {
          delete data.dados.Basicos.imageBase64

          this.matricula[this.branch][this.child].Basicos = data.dados.Basicos
          let nomeDocumento = this.matricula[this.branch][this.child].Basicos.nomeDocumento
          let nome = this.matricula[this.branch][this.child].Basicos.nome

          if ( !nomeDocumento ) {
            this.matricula[this.branch][this.child].Basicos.nomeDocumento = nome
          }

          this.matricula[this.branch][this.child].Basicos.dataNascimento = unformatDate(this.matricula[this.branch][this.child].Basicos.dataNascimento)
          if ( data.dados.Documento ) {
             this.matricula[this.branch][this.child].Documento = {
              cpf: data.dados.Documento.cpf,
              rg: data.dados.Documento.rg,
              nomeOrgaoEmissor: data.dados.Documento.nomeOrgaoEmissor,
              rgEmissor: data.dados.Documento.rgEmissor
             }
          }
        } else {
          delete data.dados.Basicos.imageBase64
          
          this.matricula[this.branch].Basicos = data.dados.Basicos

          let nomeDocumento = this.matricula[this.branch].Basicos.nomeDocumento
          let nome = this.matricula[this.branch].Basicos.nome

          if ( !nomeDocumento ) {
            this.matricula[this.branch].Basicos.nomeDocumento = nome
          }

          this.matricula[this.branch].Basicos.dataNascimento = unformatDate(this.matricula[this.branch].Basicos.dataNascimento)
          if ( data.dados.Documento ) {
            this.matricula[this.branch].Documento = {
              cpf: data.dados.Documento.cpf,
              rg: data.dados.Documento.rg,
              nomeOrgaoEmissor: data.dados.Documento.nomeOrgaoEmissor,
              rgEmissor: data.dados.Documento.rgEmissor
             }
          }
        }
        
        if ( data.dados.Endereco ) {
          this.$emit('populateEndereco', data.dados.Endereco)
        }
        
      }
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      console.log("done:", done)
      if ( done ) {
        this.$emit('onload')
        this.loadingInputContent = false
      }
    },
    changeName () {
      const nomeDocumento = this.child ? this.matricula[this.branch][this.child].Basicos.nomeDocumento : this.matricula[this.branch].Basicos.nomeDocumento
      const nome = this.child ? this.matricula[this.branch][this.child].Basicos.nome : this.matricula[this.branch].Basicos.nome

      if ( !nome ) {
        if ( this.child ) {
          this.matricula[this.branch][this.child].Basicos.nome = nomeDocumento
        } else {
          this.matricula[this.branch].Basicos.nome = nomeDocumento
        }
      }
    },
    addCountryCode ( src, e ) {
      var target = this.child ? this.matricula[this.branch][this.child].Basicos[src] : this.matricula[this.branch].Basicos[src]
      const iniCode = '+55'
      // console.log("key:", e.key)
      const escape = ['Enter', 'Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape']
      const allowAddCode = !escape.includes(e.key)


      // console.log("target.length:", target.length)
      
      if ( !target && allowAddCode ) {
        const newValue = iniCode + target
        console.log("newValue:", newValue)

        if ( this.child ) {
          this.matricula[this.branch][this.child].Basicos[src] = newValue
        } else {
          this.matricula[this.branch].Basicos[src] = newValue
        }
        // target = iniCode + target
      }
    },
    triggerTip ( branch, field ) {
      const tip = {
        branch: branch,
        field: field
      }
      window.mattip.emit("add", tip)
    },
    triggerCpfChange ( action ) {
      // console.log("action:", action)
      if ( this.matricula ) {
        const cpf = this.child ? this.matricula[this.branch][this.child]?.Documento?.cpf : this.matricula[this.branch]?.Documento?.cpf

        if ( action === 'record' && cpf ) {
          this.cpfBkp = cpf
          // console.log("cpfBkp:", this.cpfBkp)
        }
        if ( action === 'change' && cpf ) {
          const currentCpf = cpf
          // console.log("currentCpf:", currentCpf)
          if ( this.cpfBkp != currentCpf ) {
            // console.log("bkp != current")

            if ( this.child ) {
              this.matricula[this.branch][this.child].Basicos.idPessoa = null
              this.matricula[this.branch][this.child].Basicos.rescodigo = null
              this.matricula[this.branch][this.child].Documento.idPessoa = null
              this.matricula[this.branch][this.child].Documento.idDocumento = null
              this.matricula[this.branch][this.child].Endereco.idPessoa = null
              this.matricula[this.branch][this.child].Endereco.idEndereco = null
              if ( this.matricula[this.branch][this.child].Responsavel ) {
                this.matricula[this.branch][this.child].Responsavel.idPessoa = null
                this.matricula[this.branch][this.child].Responsavel.idresponsavel = null
              }

              console.log("idMatricula 1: ", this.matricula[this.branch][this.child])

              if ( !this.matricula[this.branch][this.child].matricula ) {
                console.log("tem child e não tem id matricula")
                // this.matricula[this.branch][this.child]['matricula'] = this.matricula.matricula
                // this.matricula[this.branch][this.child].matricula.idMatricula = this.idMatricula
              }
              console.log("matricula:", this.matricula[this.branch][this.child] )
            } else {
              this.matricula[this.branch].Basicos.idPessoa = null
              this.matricula[this.branch].Basicos.rescodigo = null
              this.matricula[this.branch].Documento.idPessoa = null
              this.matricula[this.branch].Documento.idDocumento = null
              this.matricula[this.branch].Endereco.idPessoa = null
              this.matricula[this.branch].Endereco.idEndereco = null
              if ( this.matricula[this.branch].Responsavel ) {
                this.matricula[this.branch].Responsavel.idPessoa = null
                this.matricula[this.branch].Responsavel.idresponsavel = null
              }
              
              console.log("idMatricula 2: ", this.matricula[this.branch])

              if ( !this.matricula[this.branch].matricula ) {
                console.log("não tem child e não tem id matricula")
                // this.matricula[this.branch]['matricula'] = this.matricula.matricula
                // this.matricula[this.branch].matricula.idMatricula = this.idMatricula
                // console.log("matricula:", this.matricula[this.branch] )
              }
              // console.log("pessoa:", this.matricula[this.branch])
            }

            
          }
        } 
      }
      
    },
    personType () {
      var doc = this.child ? this.matricula[this.branch][this.child]?.Documento?.cpf : this.matricula[this.branch]?.Documento?.cpf
          doc = clearMask('cpf', doc)

      console.log("doc:", doc)

      const isCNPJ = doc.length == 14
      this.isCNPJ = isCNPJ

      console.log("isCNPJ:", isCNPJ)

      if ( isCNPJ ) {
        if ( this.child ) {
          this.matricula[this.branch][this.child].Basicos.tipo = 2
        } else {
          this.matricula[this.branch].Basicos.tipo = 2
        }
      } else {
        if ( this.child ) {
          this.matricula[this.branch][this.child].Basicos.tipo = 1
        } else {
          this.matricula[this.branch].Basicos.tipo = 1
        }
      }
    },
    requireCPF () {
      console.log("requireCPF:", this.matricula)
      if ( this.highrequire ) {
        return true
      } else {
        return this.matricula.matricula.requerCPF == 1
      }
    },
    validateDiaVencimento () {
      if ( !this.vencimentoResponsavelTotal ) {
        const origin = this.diaVencimentoBkp
        const dia = this.child ? this.matricula[this.branch][this.child].Responsavel.diavencimento : this.matricula[this.branch].Responsavel.diavencimento
        const limit = origin < 30 ? 30 : origin

        const newDay = dia > limit ? limit : dia

        if ( dia > limit ) {
          window.toaster.emit('open', {style: "error", message: "A data é limite é dia " + limit, floater: true})
        }

        if ( this.child ) {
          this.matricula[this.branch][this.child].Responsavel.diavencimento = newDay
        } else {
          this.matricula[this.branch].Responsavel.diavencimento = newDay
        }
      }
      

    }
  }
}
</script>

<style lang="scss" scoped>

</style>