<script setup>
  import FilterController from '@/components/planejamentovendas/descontosaluno/utilities/FilterController.vue'
  import DescontosAlunos from '@/components/planejamentovendas/descontosaluno/template/DescontosAlunos.vue'
  import FormSpinner from '@/components/utilities/FormSpinner.vue'
  import PvService from '@/services/pv.service.js'
  import SidebarController from '@/components/planejamentovendas/projecao/template/SidebarFaturamento.vue'
</script>

<template>
  <controller>
    <page-header title="Descontos por Aluno">
      <template v-slot:side>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="toggleFilter()" class="btn btn-primary" v-if="filterType == 'filter'">
            Sincronizar Dados
          </a>
        </transition>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="toggleFilter()" class="btn btn-primary" v-if="filterType == 'sync'">
            Filtrar
          </a>
        </transition>
      </template>
    </page-header>
    <section class="pageComponent">
      <div>
        <filter-controller @onFilter="runFilter" @onSync="sync" :type="filterType"/>
        <form-spinner v-if="loading"/>
        <descontos-alunos :scope="descontos" v-if="descontos"/>
      </div>
      <div :class="['sidebar', { 'active': sidebar }]">
        <sidebar-controller v-if="sidebar"/>
      </div>
    </section>
  </controller>
</template>

<script>

export default {
  components: {
    FilterController, FormSpinner,
    DescontosAlunos, SidebarController
  },
  data () {
    return {
      descontos: null,
      filter: null,
      loading: false,
      filterType: 'filter',
      sidebar: false
    }
  },
  methods: {
    runFilter( filter ) {
      this.filter = filter
      this.loading = true
      
      PvService.getDescontosPorAluno( filter ).then(
        response => {
          if ( response.data.length > 0 ) {
            this.descontos = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos dados com os campos informados.", floater: true})
          }
          this.loading = false
        },
        error => {
          this.loading = false
          console.error(error.response.data)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível buscar descontos no momento. Se o problema persistir , por favor, entre em contato com o suporte.", floater: true})
        }
      )
    },
    sync ( filter ) {
      PvService.syncDescontosPorAluno( filter ).then(
        response => {
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Sincronismo iniciado. Isso pode demorar alguns minutos. Você será notificado sobre o status.", floater: true})
            this.filterType = 'filter'
          }
          
          this.loading = false
        },
        error => {
          this.loading = false
          console.error(error.response.data)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível sincronizar os dados. Se o problema persistir , por favor, entre em contato com o suporte.", floater: true})
        }
      )
    },
    toggleFilter () {
      this.filterType = this.filterType == 'filter' ? 'sync' : 'filter'
    }
  }
}
</script>

<style lang="scss" scoped>
  .pageComponent {
    display: flex; align-content: flex-start;
    min-height: calc(100% - 72px);
    
    >div:not(.sidebar) {
      flex: 1
    }
    .sidebar {
      position: sticky; top: 0;
      border-left: $border-component;
      width: 0;
      @extend %transition_4o;

      &.active {
        width: 20%; min-width: 260px; max-width: 320px;
      }
    }
  }
</style>