<script setup>
  import FinanceiroService from '@/services/financeiro.service.js'
  import Arrecadacoes from '@/components/financeiro/parametros/form/Arrecadacoes.vue'
  import Descontos from '@/components/financeiro/parametros/form/Descontos.vue'
  import Progressao from '@/components/financeiro/parametros/form/Progressao.vue'
</script>

<template>
  <controller :goToTopButton="true">
    <page-header :title="dropTitle()">
      <template v-slot:side>
        <transition name="slideRightToLeft">
          <router-link class="btn btn-secondary" :to="{ name: 'Parâmetros Financeiros' }" v-if="target">
            <span>Início</span>
          </router-link>
        </transition>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="confirmSync()" class="btn btn-primary" v-if="!syncing && target != 'progressao-descontos'">
            <span>Sincronizar Parâmetros</span>&nbsp;
            <fa :icon="['fas', 'cubes']"></fa>
          </a>
        </transition>
      </template>
    </page-header>
    <div>
      <section class="start" v-if="!filterType">
        <h4 class="color-text">Escolha abaixo que tipo de parâmetros você quer visualizar</h4>
        <ul>
          <li @click="setFilterType('arrecadacoes')">
            <fa :icon="['fas', 'badge-dollar']" class="icon"></fa>
            <h5 class="color-text">Arrecadações</h5>
          </li>
          <li @click="setFilterType('descontos')">
            <fa :icon="['fas', 'badge-percent']" class="icon"></fa>
            <h5 class="color-text">Descontos</h5>
          </li>
          <li @click="setFilterType('progressao-descontos')">
            <fa :icon="['fas', 'droplet-percent']" class="icon"></fa>
            <h5 class="color-text">Progressão Descontos</h5>
          </li>
        </ul>
      </section>
      <transition name="slideup-small">
        <arrecadacoes ref="arrecadacoes" v-if="filterType == 'arrecadacoes'" @onCancel="clearFilter()" />
      </transition>
      <transition name="slideup-small">
        <descontos ref="descontos" v-if="filterType == 'descontos'" @onCancel="clearFilter()" />
      </transition>
      <transition name="slideup-small">
        <progressao ref="progressao-descontos" v-if="filterType == 'progressao-descontos'" @onCancel="clearFilter()" />
      </transition>
      <confirm 
        :backdrop="false"
        class="confirm"
        ref="confirm"
      ></confirm>
    </div>
  </controller>
</template>

<script>

export default {
  components: {
    Arrecadacoes, Descontos, Progressao
  },
  props: {
    target: {
      type: String
    }
  },
  data () {
    return {
      syncing: false,
      filterType: null,
      sessions: [
        {
          slug: 'arrecadacoes',
          title: 'Arrecadações'
        },
        {
          slug: 'descontos',
          title: 'Descontos'
        },
        {
          slug: 'progressao-descontos',
          title: 'Progressão de Descontos'
        }
      ]
    }
  },
  mounted () {
    if ( this.target ) {
      this.setFilterType(this.target, true)
    } else {
      this.filterType = null
    }
  },
  methods: {
    confirmSync () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja sincronizar os parâmetros financeiros?",
        confirmButton: "Sim",
        denyButton: "Não",
        // data: gatilho,
        callback: this.syncParams,
      })
    },
    syncParams ( confirm ) {
      console.log("confirm:", confirm)

      if ( confirm ) {
        window.spinner.emit("open")
        FinanceiroService.syncParams().then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Parâmetros financeiros sincronizados com sucesso", floater: true})

              if ( this.filterType == 'arrecadacoes' ) this.$refs.arrecadacoes.getArrecadacoes()
              if ( this.filterType == 'descontos' ) this.$refs.descontos.getDescontos()

            } else {
              window.toaster.emit('open', {style: "error", message: "Não foi possível sincronizar os parâmetros financeiros. Isto parece um erro de integração com o GestorClass", floater: true})
            }
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível sincronizar os parâmetros financeiros.", floater: true})
            window.spinner.emit("close")
          }
        )
      }
    },
    clearFilter () {
      this.filterType = null
    },
    setFilterType ( type, skip ) {
      this.filterType = type

      if ( !skip ) {
        console.log("dont skip")
        this.$router.push({name: 'Parâmetros Financeiros', params: { target: type }})
      } 
    },
    dropTitle () {
      const title = this.target ? this.sessions.filter( a => a.slug == this.target)[0].title : 'Parâmetros Financeiros'
      return title
    }
  }
}
</script>

<style lang="scss" scoped>
  .start {
    padding: $hmg;

    > h4 {
      // text-align: center;
      margin-bottom: $hmg;
    }
    ul {
      padding: 0; margin: 0;
      display: flex;
      align-items: center; align-content: center;

      li {
        list-style: none;
        display: flex; flex-direction: column;
        align-items: center; align-content: center;
        justify-content: center;
        text-align: center;
        width: 150px; height: 130px;
        background-color: $color-secondary;
        border-radius: $border-radius;
        @extend %transition_4e;
        margin: 0 $hmg_small 0 0;
        padding: $hmg_small;
        cursor: pointer;

        .icon {
          font-size: 44px;
          margin-bottom: $hmg_small;
          color: $color-primary
        }

        h5 {
          padding: 0; margin: 0
        }

        &:hover {
          @extend %shadow-mid;
          transform: scale(1.1);
        }
      }
    }
  }
</style>