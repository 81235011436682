<script setup>
  import { formatMoney } from '@/services/utils.service.js'
  import FinanceiroService from '@/services/financeiro.service.js'
</script>

<template>
  <section v-if="data" >
    <div class="wrap">
      <header>
        <div>
          <a href="#" @click.prevent="toggle('all')">
            <fa :icon="['fas','chevron-down']" />
          </a>
        </div>
        <div>
          <div>
            <label>Reajuste</label>
            <input-control v-model="reajusteGeral" cssClass="small" />
            <a href="#" class="runButton" @click.prevent="reajustar('all', 'reajuste', reajusteGeral)">
              <fa :icon="['fas','chevron-right']" />
            </a>
          </div>
          <div>
            <label>Mínimo</label>
            <input-control v-model="minimoGeral" cssClass="small" />
            <a href="#" class="runButton" @click.prevent="reajustar('all', 'min', reajusteGeral)">
              <fa :icon="['fas','chevron-right']" />
            </a>
          </div>
          <div>
            <label>Máximo</label>
            <input-control v-model="maximoGeral" cssClass="small" />
            <a href="#" class="runButton" @click.prevent="reajustar('all', 'max', reajusteGeral)">
              <fa :icon="['fas','chevron-right']" />
            </a>
          </div>
          <div>
            <label>Desconto</label>
            <input-control v-model="descontoGeral" cssClass="small" />
            <a href="#" class="runButton" @click.prevent="reajustar('all', 'desc', reajusteGeral)">
              <fa :icon="['fas','chevron-right']" />
            </a>
          </div>
          <div>
            <label>Alçada</label>
            <input-control v-model="alcadaGeral" cssClass="small" />
            <a href="#" class="runButton" @click.prevent="reajustar('all', 'alc', reajusteGeral)">
              <fa :icon="['fas','chevron-right']" />
            </a>
          </div>
        </div>
      </header>
      <div v-for="(item, ki) in data" :key="item" class="unidades">
        <header>
          <a href="#" @click.prevent="toggle(ki)">
            <h4>{{ ki }}</h4>
            <fa :icon="['fas','chevron-down']" />
          </a>
          <div>
            <div>
              <label>Reajuste</label>
              <input-control v-model="item.reajuste" cssClass="small" />
              <a href="#" class="runButton" @click.prevent="reajustar('unidade', 'reajuste', ki)">
                <fa :icon="['fas','chevron-right']" />
              </a>
            </div>
            <div>
              <label>Mínimo</label>
              <input-control v-model="item.min" cssClass="small" />
              <a href="#" class="runButton" @click.prevent="reajustar('unidade', 'min', ki)">
                <fa :icon="['fas','chevron-right']" />
              </a>
            </div>
            <div>
              <label>Máximo</label>
              <input-control v-model="item.max" cssClass="small" />
              <a href="#" class="runButton" @click.prevent="reajustar('unidade', 'max', ki)">
                <fa :icon="['fas','chevron-right']" />
              </a>
            </div>
            <div>
              <label>Desconto</label>
              <input-control v-model="item.desconto" cssClass="small" />
              <a href="#" class="runButton" @click.prevent="reajustar('unidade', 'desc', ki)">
                <fa :icon="['fas','chevron-right']" />
              </a>
            </div>
            <div>
              <label>Alçada</label>
              <input-control v-model="item.alcada" cssClass="small" />
              <a href="#" class="runButton" @click.prevent="reajustar('unidade', 'alc', ki)">
                <fa :icon="['fas','chevron-right']" />
              </a>
            </div>
          </div>
          
        </header>
        <div v-for="(curso, kc) in item.cursos" :key="curso" :class="['cursos', { closed: !item.active }]">
          <header>
            <a href="#" @click.prevent="toggle(ki, kc)">
              <h5>{{ kc }}</h5>
              <fa :icon="['fas','chevron-down']" />
            </a>
            <div>
              <div>
                <label>Reajuste</label>
                <input-control v-model="curso.reajuste" cssClass="small" />
                <a href="#" class="runButton" @click.prevent="reajustar('curso', 'reajuste', ki, kc)">
                  <fa :icon="['fas','chevron-right']" />
                </a>
              </div>
              <div>
                <label>Mínimo</label>
                <input-control v-model="curso.min" cssClass="small" />
                <a href="#" class="runButton" @click.prevent="reajustar('curso', 'min', ki, kc)">
                  <fa :icon="['fas','chevron-right']" />
                </a>
              </div>
              <div>
                <label>Máximo</label>
                <input-control v-model="curso.max" cssClass="small" />
                <a href="#" class="runButton" @click.prevent="reajustar('curso', 'max', ki, kc)">
                  <fa :icon="['fas','chevron-right']" />
                </a>
              </div>
              <div>
                <label>Desconto</label>
                <input-control v-model="curso.desconto" cssClass="small" />
                <a href="#" class="runButton" @click.prevent="reajustar('curso', 'desc', ki, kc)">
                  <fa :icon="['fas','chevron-right']" />
                </a>
              </div>
              <div>
                <label>Alçada</label>
                <input-control v-model="curso.alcada" cssClass="small" />
                <a href="#" class="runButton" @click.prevent="reajustar('curso', 'alc', ki, kc)">
                  <fa :icon="['fas','chevron-right']" />
                </a>
              </div>
            </div>
          </header>
          <div :class="['series', { closed: !curso.active }]">
            <table class="transparent-table">
              <thead>
                <tr>
                  <th>Série/Turno</th>
                  <th>Preço de Face</th>
                  <th>Reajuste</th>
                  <th>Valor Reajuste</th>
                  <th>Valor Final</th>
                  <th>Reajuste Mín</th>
                  <th>Reajuste Max</th>
                  <th>Desconto Padrão</th>
                  <th>Alçada Padrão</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(serie, ks) in curso.series" :key="serie"> 
                  <tr>
                    <td>{{ serie.serie }}</td>
                    <td>{{ formatMoney(serie.precoFace) }}</td>
                    <td><input-control v-model="serie.reajuste" cssClass="small" @input="calcReajuste(ki, kc, ks)" type="number" /></td>
                    <td><input-control v-model="serie.valorReajuste" cssClass="small" type="number"/></td>
                    <td><input-control v-model="serie.valorFinal" cssClass="small" type="number" /></td>
                    <td><input-control v-model="serie.reajusteFinalMin" cssClass="small" type="number" /></td>
                    <td><input-control v-model="serie.reajusteFinalMax" cssClass="small" type="number" /></td>
                    <td><input-control v-model="serie.descontoPadrao" cssClass="small" type="number" /></td>
                    <td><input-control v-model="serie.alcadaPadrao" cssClass="small" type="number" /></td>
                    <td class="action"><a href="#" @click.prevent="toggleArrec(ki,kc,ks)">
                      <fa :icon="['far','magnifying-glass-minus']" v-if="serie.active"/>
                      <fa :icon="['far','magnifying-glass-plus']" v-else/>
                    </a></td>
                  </tr>
                  <tr :class="['nopad', { 'collapsed-row': serie.active }] " v-if="serie.active">
                    <td colspan="8">
                      <div class="subtable">
                        <table>
                          <thead>
                            <tr>
                              <th>Concessão</th>
                              <th>Valor</th>
                              <th>Fração Reajuste (%)</th>
                              <th>Valor Fração</th>
                              <th>Valor final (R$)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(model, km) in serie.modelagem" :key="model">
                              <td>{{ model.tipoArrecadacao }}</td>
                              <td>{{ formatMoney(model.valor) }}</td>
                              <td><input-control v-model="model.percentualReajuste" cssClass="small" type="number" @input="calcFracao(ki, kc, ks, km)"/></td>
                              <td><input-control v-model="model.valorReajuste" cssClass="small" type="number"/></td>
                              <td><input-control v-model="model.valorFinal" cssClass="small" type="number"/></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <a href="#" class="btn btn-primary" @click.prevent="save">
        Salvar
      </a>
    </footer>
  </section>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.parseData()
      }
    }
  },
  data () {
    return {
      data: null,
      reajusteGeral: null,
      minimoGeral: null,
      maximoGeral: null,
      descontoGeral: null,
      alcadaGeral: null,
      typeTimeout: null
    }
  },
  mounted () {
    if ( this.scope ) {
      this.parseData()
    }
  },
  methods: {
    parseData () {
      this.data = this.scope.reduce((acc, item) => {
        if (!acc[item.unidade]) {
          acc[item.unidade] = {
            unidade: item.unidade,
            idUnidade: item.idUnidade,
            reajuste: null,
            max: null,
            min: null,
            desconto: null,
            alcada: null,
            active: true,
            cursos: {}
          }
        }

        if (!acc[item.unidade].cursos[item.curso]) {
          acc[item.unidade].cursos[item.curso] = {
            curso: item.curso,
            idCurso: item.idCurso,
            reajuste: null,
            max: null,
            min: null,
            desconto: null,
            alcada: null,
            active: true,
            series: []
          }
        }

        acc[item.unidade].cursos[item.curso].series.push({
          idSerie: item.idSerie,
          serie: item.serie,
          precoFace: item.precoFace,
          idPrecoFace: item.idPrecoFace,
          reajuste: item.reajuste,
          valorReajuste: item.valorReajuste,
          valorFinal: item.valorFinal,
          reajusteFinalMin: item.reajusteFinalMin,
          reajusteFinalMax: item.reajusteFinalMax,
          descontoPadrao: item.descontoPadrao,
          alcadaPadrao: item.alcadaPadrao,
          active: false,
          modelagem: item.modelagem,
        })

        return acc
      }, {})

    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    toggle ( target1, target2 ) {
      if ( target1 == 'all' ) {
        Object.values(this.data).forEach( unidade => {
          unidade.active = !unidade.active
        })
      } else {
        if ( target2 ) {
          const currentStatus = this.data[target1].cursos[target2].active
          console.log("currentStatus:", currentStatus)

          this.data[target1].cursos[target2].active = !this.data[target1].cursos[target2].active 
        } else {
          const currentStatus = this.data[target1].active
          console.log("currentStatus:", currentStatus)

          this.data[target1].active = !this.data[target1].active 
        }
      }
      
    },
    toggleArrec ( ki, kc, ks ) {
      this.data[ki].cursos[kc].series[ks].active = !this.data[ki].cursos[kc].series[ks].active
    },
    reajustar ( action, item, target1, target2 ) {
      if ( action == 'all' ) {
        const value = this.reajusteGeral

        Object.values(this.data).forEach( unidade => {
          Object.values(unidade.cursos).forEach( curso => {
            curso.series.forEach( (serie, i) => {
              if ( item == 'reajuste' ) {
                serie.reajuste = value
                this.calcReajuste(unidade.unidade, curso.curso, i)
              } else if ( item == 'min' ){
                serie.reajusteFinalMin = this.minimoGeral
              } else if ( item == 'max' ) {
                serie.reajusteFinalMax = this.maximoGeral
              } else if ( item == 'desc' ) {
                serie.descontoPadrao = this.descontoGeral
              } else if ( item == 'alc' ) {
                serie.alcadaPadrao = this.alcadaGeral
              }
              
            })
          })
        })
      }

      if ( action == 'unidade' ) {
        const { reajuste, cursos, min, max, desconto, alcada} = this.data[target1]
        
        Object.values(cursos).forEach( curso => {
          curso.series.forEach( (serie, i) => {
            if ( item == 'reajuste' ) {
              serie.reajuste = reajuste
              this.calcReajuste(target1, curso.curso, i)
            } else if ( item == 'min' ){
              serie.reajusteFinalMin = min
            } else if ( item == 'max' ) {
              serie.reajusteFinalMax = max
            } else if ( item == 'desc' ) {
              serie.descontoPadrao = desconto
            } else if ( item == 'alc' ) {
              serie.alcadaPadrao = alcada
            }
          })
        })
      }

      if ( action == 'curso' ) {
        const { reajuste, series, min, max, desconto, alcada } = this.data[target1].cursos[target2]

        series.forEach( (serie, i) => {
          if ( item == 'reajuste' ) {
            serie.reajuste = reajuste
            this.calcReajuste(target1, target2, i)
          } else if ( item == 'min' ){
            serie.reajusteFinalMin = min
          } else if ( item == 'max' ) {
            serie.reajusteFinalMax = max
          } else if ( item == 'desc' ) {
            serie.descontoPadrao = desconto
          } else if ( item == 'alc' ) {
            serie.alcadaPadrao = alcada
          }
        })
      }
    },
    calcReajuste ( ki, kc, ks ) {
      const target = this.data[ki].cursos[kc].series[ks]
      var { precoFace, reajuste } = target

      if ( reajuste ) {
        target.valorReajuste = (precoFace * reajuste) / 100
        target.valorFinal = precoFace + target.valorReajuste
      }
    },
    validateFracaoSum ( model, ref ) { 
      const totalPercentualReajuste = model
          .map(item => Number(item.percentualReajuste) || 0)
          .reduce((sum, value) => sum + value, 0)
          
      const valid = ref == 'max' ? totalPercentualReajuste <= 100 : totalPercentualReajuste == 100

      return valid
    },
    calcFracao ( ki, kc, ks, km ) {
      if ( this.typeTimeout ) clearTimeout(this.typeTimeout)
      const target = this.data[ki].cursos[kc].series[ks]
      const { valorReajuste, modelagem } = target

      if ( valorReajuste ) {
        this.typeTimeout = setTimeout(() => {
          const percentual = parseFloat(modelagem[km].percentualReajuste)
          const valorOriginal = modelagem[km].valor
          
          const last = km == modelagem.length - 1

          const valid = last ? ( this.validateFracaoSum(modelagem, 'min') && this.validateFracaoSum(modelagem, 'max') ) : this.validateFracaoSum(modelagem, 'max')

          if ( valid ) {
            const valorFracao = ( valorReajuste * percentual) / 100
            target.modelagem[km].valorReajuste = valorFracao
            target.modelagem[km].valorFinal = valorOriginal + valorFracao
          } else {
            window.toaster.emit('open', {style: "error", message: "Soma dos reajustes diferentes de 100%. Revise os campos preenchidos.", floater: true})
          }
        }, 300)
      } else {
        window.toaster.emit('open', {style: "error", message: "Essa série ainda não tem um valor de reajuste definido.", floater: true})
        modelagem[km].percentualReajuste = null
      }
      
    },
    revertToArray(data) {
      let result = []

      // Iterate through each unidade
      for (const unidadeKey in data) {
        const unidade = data[unidadeKey]

        for (const cursoKey in unidade.cursos) {
          const curso = unidade.cursos[cursoKey]

          curso.series.forEach(serie => {
            result.push({
              idSerie: serie.idSerie,
              serie: serie.serie,
              idPrecoFace: serie.idPrecoFace,
              precoFace: serie.precoFace,
              reajuste: serie.reajuste,
              valorReajuste: serie.valorReajuste ?? null,
              valorFinal: serie.valorFinal ?? null,
              periodoletivo: "2025",
              idUnidade: unidade.idUnidade,
              unidade: unidade.unidade,
              idCurso: curso.idCurso,
              curso: curso.curso,
              reajusteFinalMin: serie.reajusteFinalMin,
              reajusteFinalMax: serie.reajusteFinalMax,
              descontoPadrao: serie.descontoPadrao,
              alcadaPadrao: serie.alcadaPadrao,
              modelagem: serie.modelagem,
            })
          })
        }
      }

      return result
    },
    save () {
      // Example usage:
      const data = this.revertToArray(this.data)

      FinanceiroService.saveModelagemPrecos( data ).then(
        response => {
          console.log("saveModelagemPrecos:", response.data)
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Dados salvos com sucesso.", floater: true})
          }
        },
        error => {
          console.error(error.response.data)
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar os dados enviados.", floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap {
    padding: $hmg_mid;

    >header {
      margin-bottom: $hmg_mid
    }

    .unidades {
      margin-bottom: $mg;
      position: relative;
      overflow: hidden;
      @extend %transition_4o;

      >header {
        margin-bottom: $hmg_mid
      }
    }
    .cursos {
      // padding-left: $hmg_mid;
      margin-bottom: $hmg_mid;

      @extend %transition_4o;
        overflow: hidden;
        position: relative;
        
        &.closed {
          height: 0;
          transform: scaleX(0);
          display: none;
          
          * {
            position: absolute;
          }
        }
    }
    
    .series {
      @extend %transition_4o;
      overflow: hidden;
      position: relative;

      &.closed {
        height: 0;
        transform: scaleX(0);
        display: none;
        
        * {
          position: absolute;
        }
      }
    }
    table {
      margin-top: $hmg_mid;

      .input-control {
        margin-left: 0 !important
      }

      .action {
        // padding-right: 0; 
        text-align: right;
        font-size: 16px;

        a {
          &:hover {
            color: $color-primary
          }
        }
      }
    }

    header {
      display: flex; align-items: center; align-content: center;
      justify-content: space-between;
      border-bottom: $border-component;
      padding: $mg_mini 0;

      h4, h5 {
        margin-bottom: 0;
        padding: 0;
      }
      h5{
        padding-left: $mg_small;
      }

      a {
        display: flex; align-items: center; align-content: center;
        gap: $mg_mini;
        line-height: 1;

        &:hover {
          color: $color-primary;
        }
      }

      >div {
        display: flex; align-items: center; align-content: center;
        justify-content: flex-end;
        gap: $hmg_mid;
        
        >div {
          display: flex; align-items: center; align-content: center;
          justify-content: flex-end;
          gap: $hmg_mini;
          
          label {
            margin: 0; padding: 0;
          }

          .input-control {
            width: 100px
          }
          .runButton {
            display: flex; flex-direction: column;
            align-content: center; align-items: center; justify-content: center;
            border-radius: 100%;
            background-color: $color-primary;
            width: 30px; height: 30px;
            flex-shrink: 0;
            color: $color-secondary
          }
        }
        
      }
    }

    .subtable {
      width: 100%;

      table {
        width: 100%; margin: 0;

        td, th {
          &:first-child {
            width: 20%
          }
        }
      }
    }
  }

  footer {
    display: block; width: 100%;
    position: sticky; bottom: 0;
    padding: $hmg_mid;
    border-top: $border-component;
    background-color: $color-bg;
  }

</style>