<template>
  <section class="declaracao" >
    <spinner />
    <toaster />
    <div class="closeWrap">
      <a href="#" class="btn btn-warn btPrint" @click.prevent="print()">Enviar por email &nbsp;<fa :icon="['far','paper-plane-top']" class="i"/></a>
      <a href="#" class="btn btn-warn btPrint print" @click.prevent="print()">Imprimir &nbsp;<fa :icon="['far','print']" class="i"/></a>
      <a href="#" class="btn btn-primary btClose" @click.prevent="close()">Fechar &nbsp;<fa :icon="['far','xmark']" class="i"/></a>
    </div>
    <span id="declaracao">
      <header class="pageHeader">
        <div>
          <logo class="logoRede" color="#ED1C24" size="80px"/>
          <div>
            <h5>{{ unidade.descricao }}</h5>
            <p>{{ unidade.endereco }}, {{ unidade.numero }} - {{ unidade.bairro }}</p>
            <p>{{ unidade.cidade }}/{{ unidade.uf }} - {{ maskCEP(unidade.cep) }}</p>
            <p>CNPJ: {{ maskCNPJ(unidade.cnpj) }} - rededecisao.com.br</p>
          </div>
        </div>
        <div>
          <p>{{ today() }}</p>
        </div>
      </header>
      <div class="intro">
        <h1>Declaração de Pagamentos</h1>
        <span class="customText" v-if="editDeclaracaoPagamento && !printMode && this.idPessoa">
          <textarea-control v-model="text" cssClass="customText" />
        </span>
        <p v-html="text" :class="['customTextShow', { custom: editDeclaracaoPagamento }]"></p>
      </div>
      <div class="table-wrap">
        <table v-if="data">
          <thead>
            <tr>
              <th>Aluno(a)</th>
              <th>Parcela</th>
              <th class="text-center">Vencimento</th>
              <th class="text-center">Valor</th>
              <th class="text-center">Pagamento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item">
              <td>{{ item.aluno }}</td>
              <td>{{ item.numero_parcela }}</td>
              <td class="text-center">{{ formatDate(item.data_vencimento) }}</td>
              <td class="text-center">{{ formatMoney(item.valor_pago) }}</td>
              <td class="text-center">{{ formatDate(item.data_pagamento) }}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td class="text-center"><b>{{ formatMoney(total) }}</b></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <footer>
        <p><span class="cid">{{ unidade.cidade.toLowerCase() }}</span>, {{ today('extenso') }}</p>
        <div>
          <img :src="require('@/assets/img/nelson-honda.jpg')" width="320"/>
          <p>Nelson Honda - Diretor Executivo Financeiro</p>
        </div>
        <p><b>Rede Decisão - {{ parseUnidade(unidade.descricao) }}</b><br/>CNPJ {{ maskCNPJ(unidade.cnpj) }}</p>
      </footer>
    </span>
  </section>
</template>

<script>
import { maskCNPJ, maskCEP, today, formatDate, formatMoney, capitalize } from '@/services/utils.service.js'
import Logo from '@/components/template/logo.vue'
import UserService from '@/services/user.service.js'
import FinanceiroService from '@/services/financeiro.service.js'

export default {
  props: {
    unidade: {
      type: Object
    },
    pessoa: {
      type: Object
    },
    scope: {
      type: Array
    },
    idPessoa: {
      type: [String, Number]
    }
  },
  components: {
    Logo
  },
  data () {
    return {
      data: null,
      total: 0,
      editDeclaracaoPagamento: false,
      text: null,
      printMode: false,
      dump: null
    }
  },
  mounted () {
    this.setup()

    // 
    this.editDeclaracaoPagamento = UserService.filterPermissions('editDeclaracaoPagamento')
  },
  methods: {
    setup () {
      const scope = Object.assign([], this.scope)
      this.data = scope.sort((a, b) => new Date(a.data_vencimento) - new Date(b.data_vencimento))
      
      this.total = this.scope.reduce((total, pagamento) => total + parseFloat(pagamento.valor_pago), 0)

      this.text = `Declaramos para os devidos fins que recebemos de <b>${ this.pessoa.dados.Basicos.nome }</b>, inscrito(a) no CPF/ME sob o n<sup>o</sup> <b>${ this.pessoa.dados.Documento.cpf }</b>, responsável financeiro(a) pelo(s) aluno(s) abaixo relacionado(s), os valores correspondentes ao pagamentos das parcelas, conforme especificado:`
      
      if ( !this.idPessoa ) {
        setTimeout(() => {
          this.print()
        },1000)
        
      }
    },
    maskCNPJ(cnpj) {
      return maskCNPJ(cnpj)
    },
    maskCEP ( zip ) {
      return maskCEP(zip)
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    today ( type ) {
      return today(type)
    },
    parseUnidade ( unidade ) {
      return unidade.split(" - ")[0]
    },
    close () {
      this.$emit('onClose')
    },
    print () {
      this.printMode = true
      
      setTimeout(() => {
        if ( window.innerWidth < 690 ) {
          this.sendEmail()
        } else {
          window.print()
          this.printMode = false
        }
      },500)
    },
    sendEmail () {
      let { descricao, endereco, numero, bairro, cidade, uf, cep, cnpj } = this.unidade

      const unidade = descricao
      cnpj = maskCNPJ(cnpj)
      cidade = capitalize(cidade)
      cep = maskCEP(cep)  

      const unidadeRede = {
        unidade,
        nomeUnidade: this.parseUnidade(unidade),
        endereco,
        numero,
        bairro,
        cidade,
        uf,
        cep,
        cnpj
      }

      const data = this.data.map( k => {
        return {
          aluno: capitalize(k.aluno.toLowerCase()),
          numero_parcela: k.numero_parcela,
          data_vencimento: formatDate(k.data_vencimento),
          valor_pago: formatMoney(k.valor_pago),
          data_pagamento: formatDate(k.data_pagamento),
        }
      })

      const dump = {
        hoje: today(),
        hojeExtenso: today('extenso'),
        unidade: unidadeRede,
        declaracao: this.text,
        data,
        total: formatMoney(this.total),
        email: this.pessoa.dados.Basicos.email
      }

      console.log("dump", dump)

      window.spinner.emit('open')
      FinanceiroService.sendDeclaracao( dump ).then(
        response => {
          window.spinner.emit('close')
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Email enviado com sucesso para: " + this.pessoa.dados.Basicos.email, floater: true})
          }
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível enviar o email com a declaração.", floater: true})
        }
      )
    },
    // applyComputedStyles(element) {
    //   const computedStyles = window.getComputedStyle(element)
    //   const inlineStyles = element.style

    //   for (let prop of computedStyles) {
    //     inlineStyles.setProperty(prop, computedStyles.getPropertyValue(prop))
    //   }

    //   // Apply styles recursively to all children
    //   for (let child of element.children) {
    //     this.applyComputedStyles(child)
    //   }
    // },
    // async printFullPage() {
    //   const element = document.getElementById("declaracao")
    //   const clonedElement = element.cloneNode(true)
    //   this.applyComputedStyles(clonedElement) // Apply inline styles

    //   const styleTag = document.createElement("style")
    //   styleTag.type = "text/css"
    //   styleTag.innerHTML = `
    //   #declaracao {
    //     position: relative;
    //     width: 100%;
    //     background-color: #FFF;
    //     padding: 50px;
    //   }

    //   @media screen and (max-width: 689px) {
    //     #declaracao {
    //       padding: 50px 20px 20px 20px;
    //     }
    //   }

    //   #declaracao .pageHeader {
    //     display: flex;
    //     width: 100%;
    //     justify-content: space-between;
    //     align-items: flex-start;
    //     line-height: 1.4;
    //     margin-bottom: 50px;
    //     padding: 0;
    //   }

    //   #declaracao .pageHeader > div {
    //     display: flex;
    //     gap: 20px;
    //     align-items: center;
    //   }

    //   #declaracao .pageHeader > div:last-child {
    //     text-align: right;
    //   }

    //   #declaracao .pageHeader p,
    //   #declaracao .pageHeader h5 {
    //     margin: 0;
    //     padding: 0;
    //   }

    //   #declaracao .intro {
    //     margin-bottom: 50px;
    //   }

    //   #declaracao .intro h1 {
    //     text-align: center;
    //     margin-bottom: 50px;
    //   }

    //   #declaracao .intro p {
    //     font-size: 16px;
    //   }

    //   #declaracao .table-wrap {
    //     overflow-x: auto;
    //   }

    //   #declaracao table {
    //     border: 1px solid #e3e3e9;
    //     border-collapse: collapse;
    //     width: 100%;
    //   }

    //   #declaracao table thead tr th {
    //     background-color: #F6F6F9;
    //     font-weight: bold;
    //     padding: 10px;
    //     border: 1px solid #e3e3e9;;
    //   }

    //   #declaracao table tbody tr td {
    //     padding: 10px;
    //     border: 1px solid #e3e3e9;
    //   }

    //   #declaracao footer {
    //     width: 400px;
    //     margin-top: 50px;
    //     font-size: 16px;
    //   }

    //   @media screen and (max-width: 689px) {
    //     #declaracao footer {
    //         width: 100%;
    //     }
    //   }

    //   #declaracao footer > div p {
    //     transform: translateY(-10px);
    //     padding-top: 10px;
    //     border-top:  1px solid #e3e3e9;
    //   }

    //   .cid {
    //     text-transform: capitalize;
    //   }

    //   .closeWrap {
    //     width: 100%;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     padding: 0 50px;
    //     gap: 20px;
    //     display: flex;
    //     justify-content: flex-end;
    //     z-index: 10001;
    //   }

    //   .closeWrap .btClose {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     border-bottom-left-radius: 14px;
    //     border-bottom-right-radius: 14px;
    //   }

    //   .closeWrap .btClose .i {
    //     font-size: 14px;
    //   }

    //   .closeWrap .btPrint {
    //     border: 0 !important;
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     border-bottom-left-radius: 14px;
    //     border-bottom-right-radius: 14px;
    //   }

    //   .closeWrap .btPrint:hover {
    //     border: 0 !important;
    //   }

    //   .customText {
    //     font-size: 16px !important;
    //     display: block;
    //     margin-bottom: 20px;
    //   }

    //   @media print {
    //     #declaracao {
    //         // page-break-inside: auto !important;
    //     }

    //     .customTextShow {
    //         visibility: visible;
    //     }

    //     .customTextShow.custom {
    //         visibility: visible;
    //     }

    //     .closeWrap,
    //     .customText {
    //         visibility: hidden;
    //         display: none !important;
    //     }

    //     table {
    //         break-inside: auto !important;
    //         page-break-inside: auto !important;
    //     }

    //     table thead {
    //         display: table-header-group;
    //     }

    //     table tbody {
    //         display: table-row-group;
    //     }

    //     table tr {
    //         page-break-inside: avoid !important;
    //     }

    //     table td,
    //     table th {
    //         page-break-inside: avoid !important;
    //         page-break-after: auto !important;
    //     }

    //     table th {
    //         -webkit-print-color-adjust: exact !important;
    //         print-color-adjust: exact !important;
    //         color: #444;
    //     }
    //   }
    //   `

    //   document.head.appendChild(styleTag)
    //   document.body.appendChild(clonedElement)

    //   html2pdf()
    //     .from(clonedElement)
    //     .set({
    //       margin: 22.5,
    //       filename: "declaracao.pdf",
    //       image: { type: "jpeg", quality: 1 },
    //       html2canvas: {
    //         scale: 3,
    //         useCORS: true, // Ensures images load properly
    //         logging: false,
    //         backgroundColor: null
    //       },
    //       jsPDF: {
    //         unit: "mm", format: "a4", orientation: "portrait",
    //         margins: { top: 15, left: 25, bottom: 15, right: 25 }
    //       },
    //     })
    //     .outputPdf('blob')
    //     .then((pdfBlob) => {
    //       // Create a download link
    //       const link = document.createElement("a")
    //       const blobUrl = URL.createObjectURL(pdfBlob)      

    //       link.href = blobUrl
    //       link.target = '_self'
    //       link.download = "declaracao.pdf"
    //       // link.setAttribute("download", "declaracao.pdf")
    //       // link.style.display = "none"
          
    //       // Append to body and trigger the download
    //       document.body.appendChild(link)
    //       link.click()
          
    //       setTimeout(() => {
    //         // Clean up
    //         document.body.removeChild(link)
    //         URL.revokeObjectURL(blobUrl)

    //         // Remove cloned elements
    //         document.body.removeChild(clonedElement)
    //         document.head.removeChild(styleTag)
    //         this.printMode = false
    //       },500)
          
    //     })
    // }
  }
}
</script>

<style lang="scss" scoped>
  .declaracao {
    position: relative;
    width: 100%;
    background-color: $color-secondary;
    padding: $mg;

    @media screen and ( max-width: 689px ) {
      padding: $mg $hmg_mid $hmg_mid $hmg_mid;
    }

    .pageHeader {
      display: flex;
      width: 100%; justify-content: space-between;
      align-items: flex-start; 
      line-height: 1.4;
      margin-bottom: 50px;
      padding: 0;

      >div {
        display: flex;
        gap: $hmg_mid;
        align-items: center;
        

        &:last-child {
          text-align: right;
        }
      }
      p,h5 {
        margin: 0; padding: 0;
      }

      @media screen and (max-width: 689px) {
        flex-direction: column-reverse;
        >div:last-child {
          margin-bottom: $hmg_mid;
        }
      }
    }

    .intro {
      margin-bottom: $mg;

      h1 {
        text-align: center;
        margin-bottom: $mg;
      }

      p {
        font-size: 16px
      }
    }

    .table-wrap {
      overflow-x: auto;
    }

    table {
      border: $border-component;
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          th{
            background-color: $color-bg;
            font-weight: bold;
            padding: $mg_mini;
            border: $border-component;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: $mg_mini;
            border: $border-component;
          }
        }
      }
    }

    footer {
      width: 400px;
      margin-top: $mg;
      font-size: 16px;

      @media screen and ( max-width: 689px ) {
        width: 100%;
      }

      >div {
        p {
          transform: translateY(-20px);
          padding-top: $mg_mini;
          border-top: $border-component;
        }
      }
    }
  }
  
  .cid {
    text-transform: capitalize;
  }

  .closeWrap {
    width: 100%;
    position: fixed; top: 0; left: 0;
    padding: 0 50px; gap: $hmg_mid;
    display: flex; justify-content: space-between;
    z-index: 10001;

    .btClose {
      border-top-left-radius: 0; border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius; border-bottom-right-radius: $border-radius;

      .i {
        font-size: 14px
      }
    }

    .btPrint {
      border: 0 !important;
      border-top-left-radius: 0; border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius; border-bottom-right-radius: $border-radius;

      &:hover{
        border: 0 !important;
      }

      &:not(.print) {
        display: none;
      }
      @media screen and ( max-width: 689px ) {
        &:not(.print) {
          display: flex;
        }

        &.print {
          display: none;
        }
      }
    }
  }
  
  .customText {
    font-size: 16px !important;
    display: block;
    margin-bottom: $hmg_mid;
  }
  
  @media print {
    .declaracao {
      // position: relative !important; /* Remove fixed position */
      // height: auto !important; /* Allow content to expand */
      // overflow: visible !important; /* Ensure the full content prints */
      page-break-inside: auto;
    }
    
    .customTextShow {
      visibility: visible;

      &.custom {
        visibility: visible;
      }
    }

    .closeWrap,
    .customText {
      visibility: hidden;
      display: none !important;
    }

    table {
      break-inside: auto;
      page-break-inside: auto;

      thead {
        display: table-header-group; /* Ensures the header is repeated on new pages */
      }

      tbody {
        display: table-row-group;
      }

      tr {
        page-break-inside: avoid; /* Prevent individual row breaking */
      }

      td, th {
        page-break-inside: avoid;
        page-break-after: auto;
      }

      th {
        -webkit-print-color-adjust: exact; /* Ensures colors print in WebKit browsers */
        print-color-adjust: exact;
        color: #444;
        // background-color: rgb(100,100,100);
      }
    }
  }
</style>




