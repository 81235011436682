<script setup>
  import UserService from  '@/services/user.service.js'
  import AcademicoService from '@/services/academico.service.js'
  import { createSelectOptions } from '@/services/utils.service.js'
  import FormSpinner from '@/components/utilities/FormSpinner.vue'
</script>

<template>
  <section>
    <ul class="itemList" v-if="locais">
      <li v-for="(item, i) in locais" :key="item">
        <card :blocked="false" :listed="false">
          <div class="listItemBody">
            <header>
              <div>
                <span class="sala">{{ item.saladeaula }}</span>
              </div>
              <div class="descricao">
                <p>{{ item.tipoDependencia }}</p>
                <h4>{{ item.descricao }}</h4>
              </div>
              <div>
                <p>{{ item.detalhes }}</p>
              </div>
              <div class="iconItem">
                <fa :icon="['fal', 'building']" class="icon" />
                <p>{{ item.predio }}</p>
              </div>
              <div class="iconItem">
                <fa :icon="['fal', 'arrows-up-down']" class="icon" />
                <p>{{ item.andar }}</p>
              </div>
              <div class="iconItem">
                <fa :icon="['fal', 'people-group']" class="icon" />
                <p>{{ item.capacidade }}</p>
              </div>
              <div class="toggle">
                <a href="#" @click.prevent="toggle(i)">
                  <fa :icon="['far', 'arrow-up-right-and-arrow-down-left-from-center']" />
                </a>
              </div>
            </header>
            <div class="listItemDetails" v-if="item.active">
              <div>
                <div class="row nop nom">
                  <div class="col-md-2 form-group">
                    <input-control label="Sala" v-model="item.saladeaula"/>
                  </div>
                  <div class="col-md-3 form-group">
                    <input-control label="Nome" v-model="item.descricao"/>
                  </div>
                  <div class="col-md-3 form-group">
                    <select-control label="Tipo" placeholder="Selecione" :options="options.tipo" v-model="item.tipoDependencia"/>
                  </div>
                  <div class="col-md-1 form-group">
                    <input-control label="Andar" v-model="item.andar"/>
                  </div>
                  <div class="col-md-3 form-group">
                    <input-control label="Prédio" v-model="item.predio"/>
                  </div>
                  <div class="col-md-12 form-group">
                    <textarea-control label="Detalhes" v-model="item.detalhes" />
                  </div>
                  <div class="col-md-6 form-group">
                    <select-control label="Unidade" placeholder="Selecione" :options="options.unidades" v-model="item.idUnidade"/>
                  </div>
                  <div class="col-md-2 form-group">
                    <input-control label="Área (m2)" v-model="item.areaDependencia"/>
                  </div>
                  <div class="col-md-2 form-group">
                    <input-control label="Capacidade" v-model="item.capacidade"/>
                  </div>
                  <!-- <div class="col-md-4 form-group">
                    <select-control label="Segmento" placeholder="Selecione" :options="options.cursos" v-model="item.idCurso"/>
                  </div> -->
                </div>
              </div>
              <div>
                <FormSpinner v-if="item.loading" />
                <div>
                  <header>
                    <h5>Segmentos Permitidos</h5>
                    <a href="#" class="btn btn-success-2" @click.prevent="toggleAddSegmento(i)">+ Adicionar Novo</a>
                  </header>
                  <div>
                    <ul class="segmentos">
                      <li v-for="(segmento, is) in item.segmentosPermitidos" :key="segmento">
                        <p>{{ segmento.descricao }}</p>
                        <div>
                          <!-- <p>Capacidade Pedagógica: <b>{{ segmento.capacidadePedagogica }}</b></p> -->
                          <a href="#" @click.prevent="toggleRemoveSegmento(i,is)">
                            <fa :icon="['fal', 'trash-can']" class="icon" />
                          </a>
                        </div>
                      </li>
                      <li v-if="item.createSegmento" class="formWrap">
                        <select-control v-model="item.segmentoNovo.idCurso" :options="item.cursos" placeholder="Selecione o Curso"/>
                        <div>
                          <!-- <label>Capacidade Pedagógica:</label> -->
                          <!-- <input-control type="number" v-model="item.segmentoNovo.capacidadePedagogica" style="width: 100px"/> -->
                          <div class="actions">
                            <a href="#" class="btn btn-secondary" @click.prevent="toggleAddSegmento(i, true)">Cancelar</a>
                            <a href="#" class="btn btn-primary" @click.prevent="addSegmento(i)">Adicionar</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <footer>
                <FormSpinner v-if="item.saving" />
                <div>
                  <a href="#" class="btn btn-primary" @click.prevent="saveLocal(i)">Salvar</a>
                </div>
              </footer>
            </div>
          </div>
        </card>
      </li>
    </ul>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  components: {
    FormSpinner
  },
  data () {
    return {
      locais: null,
      options: {
        unidades: [],
        cursos: [],
        tipo: [
          { label: 'Sala de Aula', value: 'Sala de Aula' },
          { label: 'Laboratório de Ciências', value: 'Laboratório de Ciências' },
          { label: 'Laboratório de Informática', value: 'Laboratório de Informática' },
          { label: 'Quadra', value: 'Quadra' },
          { label: 'Sala de Música', value: 'Sala de Música' },
          { label: 'Sala de Estudo', value: 'Sala de Estudo' },
          { label: 'Sala de Artes', value: 'Sala de Artes' },
          { label: 'Sala de Dança', value: 'Sala de Dança' },
          { label: 'Sala de Projetos', value: 'Sala de Projetos' },
          { label: 'Little Maker', value: 'Little Maker' },
          { label: 'Teatro', value: 'Teatro' },
          { label: 'Playground', value: 'Playground' },
          { label: 'Secretaria', value: 'Secretaria' },
          { label: 'Direção Coordenação', value: 'Direção Coordenação' },
          { label: 'Sala dos Professores', value: 'Sala dos Professores' },
          { label: 'Tatame', value: 'Tatame' },
          { label: 'Cantina', value: 'Cantina' },
          { label: 'Auditório', value: 'Auditório' },
          { label: 'Sala Descanso Integral', value: 'Sala Descanso Integral' },
          { label: 'Sala Hashtag', value: 'Sala Hashtag' },
          { label: 'Estoque', value: 'Estoque' },
          { label: 'Almoxarifado', value: 'Almoxarifado' },
          { label: 'Recreação', value: 'Recreação' },
          { label: 'Refeitório', value: 'Refeitório' },
          { label: 'Cozinha', value: 'Cozinha' },
          { label: 'Balcão de Atendimento', value: 'Balcão de Atendimento' }
        ]
      },
      wait: {
        unidades: false,
        cursos: false
      },
      cursos: null
    }
  },
  mounted () {
    this.set()
    this.getUnidades()
    this.getCursos()
    // console.log("scope", this.scope)
  },
  methods: {
    set () {
      this.locais = this.scope.map( k => {
        return {
          ...k,
          active: false,
          segmentosPermitidos: [],
          loading: false,
          createSegmento: false,
          segmentoNovo: null,
          cursos: null
        }
      })
    },
    toggle ( i ) {
      this.locais[i].active = !this.locais[i].active
      
      this.getLocaisSegmentos(this.locais[i].idLocal, i)
    },
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log('getUnidades:', response.data)
          if ( response.data ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade', 'descricao')
            window.spinner.emit('close')
            this.loading = false
          }
        },
        err => {
          console.log('err:', err)
          window.spinner.emit('close')
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            const cursos = response.data.filter( a => a.idTipoCurso != 5 ) 
            this.cursos = cursos
            this.options.cursos = createSelectOptions(cursos, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    filterCursos ( i ) {
      const idCursoSet = new Set(this.locais[i].segmentosPermitidos.map(item => parseFloat(item.idCurso)))
      console.log('idCursoSet:', idCursoSet)

      const filteredArray = this.cursos.filter( a => !idCursoSet.has(a.idCurso))
      console.log('filteredArray:', filteredArray)

      this.locais[i].cursos = createSelectOptions(filteredArray, 'idCurso', 'descricao')
    },
    getLocaisSegmentos ( idLocal, i ) {
      this.locais[i].loading = true
      AcademicoService.getLocaisSegmentos(idLocal).then(
        response => {
          this.locais[i].segmentosPermitidos = response.data.segmentosPermitidos
          this.locais[i].loading = false

          this.filterCursos(i)
        },
        error => {
          console.error(error.response.data)
          this.locais[i].loading = false
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.loading = false
      }

    },
    toggleRemoveSegmento ( i, is ) {
      const data = {
        i, is
      }
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este segmento?",
        confirmButton: "Sim",
        denyButton: "Cancelar",
        callback: this.removeSegmento,
        data: data,
        // backdropColor: 'blur'
      })
    },
    removeSegmento ( response, data ) {
      if ( response ) {
        const { i, is } = data
        this.locais[i].segmentosPermitidos.splice(is, 1)

        this.$nextTick(() => {
          this.filterCursos(i)
        })
      }
    },
    toggleAddSegmento ( i, close ) {
      if ( close ) {
        this.locais[i].createSegmento = false
        this.locais[i].segmentoNovo = null
      } else {
        this.filterCursos(i)
        this.locais[i].createSegmento = true
        this.locais[i].segmentoNovo = {
          idCurso: null,
          capacidadePedagogica: null,
          descricao: null
        }
      }
      
    },
    addSegmento ( i ) {
      const segmento = this.locais[i].segmentoNovo
      const valid = segmento.idCurso

      if ( valid ) {
        const descricao = this.options.cursos.filter( a => a.idCurso == segmento.idCurso)[0].descricao

        segmento.descricao = descricao
        // console.log("segmento:", segmento)
        this.locais[i].segmentosPermitidos.push(segmento)
        this.locais[i].cursos = this.locais[i].cursos.filter( a => a.idCurso == segmento.idCurso)
        
        this.$nextTick(() => {
          this.locais[i].createSegmento = false
          this.locais[i].segmentoNovo = null
        })
      } else {
        window.toaster.emit('open', {style: "error", message: "Para salvar, preencha todos os campos.", floater: true})
      }
      
    },
    saveLocal ( i ) {
      const data = this.locais[i]
      
      data.saving = true
      console.log("saveLocal:", data)

      AcademicoService.saveLocal( data ).then ( 
        response => {
          data.saving = false
          
          console.log('saveLocal - response:', response.data)
        },
        error => {
          console.error(error)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .itemList {
    padding: 0; margin: $mg 0 0 0;

    li {
      padding: 0; margin: 0 0 $mg_mini 0;
      list-style: none;
    }

    .listItemBody {
      > header {
        display: grid; width: 100%;
        padding: $hmg_small;
        align-items: center; align-content: center;
        grid-template-columns: .5fr 2.5fr 3fr 1fr 1fr 1fr .5fr;
      }

      .sala {
        display: flex;
        width: 40px; height: 40px;
        border-radius: 100%;
        border: $border-component;
        flex-shrink: 0;
        flex-direction: column; align-items: center; justify-content: center;
      }
      
      p, h4 {
        margin: 0; padding: 0;
      }

      .iconItem {
        display: flex; align-items: center;
        gap: $mg_mini;

        .icon {
          font-size: 18px
        }
      }

      .toggle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        color: $color-primary;
        padding-right: $hmg_mini;

        a {
          padding: $hmg_mini;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .listItemDetails {
      > div {
        border-top: $border-component;
        &:first-child {
          padding: $hmg_mid;
        }
        &:last-of-type {
          padding: 0;

          >div:not(.spinnerWrap) {
            padding: $hmg_mid;
          }
        }
        
      }

      footer {
        >div:last-child {
          padding: $hmg_mid;
          display: flex;
          justify-content: flex-end;
          border-top: $border-component
        }
      }

      header {
        display: flex; align-items: center;
        justify-content: space-between;

        h5 {
          padding: 0; margin: 0;
        }
      }

      .segmentos {
        display: block;
        padding: 0; margin: $hmg_mid 0 0 0;
        width: 100;

        li {
          display: flex; align-items: center; justify-content: space-between;
          width: 100%; gap: $hmg_mid;
          padding: $mg_mini $hmg_mid;
          border-radius: 2em;
          background-color: $color-bg;
          white-space: nowrap;

          p {
            margin: 0; padding: 0;
            
          }
          >div:not(.select-control) {
            display: flex; align-items: center;
            gap: $mg; flex: 0;

            label {
              margin: 0; padding: 0
            }
          }

          a {
            font-size: 14px;
            
            &:hover {
              .icon {
                color: $color-primary
              }
            }
          }

          &.formWrap {
            padding: $mg_mini;

            >div {
              gap: $hmg_mid;
            }
          }
        }
      }
    }
  }
</style>