<template>
  <div class="modal-controller" :class="{on: on, blur: backdropColor == 'blur'}" ref="modal">
    <transition name="slideup-small">
      <div :class="['modalContent', size]" v-if="on">
        <div :style="{textAlign: messageAlign}">
          <h3 v-html="message" :style="{textAlign: messageAlign}"></h3>
          <p v-if="obs" v-html="obs" :style="{textAlign: messageAlign}"></p>
        </div>
        <footer :class="messageAlign">
          <a href="#" class="btn btn-primary" @click.prevent="confirm()">{{ confirmButton }}</a>
          <a href="#" class="btn btn-secondary" @click.prevent="close()" v-if="denyButton">{{ denyButton }}</a>
        </footer>
      </div>
    </transition>
    <transition name="fadein">
      <div class="backdrop" v-if="on && backdrop" :style="{backgroundColor: backdropColor == 'blur' ?? '' }"></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "Confirm",
  props: {
    backdrop: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      on: false,
      confirmButton: "Sim",
      denyButton: "Não",
      message: null,
      obs: null,
      callback: null,
      backdropColor: "#F5F5F5",
      data: null,
      size: "small",
      preventCloseOnTrue: false,
      messageAlign: 'left'
    }
  },
  mounted () {

  },
  methods: {
    run (options) {
      console.log("confirm options:", options)

      if ( typeof options === "undefined" ) {
        console.error("Modal Error: Missing options...")
      } else {
        if ( typeof options.message === "undefined" ) {
          console.error("Modal Error: Missing message...")
        } else {
          this.message = options.message

          if ( typeof options.callback === "undefined" ) {
            console.error("Modal Error: Missing callback...")
          } else {
            this.callback = options.callback
            this.data = options.data
            this.obs = options.obs ? options.obs : null
            this.confirmButton = options.confirmButton ? options.confirmButton : this.confirmButton
            this.denyButton = options.denyButton ? options.denyButton : null
            this.size = options.size ? options.size : this.size
            this.preventCloseOnTrue = options.preventCloseOnTrue ? options.preventCloseOnTrue : this.preventCloseOnTrue
            this.backdropColor = options.backdropColor ?? this.backdropColor
            this.messageAlign = options.messageAlign ?? this.messageAlign
          }
        }
      }

      this.on = true

      this.$refs.modal.scrollIntoView({ behavior: "smooth" })

    },
    close () {
      this.on = false
      
      if (this.callback) this.callback(false)
    },
    confirm () {
      if (this.callback) this.callback(true, this.data)
      if ( !this.preventCloseOnTrue ) {
        this.on = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-controller {
    width: 100% !important; height: 100vh !important;
    position: fixed !important; top: 0 !important; left: 0 !important;
    z-index: 9000 !important;
    opacity: 0; pointer-events: none;

    &.blur {
      backdrop-filter: blur(10px);
    }

    .modalContent {
      display: flex; flex-direction: column;
      width: 90%; height: auto; max-height: 92vh;
      padding: $hmg_mid; margin: auto;
      background-color: $color-secondary; 
      border-radius: $border-radius-larger;
      position: absolute; top: 50%; left: 0; right: 0;
      z-index: 9000; transition-delay: .4s;
      transform: translateY(-70%); opacity: 0;
      border: $border-component;

      @extend %shadow-high-dark;
      @extend %transition_4e;
      
      @media screen and (max-width: 1024px) {
        // padding: 0;
        top: 5%;
      }
      @media screen and (max-width: 440px) {
        // padding: $hmg_mid;
        // max-height: calc(100vh - 15px); 
      }
      
      &.small {
        max-width: 320px;
        overflow-y: visible !important
      }
      &.mid {
        max-width: 540px;
      }
      &.normal {
        max-width: 640px;
      }
      &.large {
        max-width: 960px;
      }
      &.full {
        max-width: 100%;
      }

      h2 {
        // font-size: 64px; 
        color: $color-primary;
        // text-align: center;
      }
      p {
        font-size: 15px; margin-top: $mg_mini;
        width: 100%;
      }
      > div {
        width: 100%; height: 100%; max-height: 100%;
        overflow-y: auto; overflow-x: visible;
        @extend %scrollbar;

        > div {
          &:nth-child(1) {
            margin-bottom: 2em;
          }
          &:nth-child(2) {
            a {
              width: 100%;
              margin-top: 10px;
            }
          }
          &.modalMain {
            flex: 1;
            
            &.fullHeight {
              height: 100%
            }
          }
        }
      }

      &.fullHeight {
        height: calc(100vh - 50px);

        @media screen and (max-width: 1024px) {
          height: calc(100vh - 25px); 
        }

        > div {
          overflow-y: hidden;
        }
      }
    }

    .backdrop {
      display: block; width: 100%; height: 100%;
      background-color: $color-bg; opacity: .7;
      z-index: 8999;

      &.blur {
        background-color: $color-bg-transparent;
      }
    }

    &.on {
      opacity: 1; pointer-events: all;
      .modalContent {
        transform: translateY(-60%); 
        opacity: 1;
        top: 50%;
      }
    }
  }
  footer {
    display: flex; align-items: center;
    gap: $mg_mini;
    margin-top: $hmg_mid;
    padding: 0;

    &.center {
      justify-content: center;
    }
  }
</style>