<script setup>
  import FinanceiroService from '@/services/financeiro.service.js'
  import FormService from '@/services/form.service.js'
  import UserService from '@/services/user.service.js'
  import MatriculaService from '@/services/matricula.service.js'
  import { formatDate, formatMoney, createSelectOptions, checkValidationDate, maskCNPJ, maskCEP, today } from '@/services/utils.service.js'

  import MiniSpinner from '@/components/utilities/MiniSpinner.vue'
  import Modal from '@/components/utilities/Modal.vue'
  import InputSpinner from '@/components/utilities/InputSpinner.vue'
  import Success from '@/components/utilities/Success.vue'
  import Logo from '@/components/template/logo.vue'
  import DeclaracaoPagamento from '@/components/financeiro/extrato/template/DeclaracaoPagamento.vue'

  import moment from 'moment'
</script>
<template>
  <section :class="['extrato']">
    <template v-if="!lock">
      <spinner />
      <toaster />
      <div class="printHeader" v-if="unidade">
        <div>
          <logo class="logoRede" color="#ED1C24" size="80px"/>
          <div>
            <h5>{{ unidade.descricao }}</h5>
            <p>{{ unidade.endereco }}, {{ unidade.numero }} - {{ unidade.bairro }}</p>
            <p>{{ unidade.cidade }}/{{ unidade.uf }} - {{ maskCEP(unidade.cep) }}</p>
            <p>CNPJ: {{ maskCNPJ(unidade.cnpj) }} - rededecisao.com.br</p>
          </div>
        </div>
        <div>
          <p>{{ today() }}</p>
        </div>
      </div>
      <header class="frame ">
        <div>
          <h1>Extrato Financeiro</h1>
          <h4 class="pessoaPrint" v-if="pessoa">{{ pessoa.dados.Basicos.nome }} - {{ pessoa.dados.Documento.cpf }}</h4>
        </div>
        <div class="rangeFilterWrap">
          <h5>Período</h5>
          <div class="rangeFilter">
            <span>
              <select-control v-model="rangeFilter.value" placeholder="Selecione" :options="rangeFilter.options" @change="runRange" />
            </span>
            <span>
              <input-control v-model="range.inicio" type="date" />
            </span>
            <span>
              <input-control v-model="range.fim" type="date" />
            </span>
          </div>
          <div class="rangePrint">
            <span>
              <label>Período</label>
              <h3>{{ formatDate(range.inicio) }} - {{ formatDate(range.fim) }}</h3>
            </span>
          </div>
          <a href="#" @click.prevent="getFicha" class="btn btn-primary btFiltrar">Buscar</a>
        </div>
      </header>
      <template v-if="extrato">
        <div class="filterWrap frame">
          <nav class="filter">
            <h5>Filtrar</h5>
            <a href="#" @click.prevent="runFilter('all')" class="btn btn-secondary">
              <span>Todos</span>
              <fa :icon="['fas','check']" class="ic" v-if="filter == 'all'"/>
            </a>
            <a href="#" @click.prevent="runFilter('payed')" class="btn btn-success">
              <span>Pagos</span>
              <fa :icon="['fas','check']" class="ic" v-if="filter == 'payed'"/>
            </a>
            <a href="#" @click.prevent="runFilter('npayed')" class="btn btn-warn">
              <span>Pendentes</span>
              <fa :icon="['fas','check']" class="ic" v-if="filter == 'npayed'"/>
            </a>
            <!-- <a href="#" @click.prevent="runFilter('mens')" class="btn btn-success-2">
              <span>Mensalidades</span>
              <fa :icon="['fas','check']" class="ic" v-if="filter == 'npayed'"/>
            </a>
            <a href="#" @click.prevent="runFilter('shop')" class="btn btn-solicitacoes">
              <span>Shop Decisão</span>
              <fa :icon="['fas','check']" class="ic" v-if="filter == 'npayed'"/>
            </a> -->
            <div>
              <!-- <label>Aluno</label> -->
              <select-control v-model="aluno" :options="alunos" placeholder="Aluno" cssClass="medium" @change="runFilter('aluno')"/>
            </div>
          </nav>
          <div>
            <a href="#" class="btn btn-secondary" @click.prevent="togglePayDec">
              <fa :icon="['far','file-invoice-dollar']" class="i"/>&nbsp;
              Declaração de Pagamento
            </a>
            <a href="#" @click.prevent="print()" class="btnPrint">
              <fa :icon="['far','print']" class="ic"/>
            </a>
          </div>
          
        </div>
        <section class="frame extratoBody" v-for="(ano, ka) in extrato" :key="ka">
          <header>
            <fa :icon="['fas','calendar']" />
            <h2>{{ dropYear(ka) }}</h2>
          </header>
          <div v-for="(mes, km) in ano" :key="km" class="monthWrap">
            <header>
              <h4>{{ meses[km] }}</h4>
            </header>
            <table>
              <thead>
                <template v-if="mobile">
                  <tr v-for="head in mes" :key="head">
                    <th>Descrição</th>
                    <th>Aluno</th>
                    <th>Vencimento</th>
                    <th>Valor Original</th>
                    <th>Valor Corrigido</th>
                    <th>Status</th>
                    <th>Pagamento</th>
                    <th class="actions"></th>
                  </tr>
                </template>
                <tr v-else>
                  <th>Descrição</th>
                  <th>Aluno</th>
                  <th>Vencimento</th>
                  <th>Valor Original</th>
                  <th>Valor Corrigido</th>
                  <th>Status</th>
                  <th>Pagamento</th>
                  <th class="actions"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in mes" :key="item">
                  <tr v-if="item.active">
                    <td>{{ item.numero_parcela }}</td>
                    <td>{{ item.aluno }}</td>
                    <td>{{ item.data_vencimento ? formatDate(item.data_vencimento) : formatDate(item.data_pagamento) }}</td>
                    <td>{{ item.valor_titulo ? formatMoney(item.valor_titulo) : item.valor_titulo }}</td>
                    <td>{{ item.valor_corrigido ? formatMoney(item.valor_corrigido) : item.valor_corrigido }}</td>
                    <td><span :class="['stag', dropSituacaoTag(item.situacao)]">{{ item.situacao }}</span></td>
                    <td>{{ item.data_pagamento ? formatMoney(item.valor_pago) + ' em ' + formatDate(item.data_pagamento) : ' - ' }}</td>
                    <td class="actions" :ref="`item_${ka}_${km}_${i}`">
                      <mini-spinner v-if="item.waiting" />
                      <a href="#" v-if="item.situacao != 'Pago em dia' && item.situacao != 'Pago com atraso' && item.ocorrencia != ''">
                        <fa :icon="['fas', 'money-check-dollar-pen']" class="icon" @click.prevent="togglePay(item, ka, km, i, $event)"></fa>
                        <span></span>
                      </a>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </section>
      </template>
      <div v-if="payModal.active" class="payOptions" :style="{ top: payModal.y, left: payModal.x }">
        <a href="#" class="btClose" @click.prevent="closePayOptions()">
          <fa :icon="['far','xmark']" class="icon"/>
        </a>
        <nav>
          <a href="#" @click.prevent="copiarCodigo()" v-if="payModal.item.plataforma == 'Perseus'">
            <span>Copiar código de barras</span>
            <fa :icon="['fas', 'rectangle-barcode']" class="icon"/>
          </a>
          <a href="#" @click.prevent="copiarCodigo(true)" v-if="payModal.item.plataforma == 'Perseus' && payModal.item.pix_copia_cola">
            <span>Copiar código PIX</span>
            <fa :icon="['fas', 'qrcode']" class="icon"/>
          </a>
          <a :href="payModal.item.boleto" download target="_blank" v-if="payModal.item.plataforma == 'Apollo' && ( payModal.item.situacao == 'Pendente' || payModal.item.situacao == 'Atrasado' )">
            <span>Baixar Boleto</span>
            <fa :icon="['fas', 'barcode-read']" class="icon"/>
          </a>
          <a href="#" v-if="payModal.item.plataforma == 'Perseus' && payModal.item.pago != 'S' && payModal.item.ocorrencia != ''" @click.prevent="getBoletoPerseus()">
            <span>Baixar Boleto</span>
            <fa :icon="['fas', 'barcode-read']" class="icon"/>
          </a>
          <template v-if="payModal.item.situacao == 'Atrasado' && boleto3dias()">
            <a href="#" @click.prevent="getAtualizarBoleto()" >
              <span>Atualizar Boleto {{ payModal.item.plataforma == 'Perseus' ? 'no Banco' : '' }}</span>
              <fa :icon="['fas', 'scanner-keyboard']" class="icon" />
            </a>
            <a href="#" @click.prevent="getPagarComPix()">
              <span>Pagar com PIX</span>
              <fa :icon="['fas', 'qrcode']" class="icon"/>
            </a>
            <a href="#" @click.prevent="getPagarComCartao()">
              <span>Pagar com cartão</span>
              <fa :icon="['fas', 'credit-card']" class="icon"/>
            </a>
          </template>
        </nav>
      </div>
      <confirm 
        :backdrop="false"
        class="confirm"
        ref="confirm"
      ></confirm>
      <modal ref="barcode">
        <section class="barcodeModal">
          <div>
            <h3>Código de barras copiado!</h3>
            <p>O arquivo deste boleto não está disponível, mas não se preocupe, copiamos o código de barras para sua área de transferência.</p>
            <span v-if="currentBarcode">{{ currentBarcode }}</span>
          </div>
          <footer>
            <a href="#" @click.prevent="$refs.barcode.close()" class="btn btn-primary">Fechar</a>
          </footer>
        </section>
      </modal>
      <modal ref="modal">
        <input-spinner class="detailsSpinner" v-if="processing" />
        <div class="retorno row no-padd" v-else-if="retorno">
          <div class="col-md-12">
            <template v-if="retornoSucesso">
              <success></success>
              <h2 class="color-text">Tudo certo!</h2>
              <p v-if="atualizar">Boleto atualizado com sucesso!<br> Lembramos que seu boleto estará disponível após o registro bancário, que acontece em até 48h.</p>
              <p v-if="pagarCartao">Pagamento com cartão efetuado com sucesso!</p>
            </template>
            <template v-if="retornoErro">
              <fa :icon="['fal','face-meh']" class="icon"></fa>
              <h2 class="color-text">Ops. Algo deu errado.</h2>
              <p v-if="atualizar">Infelizmento não conseguimos atualizar seu boleto. Tente novamente. Se o erro persistir, procure nosso atendimento.</p>
              <p v-if="pagarCartao">Infelizmente não conseguimos processar seu pagamento.</p>
            </template>
            <div class="form-actions">
              <a href="#" class="btn btn-primary" @click.prevent="recallPagarComCartao()" v-if="pagarCartao && retornoErro">Tentar novamente</a>
              <a href="#" class="btn btn-primary" @click.prevent="recallAtualizarBoleto()" v-if="atualizar && retornoErro">Tentar novamente</a>
              <a href="#" class="btn btn-secondary" @click.prevent="closeModal()" v-if="retornoErro">Cancelar</a>
              <a href="#" class="btn btn-primary" @click.prevent="closeModal()" v-if="atualizar && retornoErro || retornoSucesso">Voltar para página</a>
            </div>
          </div>
        </div>
        <div v-else-if="codigoPix">
          <div class="pixModal">
            <div>
              <span class="codeWrap" @click.prevent="copyCode(codigoPix)">{{ codigoPix }}</span>
              <p>Esta parcela sera baixada automaticamente após a confirmação do pagamento.</p>
            </div>
            <footer>
              <a href="#" class="btn btn-primary" @click.prevent="clearPix(true)">Fechar</a>
            </footer>
          </div>
        </div>
        <div v-else>
          <div class="row no-padd" v-if="atualizar">
            <div class="col-md-12 form-group no-padd-left text-center" ref="atualizarForm">
              <h6 class="color-text" v-if="datasDisponiveis.length == 0">Não existem datas disponíveis para atualização deste boleto.</h6>
              <template v-else>
                <p>Escolha uma das datas disponíveis</p>
                <select-control name="datasDisponiveis" :options="datasDisponiveis" v-model="dataAtualizacao" placeholder="Selecione" cssClass="invert" :valid="validAtualizacao.datasDisponiveis" required/>
              </template>
            </div>
          </div>
          <div class="row no-padd" v-if="pagarCartao" ref="cardForm">
            <div class="form-group col-md-12 no-padd-left">
              <input-control type="text" placeholder="Número" v-model="cartao.numero" name="numero" :valid="validCartao.numero" @keyup="validCartao.numero = true" cssClass="invert" required/>
            </div>
            <div class="form-group col-md-12 no-padd-left">
              <input-control type="text" placeholder="Nome" v-model="cartao.nome" name="nome" :valid="validCartao.nome" @keyup="validCartao.nome = true" cssClass="invert" required/>
            </div>
            <div class="form-group col-6 no-padd-left">
              <input-control type="text" placeholder="Validade" v-model="cartao.validade" masking="validade_cartao" name="validade" :valid="validCartao.validade" @keyup="validCartao.validade = true; checkValidationDate" cssClass="invert" required/>
            </div>
            <div class="form-group col-6 no-padd-left">
              <input-control type="text" placeholder="CVV" v-model="cartao.cvv" masking="cvv" name="cvv" :valid="validCartao.cvv" @keyup="validCartao.cvv = true" cssClass="invert" required/>
            </div>
          </div>
          <div class="form-actions">
            <a href="#" class="btn btn-primary" @click.prevent="atualizarBoleto()" v-if="atualizar">Atualizar</a>
            <a href="#" class="btn btn-primary" @click.prevent="pagarComCartao()" v-if="pagarCartao">Pagar</a>
            <a href="#" class="btn btn-secondary" @click.prevent="closeModal()">Cancelar</a>
          </div>
        </div>
      </modal>
    </template>
    <declaracao-pagamento v-else-if="lock && declaracao && declaracaoData" :unidade="unidade" :idPessoa="idPessoa" :pessoa="pessoa" :scope="declaracaoData" @onClose="closeDeclaracao"/>
  </section>
</template>

<script>
export default {
  props: {
    idPessoa: {
      type: [Number, String]
    },
    declaracao: {
      type: String
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  components: {
    InputSpinner, Success, Logo, DeclaracaoPagamento
  },
  data () {
    return {
      scope: null,
      extrato: null,
      range: {
        inicio: '2024-01-01',
        fim: '2025-12-01',
      },
      rangeFilter: {
        value: 'personalizado',
        options: [
          { label: 'Personalizado', value: 'personalizado' },
          { label: 'Período Letivo Atual', value: 'periodo_atual' },
          { label: 'Período Letivo Anterior', value: 'periodo_anterior' },
          { label: 'Mês Atual', value: 'mes_atual' },
          { label: 'Mês Anterior', value: 'mes_anterior' }
        ]
      },
      filter: 'all',
      meses: ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio','Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      payModal: {
        y: 0,
        x: 0,
        active: false,
        item: null
      },
      processing: false,
      datasDisponiveis: [],
      dataAtualizacao: null,
      transacaoParaAtualizar: null,
      validAtualizacao: {
        dataAtualizacao: true
      },
      cartao: {
        numero: null,
        nome: null,
        validade: null,
        cvv: null
      },
      validCartao: {
        numero: true,
        nome: true,
        validade: true,
        cvv: true
      },
      pagarCartao: false,
      pagarPix: false,
      retorno: false,
      retornoSucesso: false,
      retornoErro: false,
      codigoPix: null,
      atualizar: false,
      unidade: null,
      pessoa: null,
      mobile: false,
      aluno: null,
      alunos: null,
      declaracaoData: null,
      lock: false
    }
  },
  mounted () {
    const finalDate = moment().add(2, 'months').endOf('month').format('YYYY-MM-DD')
    this.range.fim = finalDate

    this.getFicha()

    window.addEventListener('scroll', () => {
      this.closePayOptions()
    })

    window.addEventListener('resize', () => {
      this.trackMobile()
    })

    this.trackMobile()

    if ( this.declaracao && !this.declaracaoData ) {
      if ( this.idPessoa ) {
        this.$router.push({ name: 'Extrato Financeiro', params: { idPessoa: this.idPessoa } })
      } else {
        this.$router.push({ name: 'Extrato Financeiro' })
      }
    }
  },
  methods: {
    trackMobile () {
      this.mobile = window.innerWidth < 980
    },
    getFicha () {
      const idPessoa = this.idPessoa && this.idPessoa != 'declaracao' ? this.idPessoa : this.currentUser.idPessoa
      console.log("idPessoa:", idPessoa)

      const options = {
        idPessoa: idPessoa,
        ...this.range
      }

      window.spinner.emit('open')

      FinanceiroService.getFichaFinanceira(options).then(
        response => {
          if ( response.data.length > 0 ) {
            this.getUnidade(response.data[0].id_unidade)
            this.getPessoa()
            this.scope = response.data
            this.extrato = this.parseData(response.data)
          }
        },
        error => {
          console.error(error)
          window.spinner.emit('close')
        }
      )
    },
    parseData ( data ) {
      this.createAlunoFilter()

      const drop = data.reduce((acc, item) => {
        if ( item.data_vencimento || item.data_pagamento ) {
            let [year, month] = item.data_vencimento ? item.data_vencimento.split(" ")[0].split("-") : item.data_pagamento.split(" ")[0].split("-")

            month = parseFloat(month)
            year = year.toString()

            console.log("year", year)
            item.active = true

            if (!acc[year]) {
                acc[year] = {}
            }
            if (!acc[year][month]) {
                acc[year][month] = []
            }

            acc[year][month].push(item)
        }
        return acc
      }, {})

      console.log('drop:', drop)
      
      const sortedData = Object.keys(drop)
        .map(year => Number(year)) // Convert keys to numbers
        .sort((a, b) => b - a) // Sort in descending order
        .reduce((acc, year) => {
            acc[`'${ year }'`] = drop[year]
            return acc
        }, {})
      
      console.log('sortedData:', sortedData)

      return sortedData
    },
    getUnidade ( idUnidade ) {
      MatriculaService.getUnidades().then(
        response => {
          this.unidade = response.data.filter( a => a.idUnidade == idUnidade )[0]
          console.log("undidade:", this.unidade)
        },
        error => {
          console.error(error.response.data)
          window.spinner.emit('close')
        }
      )
    },
    getPessoa ()  {
      const idPessoa = this.idPessoa ? this.idPessoa : this.currentUser.idPessoa
      UserService.getPessoasByIdPessoa( idPessoa ).then(
        response => {
          window.spinner.emit('close')
          this.pessoa = response.data
        },
        error => {
          console.error(error.response.data)
          window.spinner.emit('close')
        }
      )
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    getBoletoPerseus () {
      const menCodigo = this.payModal.item.mencodigo

      console.log('getBoletoPerseus:', menCodigo)
      window.spinner.emit("open")
      // console.log("getBoletoPerseus:", menCodigo)
      UserService.getUrlBoletoPerseus(menCodigo).then(
        response => {
          const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[^\s]*)?$/i;
          if ( urlPattern.test(response.data) ) {
            const link = document.createElement('a')
            link.href = response.data
            link.download = `boleto-rededecisao-${ menCodigo }.pdf` 
            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)

          } else {
            const barcode = response.data.replace(/'/g, "")
            
            this.currentBarcode = barcode
            // console.log('barcode:', this.currentBarcode)

            navigator.clipboard.writeText(barcode).then(() => {
              const options = {
                size: "mid",
                backdrop: true,
                backdropColor: "transparent",
                backdropClose: true,
                padding: 0,
                onClose: () => {
                  // console.log("clear barcode...")
                  this.currentBarcode = null
                }
              }

              this.$refs.barcode.open(options)
            },() => {
              console.error('Failed to copy');
              /* Rejected - text failed to copy to the clipboard */
            })

          }

          this.closePayOptions()
          window.spinner.emit("close")
          // console.log("response.data", response.data)
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
        }
      )
    },
    dropSituacaoTag ( value ) {
      // console.log("dropSituacaoTag:", value)
      var tag

      // Atrasado,
      // Pago em dia
      // Em dia
      // Pago com atraso
      if ( value == "Pago em dia" ) tag = "pago"
      if ( value == "Atrasado" ) tag = "atrasado"
      if ( value == "Pago com atraso" ) tag = "pago-atrasado"

      return tag 
    },
    runRange () {
      const range = this.rangeFilter.value
      const year = moment().format('Y')
      const month = moment().format('M')

      if ( range == 'personalizado' ) {
        this.range.inicio = ''
        this.range.fim = ''
      }

      if ( range == 'periodo_atual' ) {
        this.range.inicio = year + '-01-01'
        this.range.fim = year + '-12-31'
      }

      if ( range == 'periodo_anterior' ) {
        const anoAnterior = parseInt(year) - 1
        this.range.inicio = anoAnterior + '-01-01'
        this.range.fim = anoAnterior + '-12-31'
      }

      if ( range == 'mes_atual' ) {
        const endOfMonth = moment().endOf('month').format('D')
        const mes = month < 10 ? '0' + month : month
        this.range.inicio = `${year}-${mes}-01`
        this.range.fim = `${year}-${mes}-${endOfMonth}`
      }

      if ( range == 'mes_anterior' ) {
        var mesAnterior = parseInt(month) - 1
            mesAnterior = mesAnterior < 10 ? '0' + mesAnterior : mesAnterior
        const endOfMonth = moment(mesAnterior,'M').endOf('month').format('D')

        this.range.inicio = `${year}-${mesAnterior}-01`
        this.range.fim = `${year}-${mesAnterior}-${endOfMonth}`
      }
    },
    atualizarBoleto () {
      const valid = FormService.validate(this.$refs.atualizarForm, this.validAtualizacao)
      
      if ( valid.response ) {
        const options = {
          boleto: this.payModal.item,
          data: this.dataAtualizacao.replace(/\D/g, '')
        }

        this.processing = true
        UserService.atualizarBoletoExtrato( options ).then(
          () => {
            // console.log('atualizarBoleto:',response.data)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = true
            this.retornoErro = false
          },
          () => {
            // console.error(error.response.message)
            this.processing = false
            this.retorno = true
            this.retornoErro = true
            this.retornoSucesso = false
            // window.toaster.emit('open', {style: "warning", message: "Não foi possível atualizar o boleto selecionado. Tente novamente mais tarde. Se o problema persistir entre em contato com nosso atendimento.", floater: true})
          }
        )
      }
    },
    getAtualizarBoleto () {
      const boleto = this.payModal.item

      if ( boleto.plataforma == "Apollo" ) {
        this.atualizar = true
        this.transacaoParaAtualizar = boleto
          
        const options = {
          title: 'Atualizar boleto',
          titleAlign: "center",
          // message: "Se quiser uma mensagem...", //não obrigatório
          size: "mid", //small, mid, large, full
          backdrop: true,
          backdropColor: "transparent",  //se o backdrop for false, não precisa
          backdropClose: false, //se o backdrop for false, não precisa
          fullHeight: false
        }
        
        this.$refs.modal.open(options)
        this.processing = true
        this.getAtualizacaoDatas(boleto)
      } else {
        // console.log("getAtualizarBoleto:", boleto)
        this.$refs.confirm.run({
          message: "Quer atualizar o boleto?",
          obs: "Copiamos o código de barras para área de transferência. Você será redirecionado para o site do banco para prosseguir com a atualização do seu boleto. Basta colar o código de barras no campo informado.",
          confirmButton: "Sim",
          denyButton: "Não",
          data: boleto,
          callback: this.atualizarBoletoBanco
        })
      }
    },
    fallbackCopy ( cod ) {
      // console.log("window.clipboardData:", window.clipboardData)
      const el = document.createElement('textarea')
      el.value = cod
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      
    },
    copiarCodigo ( pix ) {
      const data = this.payModal.item

      const barcode = pix ? data.pix_copia_cola : data.boleto

      if ( !navigator.clipboard ) {
          this.fallbackCopy(barcode)
        } else {
          navigator.clipboard.writeText(barcode).then(() => {
            window.toaster.emit('open', {style: "success", message: `Código ${ pix ? 'pix' : 'do boleto' } copiado com sucesso.`, floater: true})
          },() => {
            window.toaster.emit('open', {style: "success", message: `Ops. Algo aconteceu. Talvez não tenha sido possível copiar o código ${ pix ? 'pix.' : 'de barras.' }.`, floater: true})
            console.error('Failed to copy')
            this.fallbackCopy(barcode)
          })
        }
    },
    atualizarBoletoBanco ( response, data ) {
      if ( response ) {
        // console.log("data.atualizarBoleto:", data.MENLinhaDigitavel)

        const barcode = data.boleto
        const itau = '341'
        const santander = '033'

        const bankCode = barcode.substr(0,3)

        if ( !navigator.clipboard ) {
          this.fallbackCopy(barcode)
        } else {
          navigator.clipboard.writeText(barcode).then(() => {
            // console.log('Content copied to clipboard');
            const eventData = {
              'event_category': 'atualiza_boleto',
              'event_label': this.currentUser.email
            }

            // console.log("eventData:",eventData)
            this.$gtag.event('atualiza_boleto', eventData)

            if ( bankCode == itau ) {
              window.open("https://www.itau.com.br/servicos/boletos/segunda-via")
            } else if ( bankCode == santander ) {
              window.open("https://www.santander.com.br/2-via-boleto")
            }
            /* Resolved - text copied to clipboard successfully */
          },() => {
            console.error('Failed to copy');
            /* Rejected - text failed to copy to the clipboard */
          });
        }
      }
    },
    getPagarComPix () {
      const boleto = this.payModal.item
      // console.log("getPagarComPix:", boleto)
      this.pagarPix = true

      this.$refs.confirm.run({
        message: "Tem certeza que você quer pagar esse boleto com pix?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.pagarComPix,
        data: boleto,
        backdropColor: 'transparent'
      })
    },
    pagarComPix ( state, boleto ) {
      if ( state ) {
        window.spinner.emit("open")

        this.processing = true
        const options = {
          origem: boleto.plataforma == 'Perseus' ? 'perseus' : 'pagarMe',
          transacao: boleto.plataforma == 'Perseus' ? boleto.mencodigo : boleto.numero_titulo
        }

        UserService.pagarComPix( options ).then(
          response => {
            // console.log('pagarComPix:',response.data)
            window.spinner.emit("close")
            this.processing = false
            // this.retorno = true
            // this.retornoSucesso = true
            // this.retornoErro = false
            this.codigoPix = response.data[1].qrCodePix

            const options = {
              title: 'Pagar com pix',
              titleAlign: "center",
              message: "Clique sobre o código para copiar.", //não obrigatório
              size: "mid", //small, mid, large, full
              backdrop: true,
              backdropColor: "transparent",  //se o backdrop for false, não precisa
              backdropClose: false, //se o backdrop for false, não precisa
              fullHeight: false
            }
            
            this.$refs.modal.open(options)
            // window.toaster.emit('open', {style: "success", message: "Tudo certo! Seu pagamento foi concluído", floater: true})
          },
          error => {
            console.error(error.response.message)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = false
            this.retornoErro = true
            window.spinner.emit("close")
            // window.toaster.emit('open', {style: "warning", message: "Não foi possível atualizar o boleto selecionado. Tente novamente mais tarde. Se o problema persistir entre em contato com nosso atendimento.", floater: true})
          }
        )
      }
    
    },
    clearPix ( closeModal ) {
      this.codigoPix = null

      if ( closeModal ) {
        this.$refs.modal.close()
      }
    },
    getAtualizacaoDatas () {
      const boleto = this.payModal.item
      console.log("getAtualizacaoDatas:", boleto)

      UserService.getAtualizacaoDatasExtrato( boleto ).then(
        response => {
          console.log('getAtualizacaoDatas:', response.data)
          this.processing = false
          this.datasDisponiveis = createSelectOptions(response.data, 0, 0)
        },
        error => {
          console.error(error.response.message)
          this.processing = false
        }
      )
      
    },
    boleto3dias () {
      const boleto = this.payModal.item

      const date1 = moment()
      const date2 = moment(boleto.data_vencimento, 'YYYY-MM-DD')

      const differenceInDays = date1.diff(date2, 'days')

      return differenceInDays > 3
    },
    recallPagarComCartao () {
      this.pagarCartao = true
      this.retorno = false
      this.retornoError = false
      this.retornoSucesso = false
    },
    closeModal () {
      this.atualizar = false
      this.pagarCartao = false
      this.retorno = false
      this.retornoError = false
      this.retornoSucesso = false
      this.transacaoParaAtualizar = null
      this.dataAtualizacao = null
      this.cartao = {
        numero: null,
        nome: null,
        validade: null,
        cvv: null
      }
      this.$refs.modal.close()

      this.closePayOptions()
    },
    checkValidationDate (e) {
      checkValidationDate(e)
    },
    togglePay ( item, ka, km, i, e ) {

      const clientX = e.clientX
      const clientY = e.clientY

      const deslocX = window.innerWidth > 1300 ? 30 : -180
      const deslocY = window.innerWidth > 1300 ? 25 : 15

      const x = ( clientX + deslocX ) + 'px'
      const y = ( clientY - deslocY ) + 'px'
      
      this.payModal = {
        active: true,
        x: x,
        y: y,
        item: item
      }
    },
    closePayOptions() {
      this.payModal = {
        y: 0,
        x: 0,
        active: false,
        item: null
      }
    },
    getPagarComCartao () {
      const boleto = this.payModal.item

      this.pagarCartao = true
      this.transacaoParaAtualizar = boleto
      

      const options = {
        title: 'Pagar com cartão',
        titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "mid", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: false, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
      // this.getAtualizacaoDatas(boleto.transacao)
    },
    pagarComCartao () {
      const valid = FormService.validate(this.$refs.cardForm, this.validCartao)
      console.log("transacaoParaAtualizar:", this.transacaoParaAtualizar)

      if ( valid.response ) {
        const transacao = this.transacaoParaAtualizar.plataforma == 'Apollo' ? this.transacaoParaAtualizar.numero_titulo : this.transacaoParaAtualizar.mencodigo
        const origem = this.transacaoParaAtualizar.plataforma == 'Apollo' ? 'pagarMe' : 'perseus'

        const options = {
          origem: origem,
          idUnidade: this.transacaoParaAtualizar.id_unidade,
          transacao: transacao,
          idPessoa: this.currentUser.idPessoa,
          pagamento: {
            cartao: this.cartao
          }
        }

        this.processing = true
        UserService.pagarComCartao( options ).then(
          () => {
            // console.log('pagarComCartao:',response.data)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = true
            this.retornoErro = false
            // window.toaster.emit('open', {style: "success", message: "Tudo certo! Seu pagamento foi concluído", floater: true})
          },
          error => {
            console.error(error.response.message)
            this.processing = false
            this.retorno = true
            this.retornoSucesso = false
            this.retornoErro = true
            // window.toaster.emit('open', {style: "warning", message: "Não foi possível atualizar o boleto selecionado. Tente novamente mais tarde. Se o problema persistir entre em contato com nosso atendimento.", floater: true})
          }
        )
      }
    },
    runFilter ( target ) {
      console.log("target:", target)
      
      if ( target == 'all' ) {
        Object.values(this.extrato).forEach(monthItems => {
          console.log('monthItems:', monthItems)
          this.aluno = null
          Object.values(monthItems).forEach(list => {
            list.map( item => {
              console.log('item:', item)
              item.active = true
            })
          })
        })
      }

      if ( target == 'payed' ) {
        Object.values(this.extrato).forEach(monthItems => {
          Object.values(monthItems).forEach(list => {
            list.map( item => {
              console.log('item:', item)
              item.active = item.situacao == "Pago em dia" || item.situacao == 'Pago com atraso'
            })
          })
        })
      }

      if ( target == 'npayed' ) {
        Object.values(this.extrato).forEach(monthItems => {
          Object.values(monthItems).forEach(list => {
            list.map( item => {
              console.log('item:', item)
              item.active = item.situacao == "Atrasado" || item.situacao == 'Em dia'
            })
          })
        })
      }
      
      if ( target == 'aluno' ) {
        Object.values(this.extrato).forEach(monthItems => {
          Object.values(monthItems).forEach(list => {
            list.map( item => {
              console.log('item:', item)
              item.active = item.alumatricula == this.aluno
            })
          })
        })
      }

    },
    print() {
      window.print()
    },
    maskCEP ( zip ) {
      return maskCEP(zip)
    },
    maskCNPJ(cnpj) {
      return maskCNPJ(cnpj)
    },
    today () {
      return today()
    },
    dropYear ( year ) {
      return year.replace(/'/g, "") 
    },
    createAlunoFilter () {
      const copy = Object.assign([], this.scope)

      const uniqueSet = new Set()

      this.alunos = copy.map(item => ({
        label: item.aluno,
        value: item.alumatricula
      }))
      .filter(entry => entry.value !== undefined && !uniqueSet.has(entry.value) && uniqueSet.add(entry.value))

    },
    togglePayDec () {
      const data = Object.assign([], this.scope)
      
      this.lock = true
      this.declaracaoData = this.aluno ? 
                            data.filter( a => a.alumatricula == this.aluno && a.pago == 'S' && a.plataforma == 'Perseus' ) : 
                            data.filter( a => a.pago == 'S' && a.plataforma == 'Perseus' ) 
                            
      this.$router.push({ name: 'Extrato Financeiro', params: { idPessoa: this.idPessoa ?? 0, declaracao: 'declaracao' } })
    },
    closeDeclaracao () {
      this.lock = false
      this.declaracaoData = null
      this.$router.push({ name: 'Extrato Financeiro', params: { idPessoa: this.idPessoa ?? 0 } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .extrato {
    padding: $mg;

    &.lock {
      overflow-y: hidden;
    }

    @media screen and ( max-width: 980px ) {
      padding: $hmg_mid;
    }

    > header {
      padding: $hmg_mid 0;
      margin-bottom: $hmg_mid;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and ( max-width: 980px ) {
        flex-direction: column;
        align-items: flex-start;
      }

      >div:last-child {
        display: flex; align-items: center;
        justify-content: flex-end;
        gap: $mg_mini;

        @media screen and ( max-width: 980px ) {
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: $mg_mid;
        }
        
        h5 {
          margin: 0; padding: 0;

          @media screen and ( max-width: 980px ) {
            flex: 1; width: 100%
          }
        }

        >div {
          display: flex; align-items: center;
          justify-content: flex-end;
          gap: $mg_mini;

          > span {
            &:first-child {
              flex: 1; width: 44%
            }
            width: 28%;
          }

          @media screen and ( max-width: 980px ) {
            flex-wrap: wrap;

            > span {
              width: 40%;
              flex: 1;

              &:first-child {
                flex: 1; min-width: 100%
              }
            }
          }
        }
      }
    }

    .extratoBody {
      margin-bottom: $mg
    }
    > section {
      background-color: $color-secondary;
      border-radius: $border-radius;
      
      > header {
        border-bottom: $border-bg;
        padding: $hmg_mid;
        display: flex;
        align-items: center;
        gap: $hmg_mini;

        h2 {
          margin: 0; padding: 0;
        }
      }

      > div {
        padding: $hmg_mid;
        border-bottom: $border-bg;

        @media screen and ( max-width: 980px ) {
          padding: $hmg_mid;
        }
        
        >header {
          margin-bottom: $mg_mini;
        }

        &:last-child {
          border: 0
        }
      }
    }
    

    table {
      padding: 0; border: 0;
      border-collapse: collapse;
      width: 100%;

      @media screen and ( max-width: 980px ) {
        display: flex; align-items: flex-start;
      }
      

      thead {
        tr {
          @media screen and ( max-width: 980px ) {
            display: flex; flex-direction: column;
          }

          th {
            padding: $mg_mini;
            font-size: $font-size-small;

            &:first-child {
              // width: 15%;
              // padding-left: 0;
            }
            &:not(.actions),
            &:not(&:last-child) {
              width: 140px
            }
            @media screen and ( max-width: 980px ) {
              width: 100px !important;
              height: 40px; vertical-align: middle;
              padding-left: 0
            }
          }
        }
      }

      tbody {
        @media screen and ( max-width: 980px ) {
          flex: 1;
        }
        tr {
          @media screen and ( max-width: 980px ) {
            display: flex; flex-direction: column;
          }

          @extend %transition_3o;

          &:hover {
            background-color: $color-bg
          }

          td {
            padding: $mg_mini;
            font-size: $font-size-large;
            white-space: nowrap;

            &:first-child {
              // width: 15%;
              // padding-left: 0;
            }

            @media screen and ( max-width: 980px ) {
              height: 40px; vertical-align: middle;
              width: 100%
            }
          }
        }
      }
    }
  }

  .stag {
    padding: $hmg_mini $mg_mini;
    border-radius: 2em;
    margin-left: -$hmg_mini;

    &.atrasado {
      background-color: $color-error;
      color: $color-secondary;
    }
    &.pago {
      background-color: $color-success-2;
      color: $color-secondary;
    }
    &.pago-atrasado {
      background-color: $color-success;
      color: $color-secondary;
    }
  }

  .filterWrap {
    display: flex; align-items: center;
    justify-content: space-between;
    padding-bottom: $hmg;

    .filter {  
      display: flex; align-items: center;
      gap: $mg_mini;

      h5 {
        margin: 0; padding: 0;
      }

      >div {
        margin-left: $hmg_mid;
        display: flex; align-items: center;
        gap: $hmg_mini;

        label {
          margin: 0; padding: 0;
          font-weight: bold;
        }
      }

      @media screen and ( max-width: 980px ) {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        border-top: $border-component;
        padding-top: $hmg_mid;

        >div {
          margin-left: 52px;
        }
      }
    }

    .i {
      font-size: 16px
    }

    .btnPrint {
      display: flex; align-items: center;
      justify-content: center; flex-direction: column;
      border-radius: 100%; background-color: $color-secondary;
      width: 40px; height: 40px;

      .ic {
        font-size: 16px
      }

      &:hover {
        color: $color-primary
      }

      @media screen and ( max-width: 689px ) {
        display: none
      }
    }

    >div {
      display: flex;
      align-items: center;
      gap: $mg_mini;
      justify-content: flex-end;
    }

    @media screen and ( max-width: 980px ) {
      flex-direction: column;

      >div {
        width: 100%;
        margin-top: $hmg_mid; padding-top: $hmg_mid;
        border-top: $border-component;
        justify-content: space-between;
        gap: $hmg_mid;
      }
    }
  }
  

  .btn {
    .ic {
      margin-left: $mg_mini
    }
  }

  .actions {
    display: flex;
    align-items: center; align-content: center;
    justify-content: flex-end;
    padding-right: $hmg_mid !important;

    a {
      position: relative;
      font-size: 16px; margin-top: 1px;
      display: flex; flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      .icon {
        z-index: 2;
        position: relative;
        @extend %transition_3o;
      }

      > span {
        @extend %transition_4e;
        width: 40px; height: 40px;
        border-radius: 100%;
        z-index: 1;
        position: absolute;
        display: block;
        transform: translateX(-50%) translateY(-50%) scale(0.6);
        top: 50%; left: 50%;
      }

      &:hover {
        >span {
          background-color: $color-secondary;
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
        .icon {
          color: $color-primary;
        }
      }
    }
  }

  .payOptions {
    background: transparent;
    position: fixed; z-index: 2000;

    .btClose {
      display: flex; align-items: center;
      justify-content: center; flex-direction: column;
      position: absolute;
      right: -20px; top: -20px;
      width: 28px; height: 28px;
      border-radius: 100%;
      background-color: $color-secondary;

      &:hover {
        color: $color-primary
      }

      @media screen and ( max-width: 1300px ) {
        background-color: $color-bg;
      }
    }

    nav {
      width: 100%; position: relative;
      z-index: 1; overflow: hidden;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;

      a {
        display: flex; align-items: center;
        justify-content: space-between;
        padding: $mg_mini;
        border-bottom: $border-bg;
        gap: $hmg_mid;
        @extend %transition_3o;
        
        &:hover {
          background-color: $color-bg;
        }
        &:last-child {
          border: 0
        }
      }
    }
  }

  .printHeader {
    display: none;
    width: 100%; justify-content: space-between;
    align-items: flex-start; 
    line-height: 1.4;
    margin-bottom: 50px;
    padding: 0;

    >div {
      display: flex;
      gap: $hmg_mid;
      align-items: center;
      

      &:last-child {
        text-align: right;
      }
    }
    p,h5 {
      margin: 0; padding: 0;
    }
  }

  .rangePrint {
    display: none !important
  }

  .pessoaPrint { 
    display: none !important
  }

  @media print {
    body {
      padding: $hmg_mid;
    }
    // @page {
    //   // size: A4;
    //   margin: 2cm 1cm 1cm 1cm;

    //   @top-left {
    //     content: none;
    //   }

    //   @top-right {
    //     content: none;
    //   }

    //   @bottom-left {
    //     content: none;
    //   }

    //   @bottom-right {
    //     content: none;
    //   }
    // }
    .rangeFilter, .filterWrap, table .actions {
      display: none
    }

    .monthWrap {
      break-inside: avoid;
    }
    // .extrato {
    //   padding: 180px 0 0;
    // }
    .stag {
      color: $color-text !important
    }

    .printHeader {
      display: flex;
      top: 1cm;
      width: 100%;
    }

    .rangePrint, .pessoaPrint {
      display: block !important;
    }

    .rangeFilter,.btFiltrar {
      display: none !important
    }

    .rangeFilterWrap {
      h5 {
        display: none
      }
    }
  }
</style>