<script setup>
  import { formatMoney } from '@/services/utils.service.js'
</script>

<template>
  <controller>
    <page-header title="POC Precificação" />
    <section v-if="scope" class="frame wrap">
      <div v-for="(item, ki) in scope" :key="item" class="unidades">
        <header>
          <a href="#" @click.prevent="toggle(ki)">
            <h4>{{ ki }}</h4>
            <fa :icon="['fas','chevron-down']" />
          </a>
          <div>
            <label>Reajuste</label>
            <input-control v-model="item.reajuste" cssClass="small" />
            <a href="#" class="runButton">
              <fa :icon="['fas','chevron-right']" />
            </a>
          </div>
        </header>
        <div v-for="(curso, kc) in item.cursos" :key="curso" :class="['cursos', { closed: !item.active }]">
          <header>
            <a href="#" @click.prevent="toggle(ki, kc)">
              <h5>{{ kc }}</h5>
              <fa :icon="['fas','chevron-down']" />
            </a>
            <div>
              <label>Reajuste</label>
              <input-control v-model="curso.reajuste" cssClass="small" />
              <a href="#" class="runButton">
                <fa :icon="['fas','chevron-right']" />
              </a>
            </div>
          </header>
          <div :class="['series', { closed: !curso.active }]">
            <table class="transparent-table">
              <thead>
                <tr>
                  <th>Série/Turno</th>
                  <th>Preço de Face</th>
                  <th>Reajuste</th>
                  <th>Valor Final</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="serie in curso.series" :key="serie">
                  <td>{{ serie.serie }}</td>
                  <td>{{ formatMoney(serie.precoFace) }}</td>
                  <td><input-control v-model="serie.reajuste" cssClass="small" /></td>
                  <td><input-control v-model="serie.valorFinal" cssClass="small" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </controller>
</template>

<script>
export default {
  data () {
    return {
      scope: null
    }
  },
  mounted () {
    this.parseData()
  },
  methods: {
    parseData () {
      // const data = require('/public/files/precificacao.json')
      const data = {}
      this.scope = data.reduce((acc, item) => {
        if (!acc[item.sigla]) {
          acc[item.sigla] = {
            unidade: item.unidade,
            reajuste: null,
            sigla: item.sigla,
            endereco: item.endereco,
            active: true,
            cursos: {} // Object to group by curso
          }
        }

        // Ensure a group exists for this curso within the unidade
        if (!acc[item.sigla].cursos[item.curso]) {
          acc[item.sigla].cursos[item.curso] = {
            curso: item.curso,
            reajuste: null,
            active: true,
            series: [] // Array to store series grouped under this curso
          }
        }

        // Add the current series to the corresponding curso group
        acc[item.sigla].cursos[item.curso].series.push({
          idSerie: item.idSerie,
          serie: item.serie,
          precoFace: item.precoFace,
          reajuste: null,
          valor: null,
          active: true
        })

        return acc
      }, {})

      console.log("scope", this.scope)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    toggle ( target1, target2 ) {
      console.log("target1:", target1)
      console.log("target2:", target2)

      if ( target2 ) {
        const currentStatus = this.scope[target1].cursos[target2].active
        console.log("currentStatus:", currentStatus)

        this.scope[target1].cursos[target2].active = !this.scope[target1].cursos[target2].active 
      } else {
        const currentStatus = this.scope[target1].active
        console.log("currentStatus:", currentStatus)

        this.scope[target1].active = !this.scope[target1].active 
      }

    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap {
    padding: $hmg_mid;

    .unidades {
      margin-bottom: $mg;
      position: relative;
      overflow: hidden;
      @extend %transition_4o;

      >header {
        margin-bottom: $hmg_mid
      }
    }
    .cursos {
      // padding-left: $hmg_mid;
      margin-bottom: $hmg_mid;

      @extend %transition_4o;
        overflow: hidden;
        position: relative;
        
        &.closed {
          height: 0;
          transform: scaleX(0);
          display: none;
          
          * {
            position: absolute;
          }
        }
    }
    
    .series {
      @extend %transition_4o;
      overflow: hidden;
      position: relative;

      &.closed {
        height: 0;
        transform: scaleX(0);
        display: none;
        
        * {
          position: absolute;
        }
      }
    }
    table {
      margin-top: $hmg_mid;

      .input-control {
        margin-left: 0 !important
      }
    }

    header {
      display: flex; align-items: center; align-content: center;
      justify-content: space-between;
      border-bottom: $border-component;
      padding: $mg_mini 0;

      h4, h5 {
        margin-bottom: 0;
        padding: 0;
      }
      h5{
        padding-left: $mg_small;
      }

      a {
        display: flex; align-items: center; align-content: center;
        gap: $mg_mini;
        line-height: 1;

        &:hover {
          color: $color-primary;
        }
      }

      >div {
        display: flex; align-items: center; align-content: center;
        justify-content: flex-end;
        gap: $mg_mini;
        padding-right: $mg_small;
        
        label {
          margin: 0; padding: 0;
        }

        .runButton {
          display: flex; flex-direction: column;
          align-content: center; align-items: center; justify-content: center;
          border-radius: 100%;
          background-color: $color-primary;
          width: 30px; height: 30px;
          flex-shrink: 0;
          color: $color-secondary
        }
      }
    }
  }
</style>