<template>
  <section class="resultSidebar">
    <header>
      <h3>Projeção Faturamento</h3>
      <h5>{{ options.type == 'bruto' ? 'Bruto' : 'Líquido' }}</h5>
    </header>
    <div>
      <ul class="panel" v-if="reports">
        <panel v-for="item in reports" :key="item" :item="item" />
      </ul>
    </div>
  </section>
</template>

<script>
import Panel from '@/components/planejamentovendas/projecao/utilities/Panel.vue'
import PvService from '@/services/pv.service.js'

export default {
  props: {
    options: {
      type: Object
    }
  },
  components: {
    Panel
  },
  data () {
    return {
      reports: null,
      // reports: [
      //   {
      //     name: "Rede",
      //     tipo: 'rede',
      //     percentual: 96.38,
      //     valor: 182129598.27,
      //     meta: 222129598.27
      //   },
      //   {
      //     name: "Unidade",
      //     tipo: 'unidade',
      //     percentual: 13.42,
      //     valor: 12503302.13,
      //     meta: 20503302.27
      //   },
      //   {
      //     name: "Série",
      //     tipo: 'serie',
      //     percentual: 2.2,
      //     valor: 849598.97,
      //     meta: 969598.27
      //   },
      // ]
    }
  },
  mounted () {
    console.log("options:", this.options)
    if ( this.options ) {
      this.setup()
    }
  },
  methods: {
    setup () {
      if ( this.options.type == 'bruto' ) {
        this.getSidebarBruto()
      }
      if ( this.options.type == 'liquido' ) {
        this.getSidebarLiquido()
      }
    },
    getSidebarBruto () {
      const { unidade, periodoLetivo } = this.options
      const data = {
        unidade,
        periodoLetivo
      }
      console.log('data:', data)
      PvService.getSidebarBruto( data ).then(
        response => {
          console.log("getSidebarBruto:", response.data)
          this.build(response.data)
        },
        error => {
          console.error(error)
        }
      )
    },
    getSidebarLiquido () {

    },
    build ( data ) {
      console.log(data)
      var sidebar = []

      if ( data.periodoLetivo ) {
        sidebar.push({
          name: "Rede",
          tipo: 'rede',
          valor: data.periodoLetivo.totalProjetado
        })
      }
      if ( data.unidade ) {
        sidebar.push({
          name: "Unidade",
          tipo: 'unidade',
          percentual: data.unidade.percentual,
          valor: data.unidade.totalProjetado
        })
      }
      if ( data.serie ) {
        sidebar.push({
          name: "Série",
          tipo: 'serie',
          percentual: data.serie.percentual,
          valor: data.serie.totalProjetado
        })
      }

      this.reports = sidebar
    }
  }
}
</script>

<style lang="scss" scoped>
  .resultSidebar {
    position: sticky;
    top: 0;
    
    header {
      width: 100%; padding: $hmg_mid;
      border-bottom: $border-component;
      
      h3,h5 {
        margin: 0; padding: 0;
      }
      h3 {
        font-weight: bold;
      }
    }
    
    >div {
      padding: $hmg_mid;

      ul {
        padding: 0; margin: 0;
      }
    }
  }
</style>