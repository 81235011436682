
<script setup>
  import UserService from '@/services/user.service.js'
  import AcademicoService from '@/services/academico.service.js'
  import FormService from '@/services/form.service.js'

  import { createSelectOptions } from '@/services/utils.service.js'
</script>
<template>
  <header class="filter">
    <div class="" ref="filter">
      <div class="form-group">
        <select-control 
          :options="options.unidades" 
          placeholder="Selecione" 
          v-model="filter.idUnidade" 
          label="Unidade"
          :disabled="loadingFilterData"
          name="idUnidade"
          @change="getPeriodosLetivos(); valid.idUnidade = true"
          :valid="valid.idUnidade"
          required
        />
      </div>
      <div class="form-group">
        <select-control 
          :options="options.periodosLetivos" 
          placeholder="Selecione" 
          v-model="filter.idPeriodoLetivo" 
          label="Período Letivo"
          name="idPeriodoLetivo"
          :disabled="loadingFilterData"
          :valid="valid.idPeriodoLetivo"
          @change="setPeriodoLetivo(); valid.idPeriodoLetivo = true"
          required
        />
      </div>
      <div class="form-group actionWrap">
        <a href="#" class="btn btn-primary" @click.prevent="runFilter">Filtrar</a>
      </div>
    </div>
    <div v-if="allowSearch">
      <div class="form-group">
        <div class="switchWrapMini">
          <switch-control v-model:checked="filter.active" labelOn="Apenas Ativos" labelOff="Todos" labelPos="esq" invert @change="toggleActive"/>
        </div>
      </div>
      <div class="form-group">
        <input-control v-model="filter.query" placeholder="O que você procura?" @keyup="search" search preventSearchButton />
      </div>
      <div class="form-group">
        <a href="#" class="btn btn-secondary" @click.prevent="clearSearch">Limpar</a>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  props: {
    allowSearch: {
      type: Boolean
    }
  },
  data () {
    return {
      options: {
        unidades: null,
        periodosLetivos: null
      },
      filter: {
        idUnidade: null,
        idPeriodoLetivo: null,
        periodoLetivo: null,
        active: false,
        query: null
      },
      valid: {
        idUnidade: true,
        idPeriodoLetivo: true
      },
      loadingFilterData: false,
      searchWait: null
    }
  },
  mounted () {
    this.getUnidades()
  },
  methods: {
    getUnidades () {
      this.loadingFilterData = true
      UserService.getUnidades().then(
        response => {
          console.log("getUnidades:", response.data)
          // this.wait.unidades = true
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.loadingFilterData = false
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getPeriodosLetivos () {
      this.loadingFilterData = true
      AcademicoService.getPeriodosLetivos( this.filter.idUnidade ).then(
        response => {
          this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          this.loadingFilterData = false
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    setPeriodoLetivo () {
      console.log("periodosLetivos", this.options.periodosLetivos)

      const periodoLetivo = this.options.periodosLetivos.filter(a => a.idPeriodoLetivo == this.filter.idPeriodoLetivo)
      this.filter.periodoLetivo = periodoLetivo[0].descricao

      console.log("setPeriodoLetivo", periodoLetivo)
    },
    runFilter () {
      const formValid = FormService.validate(this.$refs.filter, this.valid)

      if ( formValid.response ) {
        this.$emit('onFilter', this.filter)
      } else {
        window.toaster.emit('open', {style: "error", message: "Antes de filtrar, preencha todos os campos obrigatórios.", floater: true})
      }
    },
    toggleActive () {
      this.$emit('onToggleActive', this.filter.active)
    },
    search () {
      if ( this.filter.query && this.filter.query.length > 0 ) {
        if ( this.searchWait ) clearTimeout(this.searchWait)

        this.searchWait = setTimeout(() => {
          this.$emit('onSearch', this.filter.query)
        }, 300)
      }
    },
    clearSearch () {
      this.filter.query = ""
      this.$emit('onSearch', this.filter.query)
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter {
    display: flex; align-items: flex-end;
    justify-content: space-between;
    padding: $hmg_mid;

    > div {
      display: flex; align-items: center;
      gap: $mg_mini;

      &:first-child {
        > div {
          &:first-child {
            width: 40%; min-width: 295px;
          }
        }
      }
      &:last-child {
        > div {
          &:nth-child(2) {
            width: 50%; min-width: 240px;
          }
        }
      }

      .actionWrap {
        margin-top: $hmg_mid
      }
    }
  }
</style>