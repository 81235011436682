<template>
  <section class="descontos">
    <form-spinner v-if="loading" />
    <ul v-if="descontos">
      <li v-for="(item, i) in descontos" :key="item">
        <header>
          <h5>{{ item.nome }}</h5>
          <div>
            <span>{{ item.curso }}</span>
            <span>
              <fa :icon="['fal','screen-users']" class="icon" />
              <p>{{ item.turma }}</p>
            </span>
            <span>
              <fa :icon="['fal','school-flag']" class="icon" />
              <p>{{ item.unidade }}</p>
            </span>
            <span>
              <a href="#" @click.prevent="toggleActive(i)">
                <fa :icon="['far','arrow-up-right-and-arrow-down-left-from-center']" class="icon"/>
              </a>
            </span>
          </div>
        </header>
        <div>
          <table>
            <thead>
              <tr>
                <th>Valor Bruto</th>
                <th>Desconto Original (%)</th>
                <th>Valor Desconto Original</th>
                <th>Valor Líquido Original</th>
                <th>Valor Período Anterior</th>
                <th>Reajuste (%)</th>
                <th>Valor Reajuste</th>
                <th>Limites (%)</th>
                <th>Valor Líquido Final</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ formatMoney(item.valorBruto) }}
                </td>
                <td>
                  {{ item.descontoOriginal }}
                </td>
                <td>
                  {{ formatMoney(item.valorDescontoOriginal) }}
                </td>
                <td>
                  {{ formatMoney(item.valorLiquidoOriginal) }}
                </td>
                <td>
                  <span :class="['arrowTag', { over: item.reajuste < item.reajusteFinalMin, under: item.reajuste > item.reajusteFinalMax }]">
                    <fa :icon="['fas', 'chevrons-right']" />
                  </span>
                  {{ formatMoney(item.valorPeriodoAnterior) }}
                </td>
                <td :class="['arrowCell', { over: item.reajuste < item.reajusteFinalMin, under: item.reajuste > item.reajusteFinalMax }]">
                  <div>
                    <span>{{ item.reajuste }}</span>
                    <span :class="[{ arrowDown: item.reajuste < item.reajusteFinalMin, arrowUp: item.reajuste > item.reajusteFinalMax }]"></span>
                  </div>
                </td>
                <td>
                  {{ formatMoney(item.valorReajuste) }}
                </td>
                <td>
                  {{  item.reajusteFinalMin }} - {{ item.reajusteFinalMax }}
                </td>
                <td :class="{ over: item.reajuste < item.reajusteFinalMin, under: item.reajuste > item.reajusteFinalMax }">
                  <span :class="['arrowTag', { over: item.reajuste < item.reajusteFinalMin, under: item.reajuste > item.reajusteFinalMax }]">
                    <fa :icon="['fas', 'chevrons-right']" />
                  </span>
                  {{ formatMoney(item.valorLiquidoFinal) }}
                </td>
              </tr>
            </tbody>

            <template v-if="item.active">
              <thead>
                <tr>
                  <th></th>
                  <th>Desconto Original (%)</th>
                  <th>Valor Desconto Original</th>
                  <th colspan="2"></th>
                  <th>Reajuste Desconto (%)</th>
                  <th>Valor Reajuste</th>
                  <th>Ajuste Desconto</th>
                  <th>Valor Liquido do Desconto</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr class="subHeader">
                  <td colspan="9">
                    <h4>Entrada: R$ 1.903,84</h4>
                  </td>
                </tr> -->
                <tr v-if="item.loading" class="spinner">
                  <td colspan="9">
                    <form-spinner />
                  </td>
                </tr>
                <template v-if="item.descontos">
                  <tr v-for="(desconto, di) in item.descontos" :key="desconto">
                    <td>{{ desconto.descricaoTipoDesconto }}</td>
                    <td>{{ desconto.percentualModelo }}</td>
                    <td>{{ formatMoney(desconto.valorBruto) }}</td>
                    <td colspan="2"></td>
                    <td>{{ desconto.percentualAjustado }}</td>
                    <td>{{ formatMoney(desconto.valorLiquido) }}</td>
                    <td>
                      <input-control placeholder="0" cssClass="small text-center" v-model="desconto.ajusteDescontoManual" @keyup="recalc(i, di)"/>
                    </td>
                    <td>{{ formatMoney(desconto.valorLiquidoDescontoManual) }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </table>
        </div>
      </li>
    </ul>
    <footer v-if="descontos">
      <a href="#" class="btn btn-primary" @click.prevent="save">Salvar</a>
    </footer>
  </section>
</template>

<script>
import { formatMoney } from '@/services/utils.service.js'
import PvService from '@/services/pv.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.setup()
      }
    }
  },
  data () {
    return {
      descontos: null,
      wait: null,
      loading: false
    }
  },
  mounted () {
    if ( this.scope ) {
      this.setup()
    }
    
  },
  methods: {
    setup () {
      this.descontos = this.scope.map( k => {
        return {
          active: false,
          visible: true,
          loading: false,
          ...k
        }
      })

      // console.log("descontos:", this.descontos)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    toggleActive ( i ) {
      this.descontos[i].active = !this.descontos[i].active

      if ( this.descontos[i].active ) {
        this.getDetails(this.descontos[i].idmodelagemProposta, i)
      }
    },
    getDetails ( id, i ) {
      this.descontos[i].loading = true
      PvService.getDescontosPorAlunoDetails( id ).then( 
        response => {
          this.descontos[i].descontos = response.data
          console.log(response.data )
          this.descontos[i].loading = false
        },
        error => {
          this.descontos[i].loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não consigamos carregar os dados solicitados.", floater: true})
          console.error(error)
        }
      )
    },
    recalc ( i, di ) {
      if ( this.wait ) clearTimeout(this.wait)

      this.wait = setTimeout( () => {
        const { valorBruto, valorLiquidoFinal, valorPeriodoAnterior } = this.descontos[i]
        const { valorFinal, ajusteDescontoManual } = this.descontos[i].descontos[di]

        const valorCorrigido = ( ( valorFinal / 13 ) * 12 ) * ( ajusteDescontoManual / 100 )

        this.descontos[i].descontos[di].valorLiquidoDescontoManual = valorCorrigido
        
        const totalFinal = valorBruto - valorCorrigido
        
        this.descontos[i].memoriaValor = valorLiquidoFinal * 1
        this.descontos[i].valorLiquidoFinal = totalFinal
        
        this.descontos[i].memoriaReajuste = this.descontos[i].reajuste * 1
        const novoReajuste = ( ( ( totalFinal - valorPeriodoAnterior ) / valorPeriodoAnterior ) * 100 ).toFixed(3)
        this.descontos[i].reajuste = novoReajuste

        this.descontos[i].memoriaValorReajuste = this.descontos[i].valorReajuste * 1
        this.descontos[i].valorReajuste = totalFinal * ( novoReajuste / 100 )
      }, 300)
    },
    save () {
      // console.log("save:", this.descontos)
      window.pagescroll.emit("scrollTop")
      this.loading = true
      PvService.saveDescontosPorAluno(this.descontos).then(
        response => {
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Os dados foram salvos com sucesso.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar as informações enviadas.", floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .descontos {
    ul {
      padding: $hmg_mid; margin: 0;

      li {
        list-style: none;
        padding: 0; margin: 0 0 $hmg_mid 0;
        border: $border-component;
        border-radius: $border-radius;
        background-color: $color-secondary;

        header {
          display: flex;
          align-items: center; justify-content: space-between;
          width: 100%; padding: $hmg_small $hmg_mid;
          // border-bottom: $border-component;

          h5, p {
            padding: 0; margin: 0;
          }
          
          >div {
            display: flex;
            align-items: center;
            gap: $mg;
            
            >span {
              display: flex;
              align-items: center;
              gap: $mg_mini;
              font-size: $font-size-large;

              .icon {
                font-size: 16px;
              }
              &:last-child {
                text-align: right;
                margin-left: $hmg_mid;
              }
              
              a {
                color: $color-primary;
              }
            }
          }
        }

        table {
          width: 100%; border-collapse: collapse;
          overflow: hidden;
          table-layout: fixed;

          thead {
            border-top: $border-component;

            tr {
              height: 50px;
              
              th {
                font-size: $font-size-small;
                padding: 0 $hmg_mid;
                text-align: center;

                &:first-child {
                  width: 15%;
                  min-width: 228px;
                  text-align: left;
                }
              }
            }
          }
          tbody {
            overflow: hidden;
            border-top: $border-component;

            tr {
              height: 50px;
              overflow: hidden;

              td {
                border: $border-component;
                padding: 0 $hmg_mid;
                position: relative;
                text-align: center;
                z-index: 8;
                
                &:first-child {
                  border-left: none !important;
                  text-align: left;
                }
                &:last-child {
                  border-right: none !important;
                }

                &.over {
                  background-color: $color-debito;
                }
                &.under {
                  background-color: $color-credito;
                }

                .arrowTag {
                  display: flex; flex-direction: column;
                  justify-content: center; align-items: center;
                  position: absolute; top: 50%; left: 0;
                  transform: translate(-50%, -50%);
                  width: 21px; height: 21px;
                  border-radius: 100%; border: $border-component;
                  font-size: 10px;
                  z-index: 12;
                  color: $color-text; background-color: $color-secondary;

                  &.over {
                    color: $color-primary;
                  }

                  &.under {
                    color: $color-success-2;
                  }
                }

                &.arrowCell {
                  >div {
                    display: flex; align-items: center; justify-content: center;
                    gap: $hmg_mini
                  }
                }
              }

              &.spinner {
                height: auto;

                td {
                  background-color: $color-secondary;
                  padding: 0 !important;
                }
              }
              &.subHeader {
                height: 40px;
                background-color: $color-bg;

                h4 {
                  margin: 0; padding: 0;
                }
              }

              &:last-child {
                td {
                  border-bottom: none !important;

                  &:last-child {
                    border-right: none !important;
                    border-bottom-right-radius: $border-radius;
                  }
                }
              }
            }
          }
        }
      }
    }

    footer {
      position: sticky; bottom: 0;
      width: 100%;
      padding: $hmg_mid;
      background-color: $color-bg;
      border-top: $border-component;
      z-index: 1000;
    }
  }

  .arrowDown{
    width: 0; height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid $color-primary;
    margin-bottom: 1px;
  }
  .arrowUp {
    width: 0; height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid $color-success-2;
    margin-bottom: 1px;
  }
</style>