<script setup>
  import AcademicoService from  '@/services/academico.service.js'
  import MiniSpinner from '@/components/utilities/MiniSpinner.vue'
  import { createSelectOptions } from '@/services/utils.service.js'
</script>

<template>
  <section class="table-wrap">
    <header>
      <h4>Resultado da Busca</h4>
      <p v-if="turmas">{{ turmas.length }} {{ turmas.length == 1 ? 'item encontrado' : 'itens encontrados' }} </p>
    </header>
    <table v-if="turmas" class="transparent-table ">
      <thead>
        <tr>
          <th>ID Turma</th>
          <th>TURCódigo</th>
          <th>Descrição</th>
          <th>Detalhes</th>
          <th>Série</th>
          <th>Capacidade</th>
          <th>Vagas</th>
          <th>Inscritos</th>
          <th>Local</th>
          <th>Ativo</th>
          <th>Alertas</th>
          <th class="text-right">
            <label class="check-control">
              <span>Selecionar Todos</span>
              <input type="checkbox" class="check" v-model="all" @change="toggleAll()">
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(turma,i) in turmas" :key="turma">
          <td>
            {{ turma.idTurma }}
          </td>
          <td>
            {{ turma.TURCodigo }}
          </td>
          <td>
            {{ turma.descricao ? turma.descricao : '-' }}
          </td>
          <td>
            <input-control v-model="turma.detalhes" cssClass="small invert" @change="toggleChange(i)"></input-control>
          </td>
          <td>
            {{ turma.descricaoSerie }}
          </td>
          <td>
            <input-control v-model="turma.capacidade" cssClass="small invert table-input" @change="toggleChange(i)"></input-control>
          </td>
          <td>
            {{ turma.vagas }}
            <!-- <input-control v-model="turma.vagas" cssClass="small invert table-input"></input-control> -->
          </td>
          <td>
            {{ turma.inscritos }}
            <!-- <input-control v-model="turma.inscritos" cssClass="small invert table-input"></input-control> -->
          </td>
          <td>
            <select-control cssClass="small" placeholder="Selecione" :options="turma.locais" v-model="turma.idLocal" :disabled="turma.locais.length == 0" v-if="turma.locais" @change="toggleChange(i)"/>
            <mini-spinner v-else />
          </td>
          <td class="form-group">
            <div class="switchWrapMiniLoose">
              <switch-control v-model:checked="turma.ativo" @change="toggleChange(i)" invert></switch-control>
            </div>
          </td>
          <td>
            <fa :icon="['fas','triangle-exclamation']" class="icon color-alert" v-if="!turma.TURCodigo" title="Turma não integrada no GestorClass"></fa>
          </td>
          <!-- <td>
            <a href="#" @click.prevent="selectTurma(turma)">
              <fa :icon="['fas','magnifying-glass']" class="icon"></fa>
            </a>
          </td> -->
          <td class="text-right">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="turma.selected" @change="toggleSelect()">
            </label>
          </td>
        </tr>
      </tbody>
    </table>
    <footer class="action-wrap">
      <!-- <label class="check-control">
        <input type="checkbox" class="check" v-model="syncOut">
        <span>
          Sincronizar com Perseus
        </span>
      </label> -->
      <a href="#" class="btn btn-primary" @click.prevent="confirmSync()">
        Salvar e Sincronizar
      </a>
    </footer>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>

export default {
  props: {
    scope: {
      type: Array
    }
  },
  components: {
    MiniSpinner
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.turmas = value
        this.addSelector(true)
        this.set()
      }
    }
  },
  data () {
    return {
      turmas: null,
      selected: [],
      syncOut: true,
      all: true
    }
  },
  mounted () {
    this.turmas = this.scope
    console.log("scope", this.scope)
    this.set()
    this.addSelector(true)
  },
  methods: {
    set () {
      // console.log("turmas:")
      this.turmas.map( (k, i) => {
        this.getLocais(i)
      })
    },
    getLocais ( i ) {
      const { idCurso, idUnidade } = this.turmas[i]

      console.log("getLocais - idCurso:", idCurso)
      console.log("getLocais - idUnidade:", idUnidade)

      AcademicoService.getLocaisByCurso( idCurso, idUnidade ).then(
        response => {
          console.log("getLocaisByCurso:", response.data)

          this.turmas[i].locais = response.data.length > 0 ? createSelectOptions(response.data, 'idLocal', 'descricao') : []
        }
      )
    },  
    toggleAll () {
      console.log("all:", this.all)
      if ( this.all ) {
        this.addSelector()
      } else {
        this.addSelector(true)
      }
      // console.log("this.selected", this.selected)
    },
    toggleSelect () {
      const someFalse = this.turmas.some( a => !a.selected)
      console.log("someFalse:", someFalse)
    },
    toggleChange ( index ) {
      this.turmas[index].selected = true
    },  
    addSelector ( off ) {
      this.turmas.map( k => {
        k.selected = off ? false : true
      })
    },  
    confirmSync () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja sincronizar as turmas selecionadas?",
        confirmButton: "Sim",
        denyButton: "Não",
        // data: gatilho,
        callback: this.sync,
      })
    },
    sync ( response ) {
      if ( response ) {
        const drop = []
        this.turmas.map( k => {
          if ( k.selected ) {
            drop.push(k)
          }
        })
        window.spinner.emit("open")
        // window.pagescroll.emit("goto", 0)
        AcademicoService.saveTurmas( drop, 1 ) .then(
          response => {
            console.log("response:", response)
            // this.$emit()
            window.toaster.emit('open', {style: "success", message: "Turmas salvas com sucesso", floater: true})
            this.$emit('onSave')
          },
          error => {
            console.log("error:", error)
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar as turmas informadas", floater: true})
            window.spinner.emit("close")
          }
        )
      }
    },
    selectTurma( turma ) {
      this.$emit('onSelect', turma)
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-wrap {
    padding: $hmg $hmg_small $mg_extra;
  }
  
  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    padding: $hmg $hmg_small;

    a {
      margin-left: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }
</style>