<script setup>
  import UserService from '@/services/user.service.js'
  import AcademicoService from '@/services/academico.service.js';
  import { createSelectOptions } from '@/services/utils.service.js'
  import Locais from '@/components/estruturaacademica/locais/Locais.vue'
  import FormSpinner from '@/components/utilities/FormSpinner.vue'
</script>

<template>
  <controller>
    <page-header title="Locais" />
    <form-spinner v-if="loading"/>
    <section class="frame locais">
      <header :class="{ floating: !locais }">
        <select-control 
          v-model="filter.unidade" 
          :options="options.unidades" 
          placeholder="Selecione a unidade" 
          style="width: 440px" 
          :disabled="loading" 
          :valid="valid"
          @change="valid = true"
          required
        />
        <a href="#" class="btn btn-primary" @click.prevent="getLocais()" :disabled="loading">Filtrar</a>
      </header>
      <locais :scope="locais" v-if="locais"/>
    </section>
  </controller>
</template>

<script>
export default {
  components: {
    Locais, FormSpinner
  },
  data () {
    return {
      options: {
        unidades: []
      },
      filter: {
        unidade: null
      },
      locais: null,
      loading: false,
      valid: true
    }
  },
  mounted () {
    this.getUnidades()
  },
  methods: {
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          this.options.unidades = createSelectOptions(response.data, 'idUnidade', 'descricao')
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível coletar as unidades.", floater: true})
        }
      )
    },
    getLocais () {
      if ( this.filter.unidade ) {
        this.loading = true
        AcademicoService.getLocaisPorUnidade( this.filter.unidade ).then(
          response => {
            this.locais = response.data
            this.loading = false
          },
          () => {
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível coletar os locais da unidade informada.", floater: true})
          }
        )
      } else {
        this.valid = false
        window.toaster.emit('open', {style: "error", message: "Escolha uma unidade antes de continuar", floater: true})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .locais {
    padding: 0 $hmg_mid;

    > header {
      display: flex;
      align-items: center;
      gap: $mg_mini;
      margin-top: $mg;
      @extend %transition_4e;

      >div {
        width: 280px;
      }

      &.floating {
        position: absolute; top: 50%;
        transform: translateY(-50%);
        justify-content: center;
        left: 0; right: 0; margin: auto;
      }
    }
  }
</style>